import React, { useCallback, useState, useContext } from 'react'
import { FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import ConfirmationModal from '../modals/components/ConfirmationModal'
import modalsMessages from '../modals/messages'

const defaultOptions = {
	title: '',
	description: <FormattedMessage {...modalsMessages.deleteMessage} />,
	confirmationText: <FormattedMessage {...modalsMessages.yes} />,
	cancellationText: <FormattedMessage {...modalsMessages.no} />,
	confirmationButtonProps: {},
	cancellationButtonProps: {}
}

const ConfirmContext = React.createContext({})

export const useConfirm = () => useContext(ConfirmContext)

export const ConfirmProvider = ({ children }) => {
	const [options, setOptions] = useState(defaultOptions)
	const [[resolve, reject], setResolveReject] = useState([])
	const isOpen = !!resolve || !!reject

	const confirm = useCallback((options = {}) => {
		return new Promise((resolve, reject) => {
			setOptions({ ...defaultOptions, ...options })
			setResolveReject([resolve, reject])
		})
	}, [])

	const handleClose = useCallback(() => {
		setResolveReject([])
	}, [])

	const handleCancel = useCallback(() => {
		reject()
		handleClose()
	}, [reject, handleClose])

	const handleConfirm = useCallback(() => {
		resolve()
		handleClose()
	}, [resolve, handleClose])

	return (
		<>
			<ConfirmContext.Provider value={confirm}>{children}</ConfirmContext.Provider>
			<ConfirmationModal {...options} isOpen={isOpen} onClose={handleCancel} onConfirm={handleConfirm} />
		</>
	)
}

ConfirmProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}
