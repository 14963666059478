import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { queryCache } from 'react-query'
import { Icon, ModalBody, ModalFooter, ModalHeader } from '@chakra-ui/core'
import { CALLED_TICKETS_KEY } from 'locations/entityKeys'
import { useLocation } from 'locations/LocationProvider'
import { api } from '../../api'
import { interceptError } from '../../utils/errors/errorInterceptors'
import QueueManagementAction from '../components/QueueManagementAction'
import messages from '../messages'
import Modal from '../../utils/modals/components/Modal'
import InlineStack from '../../ui/stacks/InlineStack'
import { Title } from '../../ui/text'

const CallNextContainer = ({ onCallNext, intl: { formatMessage }, ...props }) => {
	const {
		location: { id: locationId }
	} = useLocation()
	const [isLoading, setLoading] = useState(false)
	const [isNoNextTicketModalOpen, setNoNextTicketModalOpen] = useState(false)

	const callNext = () => {
		setLoading(true)
		return api.qm
			.callNext(locationId)
			.then(({ data }) => {
				if (data) {
					queryCache.invalidateQueries([CALLED_TICKETS_KEY, { locationId }])
					onCallNext(data)
				} else {
					setNoNextTicketModalOpen(true)
				}
			})
			.catch(interceptError)
			.finally(() => setLoading(false))
	}

	return (
		<>
			<QueueManagementAction
				variantColor='primary'
				icon='person'
				actionTitle={formatMessage(messages.callNext)}
				isLoading={isLoading}
				onClick={callNext}
				{...props}
			/>
			<Modal isOpen={isNoNextTicketModalOpen} onClose={() => setNoNextTicketModalOpen(false)}>
				<ModalHeader>
					<InlineStack>
						<Icon name='warning' color='brand.red' />
						<Title>{formatMessage(messages.noTicketsTitle)}</Title>
					</InlineStack>
				</ModalHeader>
				<ModalBody>{formatMessage(messages.noTicketsDescription)}</ModalBody>
				<ModalFooter />
			</Modal>
		</>
	)
}

CallNextContainer.propTypes = {
	onCallNext: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(CallNextContainer)
