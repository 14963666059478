const businessSectors = [
	{ code: 'RETAIL', label: 'retail' },
	{ code: 'CATERING', label: 'catering' },
	{ code: 'HEALTHCARE', label: 'healthcare' },
	{ code: 'EDUCATION', label: 'education' },
	{ code: 'TOURISM', label: 'tourism' },
	{ code: 'ENTERTAINMENT', label: 'entertainment' },
	{ code: 'SERVICE_PROVIDER', label: 'serviceProvider' },
	{ code: 'OTHER', label: 'other' }
]

export default businessSectors
