import * as Yup from 'yup'
import formsMessages from 'utils/forms/messages'
import messages from './messages'

const SubscriptionCreateSchema = formatMessage => {
	const today = new Date()
	today.setHours(0, 0, 0, 0)

	return Yup.object().shape({
		start_datetime: Yup.date()
			.label(formatMessage(messages.startDatetime))
			.min(today, formatMessage(messages.dateFuture))
			.required(({ label }) => formatMessage(formsMessages.required, { label }))
	})
}

export { SubscriptionCreateSchema }
