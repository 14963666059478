import React from 'react'
import PropTypes from 'prop-types'
import { Button, Flex, Icon, Text, Spinner, Box } from '@chakra-ui/core'
import { injectIntl } from 'react-intl'
import messages from '../messages'
import { MiniText } from '../../ui/text'
import { InlineStack } from '../../ui/stacks'

const PeopleCounterButton = ({ isDisabled, isLoading, iconName, ...props }) => (
	<Button h='70px' w='70px' variantColor='primary' variant='solid' isDisabled={isDisabled || isLoading} {...props}>
		<Icon name={iconName} size='10' />
	</Button>
)

const PeopleCounter = ({
	peopleInside,
	onDecrement,
	onIncrement,
	isLoading,
	warning,
	intl: { formatMessage },
	...props
}) => (
	<Flex {...props}>
		<PeopleCounterButton
			isDisabled={peopleInside === 0}
			size='10'
			borderRadius='0.5rem 0 0 0.5rem'
			isLoading={isLoading}
			onClick={onDecrement}
			iconName='minus'
		/>

		<Flex flex={1} minWidth={0} direction='column' px={2} borderWidth={1} justify='center'>
			{!isLoading ? (
				<div>
					<InlineStack justify='center'>
						<Text isTruncated fontSize='40px' lineHeight='40px' color={warning && 'red.500'}>
							{peopleInside}
						</Text>
						{warning && <Icon name='warning' color='red.500' />}
					</InlineStack>
					<MiniText
						isTruncated
						color={warning ? 'red.500' : 'gray.500'}
						textTransform='uppercase'
						textAlign='center'
					>
						{warning
							? formatMessage(messages.peopleInsideLimitExceeded)
							: formatMessage(messages.peopleInside)}
					</MiniText>
				</div>
			) : (
				<Box textAlign='center'>
					<Spinner />
				</Box>
			)}
		</Flex>
		<PeopleCounterButton
			borderRadius='0 0.5rem 0.5rem 0'
			isLoading={isLoading}
			onClick={onIncrement}
			iconName='add'
		/>
	</Flex>
)

PeopleCounterButton.defaultProps = {
	isLoading: false,
	isDisabled: false
}

PeopleCounterButton.propTypes = {
	isDisabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	iconName: PropTypes.string.isRequired
}

PeopleCounter.defaultProps = {
	isLoading: false,
	peopleInside: undefined
}

PeopleCounter.propTypes = {
	isLoading: PropTypes.bool,
	onDecrement: PropTypes.func.isRequired,
	onIncrement: PropTypes.func.isRequired,
	peopleInside: PropTypes.number,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(PeopleCounter)
