import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/core'
import Topnav from './Topnav'

const ToppedNavFrame = ({ children }) => {
	return (
		<>
			<Box pos='fixed' w='100%' h='topnav.height' zIndex='topnav'>
				<Topnav />
			</Box>
			<Box pt='topnav.height'>{children}</Box>
		</>
	)
}

ToppedNavFrame.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default ToppedNavFrame
