import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Input, Checkbox, Link as ChakraLink } from '@chakra-ui/core'

import PasswordInput from '../../ui/inputs/PasswordInput'
import { QubeSectionForm, Field, FormLabel, hasError } from '../../utils/forms'
import { SignUpSchema } from './schemas'
import messages from './messages'
import authMessages from '../messages'

const SignUpForm = ({ handleOnSubmit, intl: { formatMessage } }) => {
	const privacyPolicyURL = `${process.env.REACT_APP_QBETTER_SITE_PATH}/privacy-policy`
	return (
		<QubeSectionForm
			initialValues={{ user: { email: '', password1: '' }, name: '', terms: false }}
			validationSchema={SignUpSchema(formatMessage)}
			onSubmit={handleOnSubmit}
			submitMessage={messages.signup}
		>
			<Field
				name='name'
				label={<FormLabel htmlFor='signup-input-name'>{formatMessage(authMessages.name)}</FormLabel>}
			>
				{({ field }) => <Input id='signup-input-name' {...field} />}
			</Field>
			<Field
				name='email'
				parentName='user'
				label={<FormLabel htmlFor='signup-input-email'>{formatMessage(authMessages.email)}</FormLabel>}
			>
				{({ field }) => <Input id='signup-input-email' type='email' {...field} />}
			</Field>
			<Field
				name='password1'
				parentName='user'
				label={<FormLabel htmlFor='signup-input-password'>{formatMessage(authMessages.password)}</FormLabel>}
			>
				{({ field }) => <PasswordInput id='signup-input-password' type='password' {...field} />}
			</Field>

			<Field name='terms'>
				{({ touched, errors, field }) => (
					<Checkbox
						{...field}
						id='signup-terms-checkbox'
						borderColor='gray.500'
						isInvalid={!!hasError(touched, errors, 'terms')}
					>
						{formatMessage(messages.accept, {
							terms: (
								<ChakraLink
									key='terms'
									color='brand.activeBlue'
									_hover={{ color: 'brand.deepBlue' }}
									href={privacyPolicyURL}
									target='_blank'
								>
									{formatMessage(messages.terms)}
								</ChakraLink>
							)
						})}
					</Checkbox>
				)}
			</Field>
		</QubeSectionForm>
	)
}
SignUpForm.propTypes = {
	handleOnSubmit: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(SignUpForm)
export { SignUpForm }
