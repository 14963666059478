import { Box, Flex, Icon, Text, useDisclosure } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React, { useLayoutEffect, useState } from 'react'

import MainMenuBar from './menu/MainMenuBar'
import MenuEntry from './menu/MenuEntry'
import { SectionDivider } from './menu/SectionDivider'
import { SectionTitle } from './menu/SectionTitle'
import SubMenuBar from './subMenu/SubMenuBar'
import SubMenuEntry from './subMenu/SubMenuEntry'
import { SubSectionDivider } from './subMenu/SubSectionDivider'
import { SubSectionTitle } from './subMenu/SubSectionTitle'

export const getChildren = elements => React.Children.toArray(elements).filter(child => child)

const Sidenav = ({ matchPath: matchPathFn, location, LinkComponent, children: elements }) => {
	const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
	const [timeout, setStateTimeout] = useState(undefined)

	const isActive = ({ matchPath, path }) => path && !!matchPathFn(matchPath || path, location)

	const filterByEntries = components =>
		components.filter(
			e =>
				e.type.displayName === Sidenav.MenuEntry.displayName ||
				e.type.displayName === Sidenav.SubMenuEntry.displayName
		)

	const getActiveItem = menuElements =>
		filterByEntries(getChildren(menuElements)).find(
			({ props: { matchPath, path, children } }) =>
				isActive({ matchPath, path }) || (children && getActiveItem(children))
		)

	const activeNavItem = getActiveItem(elements)
	const shouldNavOpen = activeNavItem && activeNavItem.props.children

	useLayoutEffect(() => {
		!timeout && (shouldNavOpen ? onOpen() : onClose())
	}, [onClose, onOpen, timeout, shouldNavOpen])

	const onMouseEnter = () => setStateTimeout(setTimeout(() => onClose(), 350))

	const onMouseLeave = () => {
		clearTimeout(timeout)
		setStateTimeout(undefined)
	}

	const openMenuOnClick = evt => evt.target === evt.currentTarget && activeNavItem?.props.children && onToggle()

	const assignSidenavProps = component => {
		const { path, matchPath } = component.props

		const linkProps = path && {
			as: LinkComponent,
			to: path
		}

		switch (component.type.displayName) {
			case Sidenav.MenuEntry.displayName:
			case Sidenav.SubMenuEntry.displayName:
				return React.cloneElement(component, {
					isActive: isActive({ matchPath, path }),
					isCollapsed: isOpen,
					onClick: () => onMouseLeave(),
					...linkProps
				})
			case Sidenav.SectionTitle.displayName:
			case Sidenav.SectionDivider.displayName:
				return React.cloneElement(component, {
					isCollapsed: isOpen
				})
			default:
				return component
		}
	}

	return (
		<Flex h='100%' boxShadow='2px 0px 2px rgba(66, 66, 66, 0.06)'>
			<MainMenuBar
				isCollapsed={isOpen}
				onMouseLeave={onMouseLeave}
				onMouseEnter={onMouseEnter}
				onClick={openMenuOnClick}
				flexShrink={0}
			>
				{getChildren(elements).map(assignSidenavProps)}
			</MainMenuBar>
			{isOpen && activeNavItem && (
				<SubMenuBar>
					<Box mx={4} m={4} color='sidenav.subnav.title'>
						<Icon boxSize={4} name={activeNavItem.props.icon} />
						<Text>{activeNavItem.props.title}</Text>
					</Box>
					{getChildren(activeNavItem.props.children).map(assignSidenavProps)}
				</SubMenuBar>
			)}
		</Flex>
	)
}

Sidenav.MenuEntry = MenuEntry
Sidenav.MenuEntry.displayName = 'MenuEntry'
Sidenav.SubMenuEntry = SubMenuEntry
Sidenav.SubMenuEntry.displayName = 'SubMenuEntry'
Sidenav.SectionTitle = SectionTitle
Sidenav.SectionTitle.displayName = 'SectionTitle'
Sidenav.SubSectionTitle = SubSectionTitle
Sidenav.SubSectionTitle.displayName = 'SubSectionTitle'
Sidenav.SectionDivider = SectionDivider
Sidenav.SectionDivider.displayName = 'SectionDivider'
Sidenav.SubSectionDivider = SubSectionDivider
Sidenav.SubSectionDivider.displayName = 'SubSectionDivider'

Sidenav.propTypes = {
	children: PropTypes.node.isRequired,
	LinkComponent: PropTypes.shape({}).isRequired,
	location: PropTypes.string.isRequired,
	matchPath: PropTypes.func.isRequired
}

export default Sidenav
