import React from 'react'
import PropTypes from 'prop-types'
import { ModalCloseButton, Modal as ChakraModal, ModalOverlay, ModalContent } from '@chakra-ui/core'

const Modal = ({ isOpen, children, onClose, ...props }) => (
	<ChakraModal isOpen={isOpen} onClose={onClose} isCentered={true} {...props}>
		<ModalOverlay />
		<ModalContent rounded='lg'>
			{children}
			<ModalCloseButton />
		</ModalContent>
	</ChakraModal>
)

Modal.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired
}

export default Modal
