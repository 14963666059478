import React from 'react'
import PropTypes from 'prop-types'
import { Image, Stack } from '@chakra-ui/core'
import { MediumText, Title } from '../../ui/text'

const EmptyState = ({ imageSrc, hasImage, title, description }) => (
	<Stack align='center' direction='column' spacing={3}>
		{hasImage && <Image src={imageSrc} alt='Empty State' ignoreFallback={true} />}
		<Title>{title}</Title>
		<MediumText>{description}</MediumText>
	</Stack>
)

EmptyState.defaultProps = {
	title: 'No results found',
	description: 'We could not find any matching results, try search for another service, queue or product',
	imageSrc: '/public/images/empty-state-default.svg',
	hasImage: true
}

EmptyState.displayName = 'EmptyState'

EmptyState.propTypes = {
	/** Title of the emptyState */
	title: PropTypes.string,
	/** Description of the emptyState */
	description: PropTypes.string,
	/** The main image of the emptyState */
	imageSrc: PropTypes.string,
	/** Bool to render image */
	hasImage: PropTypes.bool
}

export default EmptyState
