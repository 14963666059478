import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import _ from 'lodash'
import { injectIntl } from 'react-intl'
import { Alert, AlertIcon } from '@chakra-ui/core'
import messages from '../messages'

const FormFeedbackErrorMessage = ({ formik: { errors }, intl: { formatMessage }, ...props }) => {
	const { non_field_errors: nonFieldErrors, base_error: baseError } = errors
	const error = baseError || nonFieldErrors || formatMessage(messages.highlightedFieldsError)
	return (
		!_.isEmpty(errors) && (
			<Alert status='error' {...props}>
				<AlertIcon />
				{error}
			</Alert>
		)
	)
}

FormFeedbackErrorMessage.displayName = 'FormFeedbackErrorMessage'

FormFeedbackErrorMessage.propTypes = {
	formik: PropTypes.shape({
		errors: PropTypes.shape({
			non_field_errors: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
			base_error: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)])
		})
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

const composedComponent = _.flowRight(injectIntl, connect)

export default composedComponent(FormFeedbackErrorMessage)
export { FormFeedbackErrorMessage }
