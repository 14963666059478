import { defineMessages } from 'react-intl'

export const scope = 'qrq.auth.signup'

export default defineMessages({
	alreadyHaveAccount: {
		id: `${scope}.link.alreadyHaveAccount`
	},
	signup: {
		id: `${scope}.button.signup`
	},
	title: {
		id: `${scope}.title`
	},
	password: {
		id: `${scope}.label.password`
	},
	accountCreated: {
		id: `${scope}.accountCreated`
	},
	accept: {
		id: `${scope}.accept`
	},
	terms: {
		id: `${scope}.terms`
	},
	checkRequired: {
		id: `${scope}.checkRequired`
	}
})
