import mqtt from 'mqtt'

class MQTTService {
	static client = null

	static getClient() {
		if (!MQTTService.client) {
			this.client = new MQTTService.connect()
		}

		return this.client
	}

	static connect() {
		this.mqttParams = {
			port: process.env.REACT_APP_MQTT_PORT,
			host: process.env.REACT_APP_MQTT_PATH,
			username: this.username,
			protocol: process.env.REACT_APP_MQTT_PROTOCOL,
			clientId: `admin_${Math.random()
				.toString(16)
				.substr(2, 8)}`
		}

		return mqtt.connect({ ...this.mqttParams })
	}

	static setUsername(username) {
		this.username = username
		this.client = this.connect()
	}
}

export default MQTTService
