import { useDisclosure } from '@chakra-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'

import { Button } from 'buttons'

import buttonMessages from '../../buttons/messages'
import CreateLocation from './CreateLocation'

const LocationCreateContainer = () => {
	const { formatMessage } = useIntl()
	const { isOpen: isCreateModalOpen, onOpen: openCreateModal, onClose: closeCreateModal } = useDisclosure()

	return (
		<>
			<Button id='locations-list-button-add' icon='add' onClick={openCreateModal}>
				{formatMessage(buttonMessages.create)}
			</Button>
			<CreateLocation isOpen={isCreateModalOpen} onClose={closeCreateModal} />
		</>
	)
}

export default LocationCreateContainer
