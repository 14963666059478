import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ModalManager } from './ModalManager'

export const modalManager = new ModalManager()

export const ModalWrapper = ({ children }) => {
	const [modal, setModal] = useState(null)
	const hideModal = () => setModal(null)
	const showModal = newModal => setModal(newModal)

	modalManager.setModalRef({ hideModal, showModal })

	return (
		<>
			{modal}
			{children}
		</>
	)
}

ModalWrapper.defaultProps = {
	children: null
}

ModalWrapper.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node])
}
