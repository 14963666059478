import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from 'locations/LocationProvider'
import { injectIntl } from 'react-intl'
import { api } from '../../api'
import { interceptError } from '../../utils/errors/errorInterceptors'
import QueueManagementAction from '../components/QueueManagementAction'
import messages from '../messages'

const GenerateTicketContainer = ({ onGenerateTicket, numberOfPeople, intl: { formatMessage }, ...props }) => {
	const { location } = useLocation()
	const [isLoading, setLoading] = useState(false)

	const generateTicket = () => {
		setLoading(true)
		return api.qm
			.generateTicket(location.id, numberOfPeople)
			.then(({ data }) => onGenerateTicket(data))
			.catch(interceptError)
			.finally(() => setLoading(false))
	}

	return (
		<QueueManagementAction
			variantColor='secondary'
			icon='ticket'
			actionTitle={formatMessage(messages.generateTicket)}
			isLoading={isLoading}
			onClick={generateTicket}
			{...props}
		/>
	)
}

GenerateTicketContainer.propTypes = {
	onGenerateTicket: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	numberOfPeople: PropTypes.number.isRequired
}

export default injectIntl(GenerateTicketContainer)
