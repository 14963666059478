import React from 'react'
import { Button, useTheme } from '@chakra-ui/core'

export const paginationButtonProps = {
	px: 1,
	w: 10,
	minW: 10
}

const PaginationButton = props => {
	const theme = useTheme()
	return (
		<Button
			variant='ghost'
			borderBottom={`1px solid ${theme.colors.brand.gray}`}
			roundedBottom='0px !important'
			{...paginationButtonProps}
			{...props}
		/>
	)
}

export default PaginationButton
