import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/core'

const ListItemContent = ({ children }) => (
	<Box mx={3} display={{ md: 'flex' }} alignItems='center' w='100%'>
		{children}
	</Box>
)

ListItemContent.propTypes = {
	children: PropTypes.node.isRequired
}

export default ListItemContent
