import React, { useState } from 'react'
import { Input, InputGroup, InputRightElement, Icon } from '@chakra-ui/core'

const PasswordInput = props => {
	const [show, setShow] = useState(false)
	const handleClick = () => setShow(!show)

	return (
		<InputGroup>
			<Input {...props} type={show ? 'text' : 'password'} />
			<InputRightElement>
				<Icon name={show ? 'closeEye' : 'openEye'} onClick={handleClick} />
			</InputRightElement>
		</InputGroup>
	)
}

export default PasswordInput
