import { defineMessages } from 'react-intl'

export const scope = 'qrq.subscription-plan-groups'

export default defineMessages({
	title: {
		id: `${scope}.title`
	},
	monthly: {
		id: `${scope}.label.monthly`
	},
	yearly: {
		id: `${scope}.label.yearly`
	},
	locationMonth: {
		id: `${scope}.label.locationMonth`
	},
	locationYear: {
		id: `${scope}.label.locationYear`
	},
	ticketsPerMonth: {
		id: `${scope}.label.ticketsPerMonth`
	},
	ticketsPerYear: {
		id: `${scope}.label.ticketsPerYear`
	},
	choosePeriodicity: {
		id: `${scope}.label.choosePeriodicity`
	},
	popular: {
		id: `${scope}.label.popular`
	},
	choose: {
		id: `${scope}.button.choose`
	},
	free: {
		id: `${scope}.label.free`
	},
	yearlySavingInMonths: {
		id: `${scope}.label.yearlySavingInMonths`
	}
})
