import PropTypes from 'prop-types'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import { queryCache, useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { api } from 'api'
import { interceptError } from 'utils/errors/errorInterceptors'

import { CURRENT_CYCLE_KEY, LOCATION_KEY } from './entityKeys'

const LocationContext = React.createContext({})

export const useLocation = () => useContext(LocationContext)

const fetchLocationById = (_, id) => api.locations.detail(id)

const fetchCurrentCycleByLocationId = (_, locationId) => api.cycles.current(locationId)

const LocationProvider = ({ children }) => {
	const navigate = useNavigate()
	const [peopleInside, setPeopleInside] = useState(undefined)
	const [ticketsWaiting, setTicketsWaiting] = useState(undefined)
	const [locationId, setLocationId] = useState(undefined)
	const { status: locationStatus, data: location } = useQuery([LOCATION_KEY, locationId], fetchLocationById, {
		enabled: locationId,
		retry: false,
		onError: () => navigate('/', { replace: true }),
		onSuccess: ({ data }) => setPeopleInside(data.people_inside)
	})
	const { status: currentCycleStatus, data: currentCycle } = useQuery(
		[CURRENT_CYCLE_KEY, locationId],
		fetchCurrentCycleByLocationId,
		{
			enabled: locationId,
			retry: false
		}
	)

	const renewSignature = useMutation(api.locations.renewSignature, {
		onSuccess: data => queryCache.setQueryData([LOCATION_KEY, locationId], data),
		onError: interceptError
	})

	const editLocation = useMutation(api.locations.edit, {
		onSuccess: data => queryCache.setQueryData([LOCATION_KEY, locationId], data)
	})

	const incrementPeople = useMutation(api.locations.increment, {
		onSuccess: ({ data }) => setPeopleInside(data.people_inside),
		onError: interceptError
	})

	const decrementPeople = useMutation(api.locations.decrement, {
		onSuccess: ({ data }) => setPeopleInside(data.people_inside),
		onError: interceptError
	})

	const fetchLocation = useCallback(id => {
		setTicketsWaiting(undefined)
		setLocationId(id)
	}, [])

	const mqttMessageHandlers = useMemo(
		() => ({
			setPeopleInside,
			setTicketsWaiting
		}),
		[]
	)

	return (
		<LocationContext.Provider
			value={{
				location: location?.data,
				fetchLocation,
				currentCycle: currentCycle?.data,
				isLoading: !location || locationStatus === 'loading' || currentCycleStatus === 'loading',
				mqttMessageHandlers,
				peopleInside,
				ticketsWaiting,
				incrementPeople,
				decrementPeople,
				renewSignature,
				editLocation
			}}
		>
			{children}
		</LocationContext.Provider>
	)
}

LocationProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

export default LocationProvider
