import { defineMessages } from 'react-intl'

export const scope = 'qrq.locations.list'

export default defineMessages({
	title: {
		id: `${scope}.title`
	},
	emptyStateTitle: {
		id: `${scope}.emptyState.title`
	},
	emptyStateDescription: {
		id: `${scope}.emptyState.label`
	}
})
