import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import './global.css'

import { ThemeProvider as ChakraThemeProvider, CSSReset } from '@chakra-ui/core'
import React from 'react'
import ReactDOM from 'react-dom'
import { IntlProvider } from 'react-intl'
import { BrowserRouter } from 'react-router-dom'

import { theme, cssResetConfig } from 'theme'

import App from './App'
import AuthProvider from './auth/AuthProvider'
import { intlProps } from './locales/LocaleData'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(
	<IntlProvider {...intlProps}>
		<BrowserRouter>
			<ChakraThemeProvider theme={theme}>
				<CSSReset config={cssResetConfig} />
				<AuthProvider>
					<App />
				</AuthProvider>
			</ChakraThemeProvider>
		</BrowserRouter>
	</IntlProvider>,
	document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
