import { showErrorModal } from './showUIErrors'

export const handleFormValidationError = setFieldError => errors => {
	Object.keys(errors)
		.filter(e => e !== 'non_field_errors')
		.forEach(key => {
			setFieldError(key, errors[key])
		})
}

export const handleNonFieldError = setFieldError => error => setFieldError('non_field_errors', error)

export const handleActionValidationError = errors => {
	showErrorModal(
		Object.keys(errors)
			.filter(e => e !== 'non_field_errors')
			.map(key => `${key} - ${errors[key]}`)
	)
}
