import { topics, matchTopic } from './topics'

const onMessageCallback = (topic, message, { setPeopleInside, setTicketsWaiting }) => {
	const parsedMessage = JSON.parse(message)

	if (matchTopic(topics.qm.changedPeopleWaiting, topic)) {
		setTicketsWaiting(parsedMessage.waiting)
	}
	if (matchTopic(topics.qm.changedPeopleInside, topic)) {
		setPeopleInside(parsedMessage.people_inside)
	}
}

export default onMessageCallback
