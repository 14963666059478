import { theme as chakraTheme } from '@chakra-ui/core'

import icons from './icons'

const breakpoints = ['32em', '40em', '48em', '64em', '80em']
breakpoints.xs = breakpoints[0]
breakpoints.sm = breakpoints[1]
breakpoints.md = breakpoints[2]
breakpoints.lg = breakpoints[3]
breakpoints.xl = breakpoints[4]

const fontSizes = {
	...chakraTheme.fontSizes,
	'2xs': '0.625rem'
}

const colors = {
	...chakraTheme.colors,
	gray: {
		50: '#f9f9fa',
		100: '#ededf0',
		200: '#e0e0e5',
		300: '#d3d2d9',
		400: '#c3c3cc',
		500: '#b2b2bd',
		600: '#9f9ead',
		700: '#888799',
		800: '#6b6a80',
		900: '#3e3e50'
	},
	black: '#424242',
	brand: {
		red: '#CF0A2C',
		green: '#3EC28F',
		deepBlue: '#0D0CB5',
		activeBlue: '#005BEA',
		purple: '#4E2F60',
		lightGray: '#F3F3F3',
		gray: '#B8B8B8'
	},
	primary: {
		50: '#e6f7e9',
		100: '#c3eac8',
		200: '#9bdda5',
		300: '#6ed17f',
		400: '#47c662',
		500: '#05bb45',
		600: '#00ab3b',
		700: '#00992f',
		800: '#008823',
		900: '#006909'
	},
	secondary: {
		50: '#e1f1f1',
		100: '#b3dedc',
		200: '#83c9c6',
		300: '#53b3ae',
		400: '#33a29d',
		500: '#20928a',
		600: '#1d857d',
		700: '#1b756d',
		800: '#19655e',
		900: '#154941'
	},
	blue: {
		50: '#73A5F3',
		100: '#5C96F1',
		200: '#4587EF',
		300: '#2E78ED',
		400: '#1769EB',
		500: '#005BEA',
		600: '#0053D5',
		700: '#004BC0',
		800: '#0043AB',
		900: '#003A95'
	},
	yellow: {
		50: '#FFC947',
		100: '#FEC23F',
		200: '#FEBB38',
		300: '#FEB430',
		400: '#FEAD29',
		500: '#FEA621',
		600: '#EB951E',
		700: '#D7851A',
		800: '#C47417',
		900: '#B06414'
	}
}

const iconButton = {
	default: {
		...colors.gray,
		500: '#424242'
	}
}

const sidenav = {
	colors: {
		bg: colors.gray[100],
		title: colors.black,
		text: colors.black,
		hoverBg: colors.white,
		hoverText: colors.black,
		highlightBg: colors.gray[400],
		highlightText: colors.white,
		divider: colors.black,
		subnav: {
			bg: colors.white,
			text: colors.black,
			title: colors.black,
			sectionTitle: colors.black,
			divider: colors.black,
			hoverBg: colors.gray[400],
			hoverText: colors.white,
			highlightBg: colors.gray[100],
			highlightText: colors.black
		}
	},
	space: {
		width: chakraTheme.space[64]
	}
}

const topnav = {
	colors: {
		bg: colors.primary[500],
		text: colors.white,
		button: {
			bg: colors.transparent,
			hoverBg: colors.whiteAlpha['300'],
			hoverText: colors.white
		},
		menu: {
			text: colors.black
		}
	},
	space: {
		height: chakraTheme.sizes['16']
	},
	zIndices: {
		topnav: 900,
		toast: 890
	}
}

const bulletState = {
	colors: {
		on: colors.brand.green,
		away: colors.yellow[400],
		off: colors.brand.red
	}
}

const card = {
	shadow: '0 1px 7px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.22)',
	radius: chakraTheme.radii.md
}

const chakraIcons = {
	...icons,
	'check-circle': icons.check,
	edit: chakraTheme.icons.edit,
	download: chakraTheme.icons.download,
	'question-outline': chakraTheme.icons['question-outline'],
	'triangle-up': chakraTheme.icons['triangle-up'],
	'triangle-down': chakraTheme.icons['triangle-down'],
	'chevron-down': chakraTheme.icons['chevron-down']
}

const theme = {
	...chakraTheme,
	fontSizes,
	colors: {
		...colors,
		sidenav: sidenav.colors,
		topnav: topnav.colors,
		iconButton: iconButton.default,
		breadcrumbs: colors.brand.gray,
		bulletState: bulletState.colors
	},
	icons: chakraIcons,
	zIndices: {
		...chakraTheme.zIndices,
		...topnav.zIndices
	},
	breakpoints,
	fonts: {
		heading: 'Roboto, system-ui, sans-serif',
		body: 'Roboto, system-ui, sans-serif'
	},
	space: {
		...chakraTheme.space,
		sidenav: sidenav.space,
		topnav: topnav.space
	},
	sizes: {
		...chakraTheme.sizes,
		sidenav: sidenav.space,
		topnav: topnav.space
	},
	shadows: {
		...chakraTheme.shadows,
		card: card.shadow
	},
	radii: {
		card: card.radius,
		...chakraTheme.radii
	}
}

export default theme
