import enEN from './translations/en-EN'
import ptPT from './translations/pt-PT'
import frFR from './translations/fr-FR'
import esES from './translations/es-ES'
import fiFI from './translations/fi-FI'
import svSE from './translations/sv-SE'
import daDK from './translations/da-DK'
import elGR from './translations/el-GR'

export const locale =
	(navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || 'en-EN'

const localeSubstr = locale.substr(0, 2)

export const messages = {
	en: enEN,
	pt: ptPT,
	fr: frFR,
	es: esES,
	fi: fiFI,
	sv: svSE,
	da: daDK,
	el: elGR
}

export const supportedLocale = Object.keys(messages).includes(localeSubstr) ? localeSubstr : 'en'

export const intlProps = {
	locale: supportedLocale,
	messages: messages[supportedLocale]
}
