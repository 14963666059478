import React from 'react'
import { useIntl } from 'react-intl'
import { Link, Navigate, Route, Routes } from 'react-router-dom'

import { Button } from 'buttons'
import SubscriptionPlanGroupsListContainer from 'subscription-plan-groups/containers/SubscriptionPlanGroupsListContainer'
import subscriptionPlansMessages from 'subscription-plan-groups/messages'
import SubscriptionCreateContainer from 'subscriptions/containers/SubscriptionCreateContainer'
import { PageHeader, SectionStack } from 'utils/layout'

import CycleContainer from './containers/CycleContainer'
import CyclesListContainer from './containers/CyclesListContainer'
import messages from './messages'

const CyclesApp = () => {
	const { formatMessage } = useIntl()

	return (
		<Routes>
			<Route
				path='/'
				element={
					<SectionStack>
						<SectionStack.Element>
							<PageHeader
								title={formatMessage(messages.title)}
								action={
									<Button id='cycles-list-button-add' icon='add' as={Link} to='create'>
										{formatMessage(messages.newPlan)}
									</Button>
								}
							/>
						</SectionStack.Element>
						<CyclesListContainer />
					</SectionStack>
				}
			/>
			<Route
				path='/create/*'
				element={
					<SectionStack>
						<SectionStack.Element>
							<PageHeader title={formatMessage(subscriptionPlansMessages.title)} />
						</SectionStack.Element>
						<SectionStack.Element>
							<SubscriptionPlanGroupsListContainer />
						</SectionStack.Element>
					</SectionStack>
				}
			/>
			<Route path='/create/:subPlanId' element={<SubscriptionCreateContainer />} />
			<Route path='/:cycleId' element={<CycleContainer />} />
			<Route path='*' element={<Navigate to='/' replace />} />
		</Routes>
	)
}

export default CyclesApp
