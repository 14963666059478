import { defineMessages } from 'react-intl'

export const scope = 'qrq.locations.create'

export default defineMessages({
	addLocation: {
		id: `${scope}.addLocation`
	},
	locationCreatedSuccessMessage: {
		id: `${scope}.locationCreatedSuccessMessage`
	}
})
