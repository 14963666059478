import { defineMessages } from 'react-intl'

export const scope = 'qrq.modal'

export default defineMessages({
	deleteMessage: {
		id: `${scope}.deleteMessage`
	},
	ok: {
		id: `${scope}.ok`
	},
	yes: {
		id: `${scope}.yes`
	},
	no: {
		id: `${scope}.no`
	},
	error: {
		id: `${scope}.error`
	}
})
