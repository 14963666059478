import React from 'react'
import PropTypes from 'prop-types'
import InlineStack from 'ui/stacks/InlineStack'

const ListItemActions = ({ children }) => (
	<InlineStack d='flex' justifyContent={{ base: 'space-around', lg: 'normal' }} ml='auto'>
		{children}
	</InlineStack>
)

ListItemActions.propTypes = {
	children: PropTypes.node.isRequired
}

export default ListItemActions
