import React from 'react'
import PropTypes from 'prop-types'
import { Box, ButtonGroup } from '@chakra-ui/core'
import { useIntl } from 'react-intl'
import Button from '../../buttons/Button'
import AddMorePeopleButton from './AddMorePeopleButton'
import { Title } from '../../ui/text'
import messages from '../messages'

const PeopleNumberSelector = ({ value, defaultValues, onChange, ...props }) => {
	const { formatMessage } = useIntl()
	const buttonValues = [...new Set(defaultValues).add(value)]

	return (
		<Box {...props}>
			<Title>{formatMessage(messages.numberOfPeople)}</Title>
			<ButtonGroup size='md'>
				{buttonValues.map(buttonValue => (
					<Button
						key={buttonValue}
						id={`select-${buttonValue}-number-of-people`}
						variantColor={value === buttonValue ? 'secondary' : 'gray'}
						onClick={() => onChange(buttonValue)}
						my={2}
					>
						{buttonValue}
					</Button>
				))}
				<AddMorePeopleButton variantColor='gray' my={2} onChange={onChange} />
			</ButtonGroup>
		</Box>
	)
}

PeopleNumberSelector.defaultProps = {
	defaultValues: []
}

PeopleNumberSelector.propTypes = {
	value: PropTypes.number.isRequired,
	onChange: PropTypes.func.isRequired,
	defaultValues: PropTypes.arrayOf(PropTypes.number)
}

export default PeopleNumberSelector
