import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { QubeSectionForm, Field, FormLabel } from 'utils/forms'
import { ResetPasswordSchema } from './schemas'
import messages from './messages'
import authMessages from '../messages'
import PasswordInput from '../../ui/inputs/PasswordInput'

const ResetPasswordForm = ({ handleOnSubmit, initialValues, intl: { formatMessage } }) => (
	<QubeSectionForm
		initialValues={{ new_password2: '', ...initialValues }}
		validationSchema={ResetPasswordSchema(formatMessage)}
		onSubmit={handleOnSubmit}
		submitMessage={messages.resetPassword}
	>
		<Field
			name='new_password2'
			label={
				<FormLabel htmlFor='reset-password-input-password'>{formatMessage(authMessages.password)}</FormLabel>
			}
		>
			{({ field }) => <PasswordInput id='reset-password-input-password' {...field} />}
		</Field>
	</QubeSectionForm>
)

ResetPasswordForm.displayName = 'ResetPasswordForm'

ResetPasswordForm.propTypes = {
	initialValues: PropTypes.shape({
		token: PropTypes.string.isRequired,
		uid: PropTypes.string.isRequired
	}).isRequired,
	handleOnSubmit: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(ResetPasswordForm)
export { ResetPasswordForm }
