import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@chakra-ui/core'
import { Title } from '../text'

const Section = ({ title, children }) => (
	<Stack spacing={2}>
		<Title>{title}</Title>
		{children}
	</Stack>
)
Section.propTypes = {
	title: PropTypes.string.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default Section
