import { defineMessages } from 'react-intl'

export const scope = 'qrq.auth'

export default defineMessages({
	email: {
		id: `${scope}.label.email`
	},
	name: {
		id: `${scope}.label.name`
	},
	password: {
		id: `${scope}.label.password`
	},
	backToLogin: {
		id: `${scope}.button.backToLogin`
	}
})
