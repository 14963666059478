import * as Yup from 'yup'

import formsMessages from 'utils/forms/messages'

import locationMessages from '../messages'

const LocationDetailsSchema = formatMessage =>
	Yup.object().shape({
		name: Yup.string()
			.label(formatMessage(locationMessages.name))
			.max(100, ({ label, max }) => formatMessage(formsMessages.stringMax, { label, max }))
			.required(({ label }) => formatMessage(formsMessages.required, { label })),
		country: Yup.string()
			.label(formatMessage(locationMessages.country))
			.required(({ label }) => formatMessage(formsMessages.required, { label })),
		business_sector: Yup.string()
			.label(formatMessage(locationMessages.businessSector))
			.required(({ label }) => formatMessage(formsMessages.required, { label }))
	})

export { LocationDetailsSchema }
