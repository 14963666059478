import React from 'react'
import PropTypes from 'prop-types'
import { Alert, AlertDescription, AlertIcon, CloseButton } from '@chakra-ui/core'
import InlineStack from '../../ui/stacks/InlineStack'
import { MediumText } from '../../ui/text'

const SuccessToast = ({ message, onClose }) => (
	<Alert variant='solid' rounded='md' status='success' as={InlineStack}>
		<AlertIcon />
		<AlertDescription as={MediumText}>{message}</AlertDescription>
		<CloseButton ml='auto' onClick={onClose} />
	</Alert>
)

SuccessToast.propTypes = {
	message: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired
}

export default SuccessToast
