import React from 'react'
import PropTypes from 'prop-types'
import { Text } from '@chakra-ui/core'
import { Link } from 'react-router-dom'
import TopnavButton from './TopnavButton'
import { LargeText } from '../../ui/text'

const LocationLink = ({ entity }) => {
	const displayOnMobile = { base: 'inline-flex', md: 'none' }
	const displayOnLaptop = { base: 'none', md: 'inline-flex' }
	return (
		<>
			<TopnavButton as={Link} display={displayOnLaptop} leftIcon='marker' to='/'>
				<LargeText isTruncated>{entity}</LargeText>
			</TopnavButton>
			<TopnavButton as={Link} display={displayOnMobile} leftIcon='marker' to='/'>
				<Text overflowX='hidden' textOverflow='ellipsis' whiteSpace='no-wrap'>
					{entity}
				</Text>
			</TopnavButton>
		</>
	)
}

LocationLink.propTypes = {
	entity: PropTypes.string.isRequired
}

export default LocationLink
