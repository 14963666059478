import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Input, Checkbox, Link as ChakraLink, Divider } from '@chakra-ui/core'

import { QubeSectionForm, Field, FormLabel, hasError } from 'utils/forms'
import { SignUpSchema } from './schemas'
import messages from './messages'
import PasswordInput from '../../ui/inputs/PasswordInput'
import signUpMessages from '../signup/messages'

const SignUpPartnerForm = ({ handleOnSubmit, intl: { formatMessage } }) => {
	const privacyPolicyURL = `${process.env.REACT_APP_QBETTER_SITE_PATH}/privacy-policy`
	return (
		<QubeSectionForm
			initialValues={{ user: { email: '', password1: '' }, name: '', referral: '', terms: false }}
			validationSchema={SignUpSchema(formatMessage)}
			onSubmit={handleOnSubmit}
			submitMessage={messages.createAccount}
		>
			<Field
				name='referral'
				label={
					<FormLabel htmlFor='signup-partners-input-referral'>{formatMessage(messages.referral)}</FormLabel>
				}
			>
				{({ field }) => <Input id='signup-partners-input-referral' type='email' {...field} />}
			</Field>
			<Divider my={2} borderColor='black' />
			<Field
				name='name'
				label={<FormLabel htmlFor='signup-partners-input-name'>{formatMessage(messages.name)}</FormLabel>}
			>
				{({ field }) => <Input id='signup-partners-input-name' type='name' {...field} />}
			</Field>
			<Field
				name='email'
				parentName='user'
				label={<FormLabel htmlFor='signup-partners-input-email'>{formatMessage(messages.email)}</FormLabel>}
			>
				{({ field }) => <Input id='signup-partners-input-email' type='email' {...field} />}
			</Field>
			<Field
				name='password1'
				parentName='user'
				label={
					<FormLabel htmlFor='signup-partners-input-password'>{formatMessage(messages.password)}</FormLabel>
				}
			>
				{({ field }) => <PasswordInput id='signup-partners-input-password' type='password' {...field} />}
			</Field>
			<Field name='terms'>
				{({ field, touched, errors }) => (
					<Checkbox
						{...field}
						isInvalid={!!hasError(touched, errors, 'terms')}
						id='signup-partners-terms-checkbox'
						borderColor='gray.500'
					>
						{formatMessage(signUpMessages.accept, {
							terms: (
								<ChakraLink
									key='terms'
									color='brand.activeBlue'
									_hover={{ color: 'brand.deepBlue' }}
									href={privacyPolicyURL}
									target='_blank'
								>
									{formatMessage(signUpMessages.terms)}
								</ChakraLink>
							)
						})}
					</Checkbox>
				)}
			</Field>
		</QubeSectionForm>
	)
}
SignUpPartnerForm.propTypes = {
	handleOnSubmit: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(SignUpPartnerForm)
export { SignUpPartnerForm }
