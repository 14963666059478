import React from 'react'
import Card from '../cards/Card'
import Section from './Section'

const SectionCard = ({ ...props }) => (
	<Card>
		<Card.Content>
			<Section {...props} />
		</Card.Content>
	</Card>
)

export default SectionCard
