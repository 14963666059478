import { defineMessages } from 'react-intl'

export const scope = 'qrq.auth.forgotpassword'

export default defineMessages({
	requestReset: {
		id: `${scope}.label.requestReset`
	},
	title: {
		id: `${scope}.title`
	},
	emailSent: {
		id: `${scope}.label.emailSent`
	},
	allDone: {
		id: `${scope}.title.allDone`
	},
	send: {
		id: `${scope}.button.send`
	}
})
