import { Box, Drawer, DrawerContent, DrawerOverlay } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { SideNavMenu } from 'sidenav'

import { useDrawer } from './DrawerProvider'

const SidedNavFrame = ({ children, prefix }) => {
	const { isOpen: isDrawerOpen, onClose: onCloseDrawer } = useDrawer()
	const sidenav = <SideNavMenu prefix={prefix} />

	return (
		<>
			<Drawer isOpen={isDrawerOpen} placement='left' onClose={onCloseDrawer}>
				<DrawerOverlay />
				<DrawerContent maxW='sidenav.width'>{sidenav}</DrawerContent>
			</Drawer>
			<Box
				top={0}
				pos='fixed'
				h='100vh'
				display={{ base: 'none', md: 'block' }}
				pt='topnav.height'
				width='sidenav.width'
			>
				{sidenav}
			</Box>
			<Box pl={{ base: 0, md: 'sidenav.width' }}>{children}</Box>
		</>
	)
}

SidedNavFrame.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	prefix: PropTypes.string.isRequired
}

export default SidedNavFrame
