import React from 'react'
import { useDisclosure } from '@chakra-ui/core'

const DrawerContext = React.createContext({})

const useDrawer = () => {
	const context = React.useContext(DrawerContext)
	if (!context) {
		throw new Error(`useDrawer must be used within a DrawerProvider`)
	}
	return context
}

const DrawerProvider = props => {
	const { isOpen, onOpen, onClose } = useDisclosure()
	return <DrawerContext.Provider value={{ isOpen, onOpen, onClose }} {...props} />
}

export { useDrawer, DrawerProvider }
