import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { LoginContainer, SignUpContainer, SignUpPartnerContainer } from 'auth'
import { AuthApp, PasswordApp } from 'auth/AuthApp'
import { AuthenticatedRoute } from 'utils/routes'
import { ToastProvider } from 'utils/toasts'

import AdminApp from './admin/AdminApp'
import { setAuthToken, setLogoutOnAuthError } from './api/config'
import { useAuth } from './auth/AuthProvider'
import CenteredLoading from './ui/loadings/CenteredLoading'
import { ModalWrapper } from './utils/modals'

const App = () => {
	const { isLoading, getCurrentUser, token, logout } = useAuth()

	useEffect(() => {
		setAuthToken(token)
	}, [token])

	useEffect(() => {
		setLogoutOnAuthError(logout)
	}, [logout])

	useEffect(() => {
		getCurrentUser()
	}, [getCurrentUser])

	return isLoading ? (
		<CenteredLoading />
	) : (
		<>
			<ModalWrapper />
			<ToastProvider>
				<Routes>
					<Route element={<AuthenticatedRoute redirectPath='/login' authCriteria={true} />}>
						<Route path='*' element={<AdminApp />} />
					</Route>
					<Route
						element={
							<AuthApp>
								<AuthenticatedRoute redirectPath='/' authCriteria={false} />
							</AuthApp>
						}
					>
						<Route path='login' element={<LoginContainer />} />
						<Route path='signup' element={<SignUpContainer />} />
						<Route path='password/*' element={<PasswordApp />} />
						<Route path='partners' element={<SignUpPartnerContainer />} />
					</Route>
				</Routes>
			</ToastProvider>
		</>
	)
}
App.propTypes = {}

export default App

export { App }
