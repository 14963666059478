import React from 'react'
import { Outlet, Route, Routes } from 'react-router-dom'

import { ResetPasswordContainer, ForgotPasswordContainer } from 'auth/index'
import ToastsDisplayer from 'utils/toasts/ToastsDisplayer'

import AuthFrame from './AuthFrame'

export const PasswordApp = () => (
	<Routes>
		<Route exact path='forgot' element={<ForgotPasswordContainer />} />
		<Route exact path='reset' element={<ResetPasswordContainer />} />
	</Routes>
)

export const AuthApp = () => {
	return (
		<>
			<ToastsDisplayer />
			<AuthFrame>
				<Outlet />
			</AuthFrame>
		</>
	)
}
