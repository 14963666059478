import { defineMessages } from 'react-intl'

export const scope = 'qrq.buttons'

export default defineMessages({
	save: {
		id: `${scope}.save`
	},
	add: {
		id: `${scope}.add`
	},
	create: {
		id: `${scope}.create`
	}
})
