import React from 'react'
import PropTypes from 'prop-types'
import { Flex } from '@chakra-ui/core'
import { PageTitle } from '../../ui/text'

const PageHeader = ({ title, action }) => (
	<Flex justifyContent='space-between'>
		<PageTitle fontSize='xl' fontWeight='700'>
			{title}
		</PageTitle>
		{action}
	</Flex>
)

PageHeader.defaultProps = {
	action: null
}

PageHeader.displayName = 'PageHeader'

PageHeader.propTypes = {
	title: PropTypes.string.isRequired,
	action: PropTypes.node
}

export default PageHeader
