export const interceptAuthRequest = token => request => {
	if (token) {
		request.headers.Authorization = `Bearer ${token}`
	}

	return request
}
export const interceptAuthErrorResponse = logout => error => {
	if (error.response && error.response.status === 401) {
		logout()
	}

	return Promise.reject(error)
}
