import { defineMessages } from 'react-intl'

export const scope = 'qrq.qrcode'

export default defineMessages({
	revoke: {
		id: `${scope}.button.revoke`
	},
	revokeMessage: {
		id: `${scope}.revokeMessage`
	},
	pdf: {
		id: `${scope}.button.pdf`
	},
	qrCodeRevokedSuccessMessage: {
		id: `${scope}.qrCodeRevokedSuccessMessage`
	},
	qrCodeTitle: {
		id: `${scope}.title`
	}
})
