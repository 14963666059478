import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex } from '@chakra-ui/core'
import InlineStack from '../stacks/InlineStack'

const ListHeader = ({ checkbox, actions, pagination }) => {
	return (
		<Box
			borderWidth='1px'
			px={4}
			py={2}
			d={['block', 'flex']}
			borderX={0}
			borderBottom={0}
			alignItems='center'
			justify='center'
		>
			<Flex>
				{checkbox}
				<InlineStack mx={3}>{actions}</InlineStack>
			</Flex>
			<Box ml='auto'>{pagination}</Box>
		</Box>
	)
}

ListHeader.defaultProps = {
	checkbox: undefined,
	actions: [],
	pagination: undefined
}

ListHeader.propTypes = {
	checkbox: PropTypes.node,
	actions: PropTypes.arrayOf(PropTypes.node),
	pagination: PropTypes.node
}

export default ListHeader
