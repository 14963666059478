import React from 'react'
import PropTypes from 'prop-types'
import { PseudoBox } from '@chakra-ui/core'

const CardActions = ({ children }) => (
	<PseudoBox
		d='flex'
		justifyContent='space-around'
		bg='gray.100'
		px={2}
		py={1}
		_first={{ roundedTop: 'card' }}
		_last={{ roundedBottom: 'card' }}
	>
		{children}
	</PseudoBox>
)

CardActions.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

CardActions.displayName = 'CardActions'

export default CardActions
