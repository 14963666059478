import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Formik } from 'formik'
import FormStatus from './feedback/FormStatus'

const QubeForm = ({ initialValues, onSubmit, validationSchema, children }) => {
	const componentIsMounted = useRef(true)
	useEffect(() => {
		return () => {
			// used to control if component is mounted so setStatus doesn't throw error.
			// this is a formik problem and they should fix this soon
			componentIsMounted.current = false
		}
	}, [])

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={(data, { setSubmitting, setFieldError, setStatus, resetForm, ...formikBag }) =>
				onSubmit(data, { setSubmitting, setFieldError, setStatus, resetForm, ...formikBag })
					.then(response => {
						componentIsMounted.current && setStatus({ success: true })
						return Promise.resolve(response)
					})
					.catch(error => {
						setStatus({ error: true })
						return Promise.reject(error)
					})
					.finally(() => setSubmitting(false))
			}
			enableReinitialize={true}
			validateOnChange={false}
			validateOnBlur={false}
			validationSchema={validationSchema}
		>
			{formikBag => (
				<form onSubmit={formikBag.handleSubmit}>
					<FormStatus />
					{children}
				</form>
			)}
		</Formik>
	)
}
QubeForm.defaultProps = {
	validationSchema: {}
}
QubeForm.propTypes = {
	initialValues: PropTypes.shape({}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
	validationSchema: PropTypes.shape({})
}
export default QubeForm
