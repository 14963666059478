import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { useLocation } from 'locations/LocationProvider'
import { useConfirm } from '../utils/confirm/ConfirmProvider'
import { useToast } from '../utils/toasts'
import Button from '../buttons/Button'
import messages from './messages'

const QRCodeRevokeButtonContainer = ({ intl: { formatMessage } }) => {
	const {
		location,
		renewSignature: [onRenewSignature, { status }]
	} = useLocation()
	const { showSuccessToast } = useToast()
	const confirm = useConfirm()

	const revokeQRCodeHandler = () =>
		confirm({ title: formatMessage(messages.revoke) }).then(() => {
			onRenewSignature(location.id, {
				onSuccess: () => showSuccessToast(formatMessage(messages.qrCodeRevokedSuccessMessage))
			})
		})

	return (
		<Button onClick={revokeQRCodeHandler} variantColor='red' isLoading={status === 'loading'}>
			{formatMessage(messages.revoke)}
		</Button>
	)
}

QRCodeRevokeButtonContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(QRCodeRevokeButtonContainer)
