import { SYSTEM_RESET, TICKET_CALLED, TICKET_GENERATED } from './actions'

export const initialState = {
	lastCalledTicket: undefined,
	lastGeneratedTicket: undefined
}

export const reducer = (state, action) => {
	switch (action.type) {
		case TICKET_GENERATED:
			return { ...state, lastGeneratedTicket: action.payload }
		case TICKET_CALLED:
			return { ...state, lastCalledTicket: action.payload }
		case SYSTEM_RESET:
			return { ...state, lastCalledTicket: undefined, lastGeneratedTicket: undefined }
		default:
			return state
	}
}
