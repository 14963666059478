import { Image } from '@chakra-ui/core'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import { useLocation } from 'locations/LocationProvider'

import { useDrawer } from '../../sidenav/DrawerProvider'
import TopnavButton from './TopnavButton'

const DrawerToggler = () => {
	const { onOpen } = useDrawer()
	const { location } = useLocation()
	const navigate = useNavigate()

	return (
		<TopnavButton px={3} onClick={() => (location ? onOpen() : navigate('/'))} rightIcon={location && 'downNav'}>
			<Image src='/images/logo/QRQueueSymbol.svg' alt='small logo' ignoreFallback={true} />
		</TopnavButton>
	)
}

export default DrawerToggler
