import React, { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'

import AdminContentFrame from 'admin/AdminContentFrame'
import MQTTService from 'mqtt/MQTTService'

import { qubeAxios } from '../api'
import { useAuth } from '../auth/AuthProvider'
import messageHandler from '../mqtt/messageHandler'
import PlansAndBillingsApp from '../plansAndBilling/PlansAndBillingsApp'
import QRCodeContainer from '../qrcode/QRCodeContainer'
import QueueManagementContainer from '../queueManagement/QueueManagementContainer'
import SettingsContainer from '../settings/SettingsContainer'
import SidedNavFrame from '../sidenav/SidedNavFrame'
import CenteredLoading from '../ui/loadings/CenteredLoading'
import errorsMessages from '../utils/errors/messages'
import { modalManager } from '../utils/modals'
import modalMessages from '../utils/modals/messages'
import { useLocation } from './LocationProvider'

const LocationApp = () => {
	const { currentCycle, fetchLocation, isLoading: isFetchingLocation, mqttMessageHandlers } = useLocation()
	const { token } = useAuth()
	const { locationId } = useParams()
	const { formatMessage } = useIntl()

	useEffect(() => {
		qubeAxios.setLocation(parseInt(locationId, 10))
		MQTTService.setUsername(token)
		MQTTService.getClient().on('error', () =>
			modalManager.showModal(formatMessage(modalMessages.error), [formatMessage(errorsMessages.mqttErrorMessage)])
		)

		const apply = handler => fns => (topic, message) => handler(topic, message, fns)
		MQTTService.getClient().on('message', apply(messageHandler)(mqttMessageHandlers))

		return () => MQTTService.getClient().end()
	}, [token, mqttMessageHandlers, locationId, formatMessage])

	useEffect(() => {
		fetchLocation(locationId)
	}, [fetchLocation, locationId])

	const hasCurrentCycle = !isFetchingLocation && !!currentCycle
	const Frame = useMemo(
		() => (hasCurrentCycle ? props => <SidedNavFrame prefix={`/${locationId}`} {...props} /> : React.Fragment),
		[hasCurrentCycle, locationId]
	)

	return (
		<Frame>
			<AdminContentFrame>
				{!isFetchingLocation ? (
					hasCurrentCycle ? (
						<Routes>
							<Route path='qm' element={<QueueManagementContainer />} />
							<Route path='qrcode' element={<QRCodeContainer />} />
							<Route path='settings' element={<SettingsContainer />} />
							<Route path='plans-billings/*' element={<PlansAndBillingsApp />} />
							<Route path='*' element={<Navigate to='qm' replace />} />
						</Routes>
					) : (
						<Routes>
							<Route path='plans-billings/*' element={<PlansAndBillingsApp />} />
							<Route
								path='*'
								element={<Navigate to='plans-billings/subscription-plans/create' replace />}
							/>
						</Routes>
					)
				) : (
					<CenteredLoading />
				)}
			</AdminContentFrame>
		</Frame>
	)
}

export default LocationApp
