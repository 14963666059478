import React from 'react'
import { Box } from '@chakra-ui/core'
import QubeLogoTopnavButton from './buttons/QRQueueLogoTopnavButton'
import DrawerToggler from './buttons/DrawerToggler'

const LogoTopnav = () => {
	const displayOnMobile = { base: 'block', md: 'none' }
	const displayOnLaptop = { base: 'none', md: 'block' }
	return (
		<>
			<Box display={displayOnLaptop}>
				<QubeLogoTopnavButton />
			</Box>
			<Box display={displayOnMobile}>
				<DrawerToggler />
			</Box>
		</>
	)
}

export default LogoTopnav
