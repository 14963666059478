import { useTheme, Box } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { MENU_ENTRY_HORIZONTAL_MARGIN, MENU_ENTRY_ICON_SIZE } from './MenuEntry'

const MainMenuBar = ({ isCollapsed, children, ...props }) => {
	const { sizes, space } = useTheme()
	const iconFontSize = sizes[MENU_ENTRY_ICON_SIZE]
	const entryPaddingWidth = space[MENU_ENTRY_HORIZONTAL_MARGIN]
	return (
		<Box
			py={2}
			bg='sidenav.bg'
			width={isCollapsed ? `calc(${iconFontSize} + ${entryPaddingWidth} + ${entryPaddingWidth})` : '100%'}
			transition='width 260ms cubic-bezier(0.2, 0.2, 0.2, 1) 0s'
			overflowX='hidden'
			overflowY='auto'
			{...props}
		>
			{children}
		</Box>
	)
}

MainMenuBar.propTypes = {
	isCollapsed: PropTypes.bool.isRequired,
	children: PropTypes.node.isRequired
}

export default MainMenuBar
