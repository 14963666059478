import { defineMessages } from 'react-intl'

export const scope = 'qrq.queueManagement'

export default defineMessages({
	peopleInside: {
		id: `${scope}.peopleInside`
	},
	lastCalledTicket: {
		id: `${scope}.lastCalledTicket`
	},
	lastGeneratedTicket: {
		id: `${scope}.lastGeneratedTicket`
	},
	reset: {
		id: `${scope}.reset`
	},
	callNext: {
		id: `${scope}.callNext`
	},
	generateTicket: {
		id: `${scope}.generateTicket`
	},
	ticketsWaiting: {
		id: `${scope}.ticketsWaiting`
	},
	confirmResetTitle: {
		id: `${scope}.confirmReset.title`
	},
	confirmResetDescriptionTicketsWaiting: {
		id: `${scope}.confirmReset.description.ticketsWaiting`
	},
	confirmResetDescriptionPeopleInside: {
		id: `${scope}.confirmReset.description.peopleInside`
	},
	noTicketsTitle: {
		id: `${scope}.noTickets.title`
	},
	noTicketsDescription: {
		id: `${scope}.noTickets.description`
	},
	loadMoreTickets: {
		id: `${scope}.loadMoreTickets`
	},
	noTicketsToCount: {
		id: `${scope}.noTicketsToCount`
	},
	refreshTicketsList: {
		id: `${scope}.refreshTicketsList`
	},
	peopleInsideLimitExceeded: {
		id: `${scope}.peopleInsideLimitExceeded`
	},
	cleanOldTickets: {
		id: `${scope}.cleanOldTickets`
	},
	ticketCheckin: {
		id: `${scope}.title.ticketCheckin`
	},
	queueManagementTitle: {
		id: `${scope}.title`
	},
	numberOfPeople: {
		id: `${scope}.numberOfPeople`
	},
	peopleNumberMore: {
		id: `${scope}.peopleNumberMore`
	},
	peopleNumberPlaceholder: {
		id: `${scope}.peopleNumberPlaceholder`
	}
})
