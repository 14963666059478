import React from 'react'
import PropTypes from 'prop-types'
import { Icon } from '@chakra-ui/core'

const FeedbackIcon = ({ status, ...props }) => {
	switch (status) {
		case 'loading':
			return <Icon name='loading' color='gray.400' {...props} />
		case 'success':
			return <Icon color='green.300' name='success' {...props} />
		case 'error':
			return <Icon color='brand.red' name='info' {...props} />
		default:
			return null
	}
}

FeedbackIcon.defaultProps = {
	status: undefined
}
FeedbackIcon.propTypes = {
	status: PropTypes.oneOf(['loading', 'success', 'error'])
}
export default FeedbackIcon
