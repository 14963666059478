import QubeAxios from './QubeAxios'
import { interceptAuthRequest, interceptAuthErrorResponse } from './interceptors'
import { supportedLocale } from '../locales/LocaleData'

const VERSION = 'api/v1/'

export const qubeAxios = new QubeAxios(`${process.env.REACT_APP_API_PATH}${supportedLocale}/${VERSION}`)
let currentAuthorizationInterceptor

export const setLogoutOnAuthError = logout => {
	qubeAxios.interceptors.response.use(response => response, interceptAuthErrorResponse(logout))
}

export const setAuthToken = token => {
	qubeAxios.interceptors.request.eject(currentAuthorizationInterceptor)
	currentAuthorizationInterceptor = qubeAxios.interceptors.request.use(interceptAuthRequest(token), error =>
		Promise.reject(error)
	)
}
