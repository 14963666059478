import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Image, Stack } from '@chakra-ui/core'

const AuthFrame = ({ children }) => (
	<Flex
		h='100vh'
		px={[5, 4]}
		justify='center'
		bgImage={`url(/images/login_background.jpg)`}
		bgRepeat='no-repeat'
		bgSize='cover'
		bgPos='center'
	>
		<Stack width='100%' align='center'>
			<Image p={5} src='/images/logo/QRQueueLogo.svg' alt='logo' ignoreFallback={true} />
			<Box bg='gray.200' px={3} py={5} rounded='lg' maxWidth='lg' width={['100%', 'md']}>
				{children}
			</Box>
		</Stack>
	</Flex>
)

AuthFrame.propTypes = {
	children: PropTypes.node.isRequired
}

export default AuthFrame
