import React from 'react'
import PropTypes from 'prop-types'
import { PseudoBox } from '@chakra-ui/core'

const CardState = ({ children }) => (
	<PseudoBox
		d='flex'
		bg='gray.300'
		py={1}
		justifyContent='center'
		_first={{ roundedTop: 'card' }}
		_last={{ roundedBottom: 'card' }}
	>
		{children}
	</PseudoBox>
)

CardState.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

CardState.displayName = 'CardState'

export default CardState
