import React from 'react'
import { Flex, Box } from '@chakra-ui/core'
import { useToast } from './ToastProvider'
import Toast from './Toast'

const ToastsDisplayer = () => {
	const { toasts } = useToast()
	return (
		<Flex justify='center' pointerEvents='none'>
			<Box pos='fixed' zIndex='toast' p={4} w={{ base: '100%', md: 'md' }}>
				{toasts.map(toast => (
					<Toast key={toast.id} {...toast} />
				))}
			</Box>
		</Flex>
	)
}

export default ToastsDisplayer
