import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import { PageTitle } from 'ui/text'
import { SectionStack } from 'utils/layout'

import api from '../../api/api'
import { useToast } from '../../utils/toasts'
import SignUpForm from './SignUpPartnerForm'
import messages from './messages'

const SignUpPartnerContainer = ({ intl: { formatMessage } }) => {
	const { showSuccessToast } = useToast()
	const handleOnSubmit = data => {
		const formData = {
			user: {
				email: data.user.email,
				username: data.user.email,
				password1: data.user.password1,
				password2: data.user.password1
			},
			name: data.name,
			referral: data.referral
		}

		return api.auth.signUp(formData).then(() => showSuccessToast(formatMessage(messages.accountCreated)))
	}

	return (
		<SectionStack>
			<SectionStack.Element>
				<PageTitle>{formatMessage(messages.title)}</PageTitle>
			</SectionStack.Element>
			<SectionStack.Element>
				<SignUpForm handleOnSubmit={handleOnSubmit} />
			</SectionStack.Element>
		</SectionStack>
	)
}

SignUpPartnerContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(SignUpPartnerContainer)
