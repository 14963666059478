import { Box, Flex, Icon, Link, SimpleGrid, Stack, Text } from '@chakra-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { queryCache, useMutation, useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { api } from 'api'
import { Button } from 'buttons'
import { useLocation } from 'locations/LocationProvider'
import { CURRENT_CYCLE_KEY } from 'locations/entityKeys'
import { MediumText, PageTitle } from 'ui'
import MetricsCard from 'ui/cards/MetricsCard'
import CenteredLoading from 'ui/loadings/CenteredLoading'
import { InlineStack } from 'ui/stacks'
import { interceptError } from 'utils/errors/errorInterceptors'
import { PageHeader, SectionStack } from 'utils/layout'
import Container from 'utils/layout/Container'

import SubscriptionPlanName from '../../subscription-plan-groups/components/SubscriptionPlanName'
import subscriptionMessages from '../../subscriptions/messages'
import { Section, SectionCard } from '../../ui/sections'
import { CYCLES_KEY, CYCLE_KEY } from '../entityKeys'
import messages from '../messages'

const fetchCurrentCycleByLocationId = (_, locationId) => api.cycles.current(locationId)

const CycleDetails = ({
	cycle: {
		subscription: { subscription_plan: subscriptionPlan },
		start_date,
		valid_until
	},
	...props
}) => {
	const { formatMessage } = useIntl()

	return (
		<Stack spacing={2} {...props}>
			<Box>
				<MediumText fontWeight='bold'>{formatMessage(subscriptionMessages.subscriptionPlan)}</MediumText>
				<MediumText>
					<SubscriptionPlanName subscriptionPlan={subscriptionPlan} />
				</MediumText>
			</Box>
			<Box>
				<MediumText fontWeight='bold'>
					{formatMessage(messages.startingDate)} - {formatMessage(messages.endingDate)}
				</MediumText>
				<MediumText>
					{start_date} - {valid_until}
				</MediumText>
			</Box>
		</Stack>
	)
}

const PendingCycle = ({ cycle, refetch, isFetching }) => {
	const { formatMessage } = useIntl()

	return (
		<Stack spacing={4} align='center' textAlign='center'>
			<Icon name='loading' width='20' height='20' color='primary.500' />
			<PageTitle>{formatMessage(messages.processingTitle)}</PageTitle>
			<CycleDetails cycle={cycle} />
			<Button onClick={refetch} isLoading={isFetching}>
				{formatMessage(messages.refresh)}
			</Button>
		</Stack>
	)
}

const NotPaidCycle = ({ cycle }) => {
	const { formatMessage } = useIntl()
	const { stripe_checkout_session } = cycle

	return (
		<Stack spacing={4} align='center' textAlign='center'>
			<Icon name='success' width='20' height='20' color='primary.500' />
			<PageTitle>{formatMessage(messages.notPaidTitle)}</PageTitle>
			<CycleDetails cycle={cycle} />
			<Button as={Link} href={stripe_checkout_session.checkout_session_url}>
				{formatMessage(messages.goToPayment)}
			</Button>
		</Stack>
	)
}

const PaidCycle = ({ cycle }) => {
	const { formatMessage } = useIntl()
	const navigate = useNavigate()

	const { data: currentCycle } = useQuery(
		[CURRENT_CYCLE_KEY, cycle.subscription.location],
		fetchCurrentCycleByLocationId,
		{
			enabled: cycle.subscription.location,
			retry: false
		}
	)

	const isCycleOfATrialSubscriptionPlanGroup = cycle.subscription.subscription_plan.subscription_plan_group.is_trial

	const now = new Date()

	const isCurrentCycle = cycle.id === currentCycle?.data.id

	const isExpired = new Date(cycle.valid_until) < now

	const expirationWarningDate = new Date(cycle.valid_until)

	const warningPeriod = cycle.subscription.subscription_plan.duration === 12 ? 14 : 7

	expirationWarningDate.setDate(expirationWarningDate.getDate() - warningPeriod)

	const isAboutToExpire = new Date(cycle.valid_until) >= now && expirationWarningDate <= now

	const [renewPlan, { isLoading }] = useMutation(api.subscriptions.renew, {
		onSuccess: ({ data }) => {
			queryCache.invalidateQueries([CYCLES_KEY, { locationId: cycle.subscription.location }])
			queryCache.invalidateQueries(CURRENT_CYCLE_KEY)
			if (data) {
				navigate(`../${data.id}`, { replace: true })
			}
		},
		onError: interceptError
	})

	return isLoading ? (
		<CenteredLoading />
	) : (
		<SectionStack>
			<SectionStack.Element>
				<PageHeader title={formatMessage(messages.detailsTitle)} />
			</SectionStack.Element>
			<SectionStack.Element>
				<SectionCard title={formatMessage(messages.details)}>
					<CycleDetails cycle={cycle} />
					<Flex
						justifyContent='space-between'
						align={{ base: 'left', xs: 'center' }}
						flexDirection={{ base: 'column', xs: 'row' }}
					>
						<Box mb={{ base: 3, xs: 0 }}>
							{isExpired && isCurrentCycle && (
								<InlineStack>
									<Icon name='warning' color='brand.red' />
									<Text color='brand.red'>{formatMessage(messages.expired)}</Text>
								</InlineStack>
							)}
							{isAboutToExpire && isCurrentCycle && (
								<InlineStack>
									<Icon name='warning' color='brand.red' />
									<Text color='brand.red'>{formatMessage(messages.aboutToExpire)}</Text>
								</InlineStack>
							)}
						</Box>
						<Box>
							{isCurrentCycle &&
								(isCycleOfATrialSubscriptionPlanGroup ? (
									<Button onClick={() => navigate('../create/', { replace: true })}>
										{formatMessage(messages.upgradePlan)}
									</Button>
								) : (
									<Button
										onClick={() =>
											renewPlan({
												locationId: cycle.subscription.location,
												subscriptionId: cycle.subscription.id
											})
										}
									>
										{formatMessage(messages.renewPlan)}
									</Button>
								))}
						</Box>
					</Flex>
				</SectionCard>
			</SectionStack.Element>
			<SectionStack.Element>
				<Section title={formatMessage(messages.ticketsTitle)}>
					<SimpleGrid columns={[1, 1, 2, 1, 2]} spacing='4'>
						<MetricsCard label={formatMessage(messages.generatedTickets)} value={cycle.number_tickets} />
						<MetricsCard
							label={formatMessage(messages.ticketsLeft)}
							value={cycle.max_tickets - cycle.number_tickets}
						/>
					</SimpleGrid>
				</Section>
			</SectionStack.Element>
		</SectionStack>
	)
}

const CycleContainer = () => {
	const { location } = useLocation()
	const { cycleId } = useParams()

	const { isLoading, isSuccess, isFetching, refetch, data: cycle } = useQuery(
		[CYCLE_KEY, parseInt(location.id, 10), parseInt(cycleId, 10)],
		async (_, locationId) => {
			const { data } = await api.cycles.detail({ locationId, cycleId })
			return data
		},
		{ onError: interceptError }
	)
	const isPaid = !!cycle?.started_at

	return (
		<>
			{isLoading && <CenteredLoading />}
			{isSuccess &&
				(!isPaid ? (
					!cycle?.stripe_checkout_session ? (
						<PendingCycle cycle={cycle} refetch={refetch} isFetching={isFetching} />
					) : (
						<NotPaidCycle cycle={cycle} />
					)
				) : (
					<Container>
						<PaidCycle cycle={cycle} />
					</Container>
				))}
		</>
	)
}

export default CycleContainer
