import React from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Menu, MenuButton, MenuList } from '@chakra-ui/core'
import LogoutTopnavMenuItem from './LogoutTopnavMenuItem'

const ProfileTopnavMenu = ({ profileName }) => {
	const displayOnLaptop = { base: 'none', md: 'block' }
	const displayOnMobile = { base: 'block', md: 'none' }
	const buttonProps = {
		as: Button,
		_focus: { boxShadow: 'none' },
		_active: { color: 'topnav.button.hoverText', bg: 'topnav.button.hoverBg' },
		_hover: { color: 'topnav.button.hoverText', bg: 'topnav.button.hoverBg' },
		bg: 'topnav.button.bg',
		fontWeight: 'normal',
		px: 3,
		rightIcon: 'downNav'
	}

	const menuList = (
		<MenuList>
			<LogoutTopnavMenuItem />
		</MenuList>
	)

	return (
		<>
			<Menu>
				<MenuButton {...buttonProps} display={displayOnLaptop} leftIcon='user'>
					{profileName}
				</MenuButton>
				{menuList}
			</Menu>
			<Menu>
				<MenuButton {...buttonProps} display={displayOnMobile}>
					<Icon name='user' />
				</MenuButton>
				{menuList}
			</Menu>
		</>
	)
}

ProfileTopnavMenu.propTypes = {
	profileName: PropTypes.string.isRequired
}

export default ProfileTopnavMenu
