import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'

import CyclesApp from 'cycles/CyclesApp'

const PlansAndBillingsApp = () => {
	return (
		<Routes>
			<Route path='subscription-plans/*' element={<CyclesApp />} />
			<Route path='*' element={<Navigate to='subscription-plans' replace />} />
		</Routes>
	)
}

export default PlansAndBillingsApp
