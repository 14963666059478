import { ButtonGroup, Stack, Text } from '@chakra-ui/core'
import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { usePaginatedQuery } from 'react-query'

import { useAuth } from 'auth/AuthProvider'
import { Button } from 'buttons'
import subscriptionPlansMessages from 'subscription-plan-groups/messages'
import { CardsList, ListHeader } from 'ui/lists'
import CenteredLoading from 'ui/loadings/CenteredLoading'

import api, { defaultPageSize } from '../../api/api'
import { interceptError } from '../../utils/errors/errorInterceptors'
import Pagination from '../../utils/pagination/Pagination'
import SubscriptionPlanCard from '../components/SubscriptionPlanCard'
import { SUBSCRIPTION_PLAN_GROUPS_KEY } from '../entityKeys'
import { useSearchParams } from 'react-router-dom'

const DURATION_OPTIONS = [
	{
		duration: 1,
		msgDesc: subscriptionPlansMessages.monthly
	},
	{
		duration: 12,
		msgDesc: subscriptionPlansMessages.yearly
	}
]

const fetchSubscriptionPlanGroups = (_, params) => api.subscriptionPlanGroups.list(params)

const computeMaxRelativePriceDeviation = ({ subscription_plans: subPlans }) => {
	const filterAndNormalizePrices = durationFilter =>
		subPlans.filter(subPlan => durationFilter(subPlan.duration)).map(subPlan => subPlan.price / subPlan.duration)

	const yearlyPrice = Math.min(...filterAndNormalizePrices(duration => duration === 12))
	const otherPrices = Math.max(...filterAndNormalizePrices(duration => duration < 12))

	return (otherPrices - yearlyPrice) / otherPrices
}

const computeYearlySavingInMonths = subPlanGroups =>
	12 * Math.max(...subPlanGroups.map(computeMaxRelativePriceDeviation))

const SubscriptionPlansListContainer = () => {
	const { user } = useAuth()
	const { formatMessage } = useIntl()
	const [selectedDuration, setSelectedDuration] = useState(1)

	const [searchParams] = useSearchParams()
	const activePage = Number(searchParams.get('activePage')) || 1

	const { status, resolvedData } = usePaginatedQuery(
		[
			SUBSCRIPTION_PLAN_GROUPS_KEY,
			{ page: activePage, is_trial: user.profile.has_consumed_trial ? false : undefined }
		],
		fetchSubscriptionPlanGroups,
		{ retry: false, onError: interceptError, enabled: !!user }
	)

	const { count = 0, results = [] } = resolvedData?.data || {}
	const totalPages = Math.ceil(results.length / defaultPageSize)

	const yearlySavingInMonths = Math.floor(computeYearlySavingInMonths(results))

	const cardsData = results
		.sort((subPlanGroupA, subPlanGroupB) => subPlanGroupB.is_trial - subPlanGroupA.is_trial)
		.map(subPlanGroup => ({
			subPlanGroup,
			subPlan: subPlanGroup.subscription_plans.find(
				({ duration }) => duration === selectedDuration || subPlanGroup.is_trial
			)
		}))

	return (
		<>
			<Stack isInline align='center'>
				<ButtonGroup isAttached variant='outline' my={4}>
					{DURATION_OPTIONS.map(({ duration, msgDesc }) => (
						<Button
							key={duration}
							onClick={() => setSelectedDuration(duration)}
							variantColor={selectedDuration !== duration ? 'gray' : null}
							children={formatMessage(msgDesc)}
						/>
					))}
				</ButtonGroup>
				{isFinite(yearlySavingInMonths) && yearlySavingInMonths > 0 ? (
					<Text display={['none', 'none', 'none', 'none', 'block']} color='primary.500'>
						{formatMessage(subscriptionPlansMessages.yearlySavingInMonths, {
							number: yearlySavingInMonths
						})}
					</Text>
				) : null}
			</Stack>

			{count !== 0 && <ListHeader pagination={<Pagination activePage={activePage} totalPages={totalPages} />} />}
			{status === 'loading' && <CenteredLoading />}
			{status === 'success' && count !== 0 && (
				<CardsList>
					{cardsData.map(({ subPlanGroup, subPlan }) => (
						<SubscriptionPlanCard key={subPlanGroup.id} subPlanGroup={subPlanGroup} subPlan={subPlan} />
					))}
				</CardsList>
			)}
		</>
	)
}

export default SubscriptionPlansListContainer
