import { defineMessages } from 'react-intl'

export const scope = 'qrq.auth.signupPartner'

export default defineMessages({
	createAccount: {
		id: `${scope}.button.createAccount`
	},
	title: {
		id: `${scope}.title`
	},
	email: {
		id: `${scope}.label.email`
	},
	password: {
		id: `${scope}.label.password`
	},
	name: {
		id: `${scope}.label.name`
	},
	referral: {
		id: `${scope}.label.referral`
	},
	accountCreated: {
		id: `${scope}.accountCreated`
	}
})
