import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import messages from '../messages'

const SubscriptionPlanName = ({
	subscriptionPlan: { subscription_plan_group, max_tickets, duration },
	intl: { formatMessage }
}) => {
	const msgDesc = duration === 1 ? messages.ticketsPerMonth : messages.ticketsPerYear
	return (
		<>
			{subscription_plan_group.display_name} - {formatMessage(msgDesc, { number: max_tickets })}
		</>
	)
}

SubscriptionPlanName.propTypes = {
	subscriptionPlan: PropTypes.shape({
		subscription_plan_group: PropTypes.shape({
			display_name: PropTypes.string.isRequired
		}).isRequired,
		max_tickets: PropTypes.number.isRequired,
		duration: PropTypes.number.isRequired
	}).isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(SubscriptionPlanName)
