import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Text } from '@chakra-ui/core'

import { SectionStack } from 'utils/layout'
import { PageTitle } from 'ui/text'
import { api } from 'api'
import messages from './messages'
import ForgotPasswordForm from './ForgotPasswordForm'
import Link from '../../ui/links/Link'
import authMessages from '../messages'

const STATUS = {
	FORM: 0,
	SUCCESS: 1
}

const ForgotPasswordContainer = ({ intl: { formatMessage } }) => {
	const [status, setStatus] = useState(STATUS.FORM)

	const handleOnSubmit = values => api.auth.resetPassword(values).then(() => setStatus(STATUS.SUCCESS))

	const title = status === STATUS.SUCCESS ? messages.allDone : messages.title

	const content =
		status === STATUS.SUCCESS ? (
			<Text>{formatMessage(messages.emailSent)}</Text>
		) : (
			<SectionStack>
				<SectionStack.Element>
					<Text>{formatMessage(messages.requestReset)}</Text>
				</SectionStack.Element>
				<SectionStack.Element>
					<ForgotPasswordForm handleOnSubmit={handleOnSubmit} />
				</SectionStack.Element>
			</SectionStack>
		)

	return (
		<SectionStack>
			<SectionStack.Element>
				<PageTitle>{formatMessage(title)}</PageTitle>
			</SectionStack.Element>
			{content}
			<SectionStack.Element mx='auto'>
				<Link id='forgot-password-link-to-login' to='/login'>
					{formatMessage(authMessages.backToLogin)}
				</Link>
			</SectionStack.Element>
		</SectionStack>
	)
}

ForgotPasswordContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(ForgotPasswordContainer)
export { ForgotPasswordContainer }
