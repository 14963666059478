import { defineMessages } from 'react-intl'

export const scope = 'qrq.auth.resetpassword'

export default defineMessages({
	resetPasswordHeader: {
		id: `${scope}.label.resetPassword`
	},
	title: {
		id: `${scope}.title`
	},
	passwordUpdated: {
		id: `${scope}.label.passwordUpdated`
	},
	invalidUrl: {
		id: `${scope}.label.invalidUrl`
	},
	resetPassword: {
		id: `${scope}.button.resetPassword`
	}
})
