import { useEffect } from 'react'
import { connect } from 'formik'
import _ from 'lodash'

const FormStatus = ({ formik: { errors, setStatus } }) => {
	const hasErrors = !_.isEmpty(errors)

	useEffect(() => {
		setStatus({ error: hasErrors })
	}, [hasErrors, setStatus])

	return null
}

export default connect(FormStatus)
export { FormStatus }
