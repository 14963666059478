import { qubeAxios as axios } from './config'

export const defaultPageSize = 25

export const COUNT_PENDING = 1
export const COUNT_COUNTED = 2
export const COUNT_CLEANED = 3
export const STATE_WAITING = 1
export const STATE_CALLED = 2

export default {
	auth: {
		login: data => axios.post('login/', data),
		logout: () => axios.post('logout/'),
		resetPassword: data => axios.post('password/reset/', data),
		confirmResetPassword: data => axios.post('password/reset/confirm/', data),
		signUp: data => axios.post('profiles/', data)
	},
	users: {
		current: () => axios.get('users/current/')
	},
	locations: {
		detail: id => axios.get(`locations/${id}/`),
		create: ({ profileId, data }) => axios.post(`profiles/${profileId}/locations/`, data),
		renewSignature: id => axios.post(`locations/${id}/renew-signature/`),
		increment: id => axios.post(`locations/${id}/increment/`),
		decrement: id => axios.post(`locations/${id}/decrement/`),
		edit: ({ id, data }) => axios.patch(`locations/${id}/`, data)
	},
	qm: {
		generateTicket: (id, numberOfPeople) =>
			axios.post(`locations/${id}/tickets/generate/`, { number_of_people: numberOfPeople }),
		callNext: id => axios.post(`locations/${id}/tickets/call/`),
		resetQMSystem: id => axios.post(`locations/${id}/reset/`)
	},
	profileLocations: {
		list: ({ profileId, ...params }) =>
			axios.get(`profiles/${profileId}/locations/`, { params: { page_size: defaultPageSize, ...params } })
	},
	cycles: {
		current: locationId => axios.get(`locations/${locationId}/cycles/current/`),
		list: ({ locationId, ...params }) =>
			axios.get(`locations/${locationId}/cycles/`, { params: { page_size: defaultPageSize, ...params } }),
		detail: ({ locationId, cycleId }) => axios.get(`locations/${locationId}/cycles/${cycleId}/`)
	},
	tickets: {
		listCalled: ({ locationId, ...params }) =>
			axios.get(`locations/${locationId}/tickets/`, {
				params: { state: STATE_CALLED, count_state: COUNT_PENDING, ...params }
			}),
		count: ({ locationId, ticketId }) => axios.post(`locations/${locationId}/tickets/${ticketId}/count/`),
		cleanOld: ({ locationId }) => axios.post(`locations/${locationId}/tickets/clean-old/`)
	},
	subscriptionPlans: {
		detail: id => axios.get(`subscription-plans/${id}/`)
	},
	subscriptions: {
		create: ({ locationId, data }) => axios.post(`locations/${locationId}/subscriptions/`, data),
		renew: ({ locationId, subscriptionId }) =>
			axios.post(`locations/${locationId}/subscriptions/${subscriptionId}/cycles/`)
	},
	subscriptionPlanGroups: {
		list: ({ ...params }) =>
			axios.get(`subscription-plan-groups/`, { params: { page_size: defaultPageSize, ...params } })
	}
}
