import React from 'react'
import { Button, Icon } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import { InlineStack } from '../../ui/stacks'
import { Title } from '../../ui/text'

export const QueueManagementAction = ({ icon, actionTitle, isLoading, ...props }) => (
	<Button
		h='70px'
		borderRadius='0.5rem 0 0 0.5rem'
		variant='solid'
		fontSize='15px'
		rounded={15}
		isDisabled={isLoading}
		{...props}
	>
		<InlineStack width='100%' textAlign='left'>
			<Icon name={icon} size='10' />

			<Title textTransform='uppercase' isTruncated flex={1}>
				{actionTitle}
			</Title>
		</InlineStack>
	</Button>
)

QueueManagementAction.defaultProps = {
	isLoading: false
}

QueueManagementAction.propTypes = {
	icon: PropTypes.string.isRequired,
	actionTitle: PropTypes.string.isRequired,
	isLoading: PropTypes.bool
}

export default QueueManagementAction
