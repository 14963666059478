import React from 'react'
import { Spinner, Flex } from '@chakra-ui/core'

const CenteredLoading = () => {
	return (
		<Flex>
			<Spinner size='lg' color='brand.activeBlue' mx='auto' />
		</Flex>
	)
}

export default CenteredLoading
