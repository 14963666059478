import { defineMessages } from 'react-intl'

export const scope = 'qrq.settings'

export default defineMessages({
	locationDetails: {
		id: `${scope}.locationDetails`
	},
	locationUpdateSuccessMessage: {
		id: `${scope}.locationUpdateSuccessMessage`
	},
	settingsTitle: {
		id: `${scope}.title`
	}
})
