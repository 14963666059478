import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Input } from '@chakra-ui/core'

import { QubeSectionForm, Field, FormLabel } from 'utils/forms'
import messages from './messages'
import authMessages from '../messages'
import { ForgotPasswordSchema } from './schemas'

const ForgotPasswordForm = ({ handleOnSubmit, intl: { formatMessage } }) => (
	<QubeSectionForm
		initialValues={{ email: '' }}
		validationSchema={ForgotPasswordSchema(formatMessage)}
		onSubmit={handleOnSubmit}
		submitMessage={messages.send}
	>
		<Field
			name='email'
			label={<FormLabel htmlFor='forgot-password-input-email'>{formatMessage(authMessages.email)}</FormLabel>}
		>
			{({ field }) => <Input id='forgot-password-input-email' icon='mail' type='email' {...field} />}
		</Field>
	</QubeSectionForm>
)

ForgotPasswordForm.displayName = 'ForgotPasswordForm'

ForgotPasswordForm.propTypes = {
	handleOnSubmit: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(ForgotPasswordForm)
export { ForgotPasswordForm }
