import React from 'react'
import PropTypes from 'prop-types'
import { Box, SimpleGrid } from '@chakra-ui/core'

const CardsList = ({ children }) => {
	return (
		<SimpleGrid columns={{ sm: 2, md: 2, lg: 3, xl: 4 }} spacing='4' columnGap='12'>
			{[].concat(children).map((item, index) => (
				<Box key={index}>{item}</Box>
			))}
		</SimpleGrid>
	)
}

CardsList.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

export default CardsList
