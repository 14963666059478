import React from 'react'
import { Box, Button as ChakraButton } from '@chakra-ui/core'
import { useMutation, queryCache } from 'react-query'
import { FormattedMessage } from 'react-intl'

import { CALLED_TICKETS_KEY } from 'locations/entityKeys'
import { interceptError } from 'utils/errors/errorInterceptors'
import { useLocation } from 'locations/LocationProvider'
import { api } from '../../api'
import SmallText from '../../ui/text/SmallText'
import messages from '../messages'

const CleanOldTicketsButtonContainer = ({ ...props }) => {
	const {
		location: { id: locationId }
	} = useLocation()
	const cleanOldTicketsQuery = () => api.tickets.cleanOld({ locationId })
	const [cleanOldTickets, { status }] = useMutation(cleanOldTicketsQuery, {
		onSuccess: () => queryCache.invalidateQueries([CALLED_TICKETS_KEY, { locationId }]),
		onError: interceptError
	})

	return (
		<Box {...props}>
			<ChakraButton
				onClick={cleanOldTickets}
				variantColor='gray'
				leftIcon='trash'
				disabled={status === 'loading'}
				size='lg'
			>
				<SmallText>
					<FormattedMessage {...messages.cleanOldTickets} values={{ linebreak: <br /> }} />
				</SmallText>
			</ChakraButton>
		</Box>
	)
}

export default CleanOldTicketsButtonContainer
