import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'

import { PageTitle } from 'ui/text'
import { SectionStack } from 'utils/layout'

import Link from '../../ui/links/Link'
import { useAuth } from '../AuthProvider'
import LoginForm from './LoginForm'
import messages from './messages'

const LoginContainer = ({ intl: { formatMessage } }) => {
	const { login } = useAuth()
	const location = useLocation()
	const navigate = useNavigate()

	const handleOnSubmit = data =>
		login(data).then(() => {
			if (location.state && location.state.from) {
				navigate(location.state.from, { replace: true })
			} else {
				navigate('/', { replace: true })
			}
		})

	return (
		<SectionStack>
			<SectionStack.Element>
				<PageTitle>{formatMessage(messages.title)}</PageTitle>
			</SectionStack.Element>
			<SectionStack.Element>
				<LoginForm handleOnSubmit={handleOnSubmit} />
			</SectionStack.Element>
			<SectionStack.Element mx='auto'>
				<Link to='/password/forgot' textAlign='center'>
					{formatMessage(messages.forgotPassword)}
				</Link>
				<Link to='/signup' textAlign='center'>
					{formatMessage(messages.noAccount)}
				</Link>
			</SectionStack.Element>
		</SectionStack>
	)
}

LoginContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(LoginContainer)
