import { Stack, Image } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import { useLocation } from 'locations/LocationProvider'

import { SmallText } from '../ui/text'
import PageHeader from '../utils/layout/PageHeader'
import SectionStack from '../utils/layout/SectionStack'
import QrCodePDFButton from './QRCodePDFButton'
import QrCodeRevokeButton from './QRCodeRevokeButtonContainer'
import messages from './messages'

const QRCodeContainer = ({ intl: { formatMessage } }) => {
	const { location } = useLocation()
	const eTicketLocationURL = `${process.env.REACT_APP_QR_Q_PUBLIC_PATH}${location.signed_id}`

	const qrCodeGenerator = `${process.env.REACT_APP_QR_CODE_GENERATOR_PATH}?url_path=${eTicketLocationURL}`
	return (
		<SectionStack>
			<SectionStack.Element>
				<PageHeader title={formatMessage(messages.qrCodeTitle)} action={<QrCodePDFButton />} />
			</SectionStack.Element>
			<SectionStack.Element>
				<Stack align='center' spacing={3}>
					<Image src={qrCodeGenerator} height={300} width={300} alt='QR-Code' ignoreFallback={true} />
					<SmallText>{formatMessage(messages.revokeMessage)}</SmallText>
					<QrCodeRevokeButton />
				</Stack>
			</SectionStack.Element>
		</SectionStack>
	)
}

QRCodeContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}
export default injectIntl(QRCodeContainer)
