import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { Button } from '../../../buttons'

const FormSubmitButton = ({ formik: { isSubmitting }, children, ...props }) => (
	<Button type='submit' disabled={isSubmitting} {...props}>
		{children}
	</Button>
)

FormSubmitButton.displayName = 'FormSubmitButton'

FormSubmitButton.propTypes = {
	formik: PropTypes.shape({
		errors: PropTypes.shape({}),
		isSubmitting: PropTypes.bool
	}).isRequired
}
export default connect(FormSubmitButton)
export { FormSubmitButton }
