import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/core'
import CardContent from './CardContent'
import CardActions from './CardActions'
import CardState from './CardState'

const Card = ({ children, ...props }) => (
	<Box borderWidth='1px' rounded='md' {...props}>
		{children}
	</Box>
)

Card.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

Card.Content = CardContent
Card.Actions = CardActions
Card.State = CardState

Card.displayName = 'Card'

export default Card
