import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import _ from 'lodash'
import { Icon, MenuItem, Stack, Text } from '@chakra-ui/core'
import messages from '../messages'
import { useAuth } from '../../auth/AuthProvider'

const LogoutTopnavMenuItem = ({ intl: { formatMessage } }) => {
	const { logout } = useAuth()

	return (
		<MenuItem px={4} py={2} onClick={logout}>
			<Stack isInline spacing={4} align='center'>
				<Icon color='topnav.menu.text' name='onOff' />
				<Text color='topnav.menu.text'>{formatMessage(messages.logout)}</Text>
			</Stack>
		</MenuItem>
	)
}

const composedComponent = _.flowRight(injectIntl)

LogoutTopnavMenuItem.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default composedComponent(LogoutTopnavMenuItem)
export { LogoutTopnavMenuItem }
