const cssResetConfig = theme => ({
	light: {
		color: theme.colors.black,
		bg: undefined,
		borderColor: theme.colors.gray[200],
		placeholderColor: theme.colors.gray[400]
	}
})

export default cssResetConfig
