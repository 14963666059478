import React from 'react'
import PropTypes from 'prop-types'
import { Icon, ModalBody, ModalFooter, ModalHeader } from '@chakra-ui/core'
import Modal from './Modal'
import { Title } from '../../../ui/text'
import InlineStack from '../../../ui/stacks/InlineStack'
import MediumText from '../../../ui/text/MediumText'
import { Button } from '../../../buttons'

const ConfirmationModal = ({
	title,
	description,
	confirmationText,
	cancellationText,
	confirmationButtonProps,
	cancellationButtonProps,
	onClose,
	onConfirm,
	modalProps,
	...props
}) => (
	<Modal {...props} onClose={onClose}>
		<ModalHeader>
			<InlineStack>
				<Icon name='warning' color='brand.red' />
				<Title>{title}</Title>
			</InlineStack>
		</ModalHeader>
		<ModalBody>
			<MediumText>{description}</MediumText>
		</ModalBody>
		<ModalFooter>
			<InlineStack>
				<Button id='cancel-button' variant='link' onClick={onClose} {...cancellationButtonProps}>
					{cancellationText}
				</Button>
				<Button id='confirm-submit-button' onClick={onConfirm} {...confirmationButtonProps}>
					{confirmationText}
				</Button>
			</InlineStack>
		</ModalFooter>
	</Modal>
)

ConfirmationModal.defaultProps = {
	confirmationButtonProps: {},
	cancellationButtonProps: {},
	modalProps: {}
}

ConfirmationModal.propTypes = {
	description: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
	title: PropTypes.string.isRequired,
	confirmationText: PropTypes.node.isRequired,
	cancellationText: PropTypes.node.isRequired,
	confirmationButtonProps: PropTypes.shape({}),
	cancellationButtonProps: PropTypes.shape({}),
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	modalProps: PropTypes.shape({})
}

export default ConfirmationModal
