/* eslint-disable no-underscore-dangle */
import axios from 'axios'

class Axios {
	constructor(baseURL) {
		return axios.create({ baseURL })
	}
}

class QubeAxios extends Axios {
	constructor(baseURL) {
		super(baseURL)
		this._axiosWithLocation = new Axios(baseURL)
	}

	setLocation = id => {
		this._axiosWithLocation.defaults.baseURL = `${this.defaults.baseURL}locations/${id}`
	}

	getAxiosWithLocation = () => {
		return this._axiosWithLocation
	}

	withLocation = () => this._axiosWithLocation
}

export default QubeAxios
