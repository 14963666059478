import React from 'react'
import PropTypes from 'prop-types'
import { Button as ChakraButton } from '@chakra-ui/core'
import { MediumText } from '../ui/text'

const Button = ({ children, variantColor, size, icon, ...props }) => (
	<ChakraButton
		variantColor={variantColor}
		size={size}
		{...props}
		leftIcon={icon}
		textTransform='uppercase'
		rounded='lg'
	>
		<MediumText>{children}</MediumText>
	</ChakraButton>
)

Button.displayName = 'Button'

Button.defaultProps = {
	size: 'sm',
	variantColor: 'primary',
	icon: undefined
}

Button.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
	variantColor: PropTypes.string,
	size: PropTypes.string,
	icon: PropTypes.string
}

export default Button
