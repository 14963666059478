import { Box, Icon, MenuDivider, Stack, Text } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import { Button } from 'buttons'
import { useLocation } from 'locations/LocationProvider'
import useCreateSubscription from 'subscriptions/useCreateSubscription'
import { SmallText, Card } from 'ui'
import { interceptError } from 'utils/errors/errorInterceptors'

import subscriptionPlanMessages from '../messages'

const MostPopularLabel = ({ text, ...props }) => (
	<Box textAlign='center' textTransform='uppercase' {...props}>
		<SmallText>{text}</SmallText>
	</Box>
)

const SubscriptionPlanCard = ({
	subPlanGroup: { display_name: displayName, is_trial: isTrial },
	subPlan,
	...props
}) => {
	const { formatMessage } = useIntl()
	const { createSubscription } = useCreateSubscription()
	const { location } = useLocation()

	const { duration, max_tickets: maxTickets, price, id: subPlanId } = subPlan || {}

	const isMostPopular = displayName.toLowerCase() === 'premium' // TODO get this bool from API

	const features = [
		formatMessage(
			duration === 1 ? subscriptionPlanMessages.ticketsPerMonth : subscriptionPlanMessages.ticketsPerYear,
			{
				number: maxTickets
			}
		)
	]

	if (!isTrial) {
		features.push(formatMessage(subscriptionPlanMessages.choosePeriodicity))
	}

	const handleTrialOnSubmit = () => createSubscription(location.id, subPlanId).catch(interceptError)

	return (
		<Card
			position='relative'
			boxShadow={isTrial ? 'xl' : null}
			borderColor={isMostPopular ? 'primary.500' : null}
			backgroundColor='white'
			{...props}
		>
			{isMostPopular ? (
				<MostPopularLabel
					text={formatMessage(subscriptionPlanMessages.popular)}
					position='absolute'
					width='100%'
					backgroundColor='primary.500'
					color='white'
				/>
			) : null}
			<Card.Content my={8}>
				<Stack spacing={2}>
					<Stack textAlign='center' spacing={2}>
						<Text color='gray.800' fontWeight='bold'>
							{displayName}
						</Text>
						{subPlan ? (
							<Stack spacing={0}>
								<Text textTransform='uppercase' color='primary.500' fontWeight='bold' fontSize='3xl'>
									{isTrial ? formatMessage(subscriptionPlanMessages.free) : <>{price.toFixed(2)}€</>}
								</Text>
								<Text color='black.500'>
									{duration === 1
										? formatMessage(subscriptionPlanMessages.locationMonth)
										: formatMessage(subscriptionPlanMessages.locationYear)}
								</Text>
							</Stack>
						) : null}
					</Stack>
					{subPlan ? (
						<>
							<MenuDivider />
							<Stack spacing={2} m={2} pb={16}>
								{features.map(featureText => (
									<Stack key={featureText} align='top' direction='row'>
										<Icon name='check' color='primary.500' size='4' m={1} />
										<Text color='gray'>{featureText}</Text>
									</Stack>
								))}
							</Stack>
						</>
					) : null}
				</Stack>
				{subPlan ? (
					isTrial ? (
						<Button bg='primary.500' color='white' onClick={handleTrialOnSubmit} isFullWidth>
							{formatMessage(subscriptionPlanMessages.choose)}
						</Button>
					) : (
						<Button bg='primary.500' color='white' as={Link} to={`${subPlanId}`} isFullWidth>
							{formatMessage(subscriptionPlanMessages.choose)}
						</Button>
					)
				) : null}
			</Card.Content>
		</Card>
	)
}

SubscriptionPlanCard.propTypes = {
	subPlanGroup: PropTypes.shape({
		display_name: PropTypes.string.isRequired,
		is_trial: PropTypes.bool.isRequired
	}).isRequired,
	subPlan: PropTypes.shape({
		id: PropTypes.number.isRequired,
		duration: PropTypes.number.isRequired,
		max_tickets: PropTypes.number.isRequired,
		price: PropTypes.number.isRequired
	})
}

export default SubscriptionPlanCard
