const usePagination = ({ totalPages, activePage }) => {
	const minPage = 1
	const currentPage = Math.min(activePage, totalPages)

	const next = () => Math.min(currentPage + 1, totalPages)

	const hasNext = () => currentPage !== totalPages

	const prev = () => Math.max(currentPage - 1, minPage)

	const hasPrev = () => currentPage !== 1

	const range = (start, end) => {
		const length = end - start
		return Array.from({ length }, (_, i) => start + i)
	}

	const items = () => {
		const rangeStart = Math.max(minPage + 2, Math.min(totalPages - 4, currentPage - 1))
		return range(rangeStart, Math.min(rangeStart + 3, totalPages - 1))
	}

	return { next, prev, items, hasNext, hasPrev }
}

export default usePagination
