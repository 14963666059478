import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
	ModalBody,
	ModalHeader,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Stack,
	useDisclosure
} from '@chakra-ui/core'
import { useIntl } from 'react-intl'
import Button from '../../buttons/Button'
import { Modal } from '../../utils/modals/components'
import { Title } from '../../ui/text'
import buttonMessages from '../../buttons/messages'
import messages from '../messages'

const AddMorePeopleButton = ({ onChange, ...props }) => {
	const { formatMessage } = useIntl()
	const { isOpen, onOpen, onClose } = useDisclosure()
	const [number, setNumber] = useState(undefined)

	const onSubmit = e => {
		e.preventDefault()
		number > 0 && onChange(number)
		onClose()
	}

	return (
		<>
			<Button onClick={onOpen} {...props}>
				{formatMessage(messages.peopleNumberMore)}
			</Button>
			<Modal onClose={onClose} isOpen={isOpen}>
				<ModalHeader>
					<Title>{formatMessage(messages.numberOfPeople)}</Title>
				</ModalHeader>
				<ModalBody>
					<form onSubmit={onSubmit}>
						<Stack>
							<NumberInput id='select-n-number-of-people' size='md' value={number} onChange={setNumber}>
								<NumberInputField
									min={1}
									type='number'
									placeholder={formatMessage(messages.peopleNumberPlaceholder)}
								/>
								<NumberInputStepper>
									<NumberIncrementStepper />
									<NumberDecrementStepper />
								</NumberInputStepper>
							</NumberInput>
							<Button type='submit' ml='auto'>
								{formatMessage(buttonMessages.save)}
							</Button>
						</Stack>
					</form>
				</ModalBody>
			</Modal>
		</>
	)
}

AddMorePeopleButton.propTypes = {
	onChange: PropTypes.func.isRequired
}

export default AddMorePeopleButton
