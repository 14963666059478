import React from 'react'
import { useIntl } from 'react-intl'
import { usePaginatedQuery } from 'react-query'

import { useLocation } from 'locations/LocationProvider'
import { CYCLES_KEY } from '../entityKeys'
import { ListHeader } from 'ui'
import Link from 'ui/links/Link'
import CenteredLoading from 'ui/loadings/CenteredLoading'
import Pagination from 'utils/pagination/Pagination'

import api, { defaultPageSize } from '../../api/api'
import theme from '../../theme/theme'
import { interceptError } from '../../utils/errors/errorInterceptors'
import messages from '../messages'
import SubscriptionPlanName from 'subscription-plan-groups/components/SubscriptionPlanName'
import { useSearchParams } from 'react-router-dom'

const fetchCycles = (_, params) => api.cycles.list(params)

const CyclesListContainer = () => {
	const {
		location: { id: locationId }
	} = useLocation()

	const { formatMessage } = useIntl()
	const [searchParams] = useSearchParams()
	const activePage = Number(searchParams.get('activePage')) || 1

	const { status, resolvedData } = usePaginatedQuery([CYCLES_KEY, { locationId, page: activePage }], fetchCycles, {
		retry: false,
		onError: interceptError
	})

	const { count = 0, results = [] } = resolvedData?.data || {}
	const totalPages = Math.ceil(count / defaultPageSize)

	const styles = {
		backgroundColor: theme.colors.primary[500],
		textAlign: 'left',
		color: theme.colors.white
	}
	const columns = [
		formatMessage(messages.orderDate),
		formatMessage(messages.description),
		formatMessage(messages.startingDate),
		formatMessage(messages.endingDate),
		formatMessage(messages.price),
		formatMessage(messages.status),
		formatMessage(messages.details)
	]

	return (
		<>
			{count !== 0 && <ListHeader pagination={<Pagination activePage={activePage} totalPages={totalPages} />} />}
			{status === 'loading' && <CenteredLoading />}
			{status === 'success' && (
				<table cellPadding={5}>
					<tbody>
						<tr style={styles}>
							{columns.map(column => (
								<th key={column}>{column}</th>
							))}
						</tr>
						{results.map(cycle => {
							const { subscription_plan: subscriptionPlan } = cycle.subscription
							const { subscription_plan_group: subscriptionPlanGroup } = subscriptionPlan
							return (
								<tr key={cycle.id}>
									<td>{cycle.created_at.substring(0, 10)}</td>
									<td>
										<SubscriptionPlanName subscriptionPlan={subscriptionPlan} />
									</td>
									<td>{cycle.start_date}</td>
									<td>{cycle.valid_until}</td>
									<td>{subscriptionPlanGroup.is_trial ? 0 : subscriptionPlan.price.toFixed(2)}€</td>
									<td>
										{subscriptionPlanGroup.is_trial
											? formatMessage(messages.subscribed)
											: cycle.started_at === null
											? formatMessage(messages.waiting)
											: formatMessage(messages.paid)}
									</td>
									<td>
										<Link to={`${cycle.id}`}>{formatMessage(messages.viewDetails)}</Link>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			)}
		</>
	)
}

export default CyclesListContainer
