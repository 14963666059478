import { Text } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

export const SubSectionTitle = ({ title }) => (
	<Text color='sidenav.subnav.sectionTitle' p={4}>
		{title}
	</Text>
)

SubSectionTitle.propTypes = {
	title: PropTypes.string.isRequired
}
