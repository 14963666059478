import { Image } from '@chakra-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'

import { useLocation } from 'locations/LocationProvider'

const QRQueueLogoTopnavButton = () => {
	const { location } = useLocation()
	return (
		<Link to={location ? `/${location.id}` : '/'}>
			<Image src='/images/logo/QRQueueLogo.svg' alt='logo' ignoreFallback={true} />
		</Link>
	)
}

export default QRQueueLogoTopnavButton
