import { Divider } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { MENU_ENTRY_HORIZONTAL_MARGIN } from './MenuEntry'

export const SectionDivider = ({ isCollapsed }) => (
	<Divider
		borderWidth={1}
		mx={!isCollapsed && MENU_ENTRY_HORIZONTAL_MARGIN}
		my={4}
		borderColor='sidenav.divider'
		w='auto'
	/>
)

SectionDivider.defaultProps = {
	isCollapsed: false
}

SectionDivider.propTypes = {
	isCollapsed: PropTypes.bool
}
