import { defineMessages } from 'react-intl'

export const scope = 'qrq.cycles'

export default defineMessages({
	title: {
		id: `${scope}.title`
	},
	detailsTitle: {
		id: `${scope}.detailsTitle`
	},
	newPlan: {
		id: `${scope}.button.newPlan`
	},
	orderDate: {
		id: `${scope}.orderDate`
	},
	description: {
		id: `${scope}.description`
	},
	startingDate: {
		id: `${scope}.startingDate`
	},
	endingDate: {
		id: `${scope}.endingDate`
	},
	price: {
		id: `${scope}.price`
	},
	status: {
		id: `${scope}.status`
	},
	details: {
		id: `${scope}.details`
	},
	waiting: {
		id: `${scope}.waiting`
	},
	paid: {
		id: `${scope}.paid`
	},
	viewDetails: {
		id: `${scope}.viewDetails`
	},
	ticketsTitle: {
		id: `${scope}.ticketsTitle`
	},
	generatedTickets: {
		id: `${scope}.generatedTickets`
	},
	ticketsLeft: {
		id: `${scope}.ticketsLeft`
	},
	processingTitle: {
		id: `${scope}.processingTitle`
	},
	notPaidTitle: {
		id: `${scope}.notPaidTitle`
	},
	goToPayment: {
		id: `${scope}.goToPayment`
	},
	refresh: {
		id: `${scope}.refresh`
	},
	subscribed: {
		id: `${scope}.subscribed`
	},
	expired: {
		id: `${scope}.expired`
	},
	aboutToExpire: {
		id: `${scope}.aboutToExpire`
	},
	renewPlan: {
		id: `${scope}.renewPlan`
	},
	upgradePlan: {
		id: `${scope}.upgradePlan`
	}
})
