import * as Yup from 'yup'
import formsMessages from 'utils/forms/messages'
import messages from './messages'
import authMessages from '../messages'
import { passwordValidator, emailValidator } from '../validators'

const SignUpSchema = formatMessage =>
	Yup.object().shape({
		name: Yup.string()
			.label(formatMessage(authMessages.name))
			.required(({ label }) => formatMessage(formsMessages.required, { label }))
			.min(2, ({ label, min }) => formatMessage(formsMessages.stringMin, { label, min }))
			.max(100, ({ label, max }) => formatMessage(formsMessages.stringMax, { label, max })),
		user: Yup.object().shape({
			email: emailValidator(formatMessage),
			password1: passwordValidator(formatMessage)
		}),
		terms: Yup.boolean().oneOf([true], formatMessage(messages.checkRequired))
	})

export { SignUpSchema }
