import { Text } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import { MENU_ENTRY_HORIZONTAL_MARGIN } from './MenuEntry'

export const SectionTitle = ({ title, collapsedTitle, isCollapsed }) => (
	<Text
		whiteSpace='nowrap'
		color='sidenav.title'
		textAlign={isCollapsed && 'center'}
		py={4}
		mx={!isCollapsed && MENU_ENTRY_HORIZONTAL_MARGIN}
	>
		{isCollapsed ? collapsedTitle.toUpperCase() : title.toUpperCase()}
	</Text>
)

SectionTitle.defaultProps = {
	isCollapsed: false,
	collapsedTitle: undefined
}

SectionTitle.propTypes = {
	title: PropTypes.string.isRequired,
	collapsedTitle: PropTypes.string,
	isCollapsed: PropTypes.bool
}
