import { Text } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

import { LargeText } from '../../ui/text'
import TopnavButton from './TopnavButton'

const CycleLink = ({ subPlanName, cycleId, locationId }) => {
	const displayOnMobile = { base: 'inline-flex', md: 'none' }
	const displayOnLaptop = { base: 'none', md: 'inline-flex' }

	const cycleLink = `${locationId}/plans-billings/subscription-plans/${cycleId}`

	return (
		<>
			<TopnavButton as={Link} display={displayOnLaptop} leftIcon='ticket' to={cycleLink}>
				<LargeText isTruncated>{subPlanName}</LargeText>
			</TopnavButton>
			<TopnavButton as={Link} display={displayOnMobile} leftIcon='ticket' to={cycleLink}>
				<Text overflowX='hidden' textOverflow='ellipsis' whiteSpace='no-wrap'>
					{subPlanName}
				</Text>
			</TopnavButton>
		</>
	)
}

CycleLink.propTypes = {
	subPlanName: PropTypes.string.isRequired,
	cycleId: PropTypes.number.isRequired
}

export default CycleLink
