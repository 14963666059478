import React from 'react'
import PropTypes from 'prop-types'
import { Button as ChakraButton, Divider, Icon } from '@chakra-ui/core'
import { CALLED_TICKETS_KEY } from 'locations/entityKeys'
import { useMutation, queryCache } from 'react-query'
import { interceptError } from 'utils/errors/errorInterceptors'
import { useLocation } from 'locations/LocationProvider'
import { api } from '../../api'
import { MediumText } from '../../ui/text'

const TicketButtonContainer = ({ variant, variantColor, icon, ticket, ...props }) => {
	const {
		location: { id: locationId }
	} = useLocation()
	const countTicketQuery = () => api.tickets.count({ locationId, ticketId: ticket.id })
	const [countTicket, { status }] = useMutation(countTicketQuery, {
		onSuccess: () => queryCache.invalidateQueries([CALLED_TICKETS_KEY, { locationId }]),
		onError: interceptError
	})
	const textAndDividerColor = 'black'

	return (
		<ChakraButton
			p={2}
			onClick={countTicket}
			variant={variant}
			variantColor={variantColor}
			disabled={status === 'loading'}
			rounded='lg'
			{...props}
		>
			<Icon name={icon} />
			<MediumText color={textAndDividerColor} mx={1}>
				{ticket.formatted_number}
			</MediumText>
			<Divider borderColor={textAndDividerColor} orientation='vertical' h='100%' />
			<Icon name='person' />
			<MediumText color={textAndDividerColor} mx={1}>
				{ticket.number_of_people}
			</MediumText>
		</ChakraButton>
	)
}

TicketButtonContainer.defaultProps = {
	variant: undefined
}

TicketButtonContainer.propTypes = {
	variant: PropTypes.string,
	variantColor: PropTypes.string.isRequired,
	icon: PropTypes.string.isRequired,
	ticket: PropTypes.shape({
		id: PropTypes.number.isRequired,
		formatted_number: PropTypes.string.isRequired,
		number_of_people: PropTypes.number.isRequired
	}).isRequired
}

export default TicketButtonContainer
