import React from 'react'
import PropTypes from 'prop-types'
import { Box, Stack, IconButton } from '@chakra-ui/core'
import { useEffect, useState } from 'react'
import { CALLED_TICKETS_KEY } from 'locations/entityKeys'
import { useInfiniteQuery, queryCache } from 'react-query'
import { Button } from 'buttons'
import { injectIntl } from 'react-intl'
import { Title } from 'ui/text'
import { interceptError } from 'utils/errors/errorInterceptors'
import { useLocation } from 'locations/LocationProvider'
import messages from '../messages'
import { api } from '../../api'
import TicketButtonContainer from './TicketButtonContainer'

const TicketListContainer = ({ intl: { formatMessage }, ...props }) => {
	const {
		location: { id: locationId }
	} = useLocation()
	const [timeNow, setTimeNow] = useState(new Date())
	const TIME_DELTA_LIMIT = 15 * 60

	const { data: pagesData = [], isFetching, isFetchingMore, fetchMore, canFetchMore } = useInfiniteQuery(
		[CALLED_TICKETS_KEY, { locationId }],
		async (_key, { locationId }, page = 1) => {
			const { data } = await api.tickets.listCalled({ locationId, page })
			return data
		},
		{
			getFetchMore: lastPage => lastPage.next && new URLSearchParams(lastPage.next).get('page'),
			onError: interceptError
		}
	)

	useEffect(() => {
		const interval = setInterval(() => {
			setTimeNow(new Date())
		}, 1000)
		return () => clearInterval(interval)
	}, [timeNow])

	const listElements = pagesData
		.map(page =>
			page.results.map(ticket => {
				const timeDelta = (timeNow - new Date(ticket.called_at)) / 1000
				const isOldTicket = timeDelta > TIME_DELTA_LIMIT
				const ticketTypeIcon = ticket.generated_by_profile ? 'ticket' : 'qrCode'
				const ticketColor = ticket.generated_by_profile ? 'secondary' : 'primary'
				const variant = isOldTicket ? undefined : 'outline'
				const variantColor = isOldTicket ? 'gray' : ticketColor

				return (
					<Box key={ticket.id}>
						<TicketButtonContainer
							size='md'
							variant={variant}
							variantColor={variantColor}
							icon={ticketTypeIcon}
							ticket={ticket}
						/>
					</Box>
				)
			})
		)
		.flat()

	if (canFetchMore) {
		listElements.push(
			<Box>
				<Button size='md' onClick={() => fetchMore()} isLoading={isFetching || isFetchingMore}>
					{formatMessage(messages.loadMoreTickets)}
				</Button>
			</Box>
		)
	}

	return (
		<Box {...props}>
			<Title>{formatMessage(messages.ticketCheckin)}</Title>
			<Stack isInline spacing={3} my={2}>
				<IconButton
					aria-label={formatMessage(messages.refreshTicketsList)}
					icon='refresh'
					onClick={() => queryCache.invalidateQueries([CALLED_TICKETS_KEY, { locationId }])}
				/>
				<Stack isInline align='center' spacing={2} overflowX='auto'>
					{listElements.length > 0 ? listElements : <Box>{formatMessage(messages.noTicketsToCount)}</Box>}
				</Stack>
			</Stack>
		</Box>
	)
}

TicketListContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(TicketListContainer)
