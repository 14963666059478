import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

import { PageTitle } from 'ui/text'
import { SectionStack } from 'utils/layout'

import api from '../../api/api'
import Link from '../../ui/links/Link'
import { useToast } from '../../utils/toasts'
import SignUpForm from './SignUpForm'
import messages from './messages'

const SignUpContainer = ({ intl: { formatMessage } }) => {
	const { showSuccessToast } = useToast()
	const navigate = useNavigate()
	const handleOnSubmit = data => {
		const formData = {
			user: {
				email: data.user.email,
				username: data.user.email,
				password1: data.user.password1,
				password2: data.user.password1
			},
			name: data.name
		}

		return api.auth.signUp(formData).then(() => {
			navigate('/', { replace: true })
			showSuccessToast(formatMessage(messages.accountCreated))
		})
	}

	return (
		<SectionStack>
			<SectionStack.Element>
				<PageTitle>{formatMessage(messages.title)}</PageTitle>
			</SectionStack.Element>
			<SectionStack.Element>
				<SignUpForm handleOnSubmit={handleOnSubmit} />
			</SectionStack.Element>
			<SectionStack.Element mx='auto'>
				<Link to='/login'>{formatMessage(messages.alreadyHaveAccount)}</Link>
			</SectionStack.Element>
		</SectionStack>
	)
}

SignUpContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(SignUpContainer)
