import { Input } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import { QubeSectionForm, Field, FormLabel } from 'utils/forms'

import PasswordInput from '../../ui/inputs/PasswordInput'
import authMessages from '../messages'
import messages from './messages'
import { LoginSchema } from './schemas'

const LoginForm = ({ handleOnSubmit, intl: { formatMessage } }) => (
	<QubeSectionForm
		initialValues={{ email: '', password: '' }}
		validationSchema={LoginSchema(formatMessage)}
		onSubmit={handleOnSubmit}
		submitMessage={messages.login}
	>
		<Field
			name='email'
			label={<FormLabel htmlFor='login-input-email'>{formatMessage(authMessages.email)}</FormLabel>}
		>
			{({ field }) => <Input id='login-input-email' type='email' {...field} />}
		</Field>
		<Field
			name='password'
			label={<FormLabel htmlFor='login-input-email'>{formatMessage(authMessages.password)}</FormLabel>}
		>
			{({ field }) => <PasswordInput id='login-input-password' type='email' {...field} />}
		</Field>
	</QubeSectionForm>
)

LoginForm.propTypes = {
	handleOnSubmit: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(LoginForm)
export { LoginForm }
