import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/core'

const CardContent = ({ children, ...props }) => (
	<Box p={2}>
		<Box m={2} {...props}>
			{children}
		</Box>
	</Box>
)

CardContent.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

CardContent.displayName = 'CardContent'

export default CardContent
