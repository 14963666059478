import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { useLocation } from 'locations/LocationProvider'
import Container from 'utils/layout/Container'
import { SectionCard } from '../ui/sections'
import messages from './messages'
import LocationForm from '../locations/create/LocationForm'
import { useToast } from '../utils/toasts'
import { SectionStack, PageHeader } from '../utils/layout'

const SettingsContainer = ({ intl: { formatMessage } }) => {
	const {
		location,
		editLocation: [onEditLocation]
	} = useLocation()

	const { showSuccessToast } = useToast()

	const handleEditLocation = data =>
		onEditLocation(
			{ id: location.id, data },
			{
				throwOnError: true,
				onSuccess: () => showSuccessToast(formatMessage(messages.locationUpdateSuccessMessage))
			}
		)

	return (
		<SectionStack>
			<SectionStack.Element>
				<PageHeader title={formatMessage(messages.settingsTitle)} />
			</SectionStack.Element>
			<SectionStack.Element as={Container}>
				<SectionCard title={formatMessage(messages.locationDetails)}>
					<LocationForm location={location} handleOnSubmit={handleEditLocation} />
				</SectionCard>
			</SectionStack.Element>
		</SectionStack>
	)
}

SettingsContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(SettingsContainer)
