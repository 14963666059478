import { useIntl } from 'react-intl'
import { queryCache, useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import { api } from 'api'
import { CURRENT_CYCLE_KEY } from 'locations/entityKeys'
import { useToast } from 'utils/toasts'

import messages from './messages'

const useCreateSubscription = () => {
	const { showSuccessToast } = useToast()
	const navigate = useNavigate()
	const { formatMessage } = useIntl()

	const [mutate, { status }] = useMutation(api.subscriptions.create, {
		onSuccess: ({ data }) => {
			queryCache.invalidateQueries(CURRENT_CYCLE_KEY)
			showSuccessToast(formatMessage(messages.createdSuccessMessage))
			navigate(`../${data.id}`, { replace: true })
		}
	})

	const today = new Date()
	today.setUTCHours(0, 0, 0, 0)

	const createSubscription = (locationId, subscriptionPlanId, startDatetime = today) =>
		mutate(
			{ locationId, data: { start_datetime: startDatetime, subscription_plan: subscriptionPlanId } },
			{ throwOnError: true }
		)

	return { createSubscription, status }
}

export default useCreateSubscription
