import { Stack, Icon, useTheme, Flex } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useSearchParams } from 'react-router-dom'

import PaginationButton, { paginationButtonProps } from './PaginationButton'
import { createSearchString } from './urlQueryParams'
import usePagination from './usePagination'

const Pagination = ({ activePage, totalPages }) => {
	const theme = useTheme()
	const [searchParams] = useSearchParams()

	const createLocationObject = item => ({
		search: createSearchString({ ...Object.fromEntries(searchParams.entries()), activePage: item })
	})

	const { items, hasNext, hasPrev, prev, next } = usePagination({ activePage, totalPages })
	const activeStyleProps = isActive => ({
		borderBottom: isActive ? `1px solid ${theme.colors.primary['500']}` : `1px solid ${theme.colors.brand.gray}`,
		color: isActive ? 'primary.500' : undefined
	})

	const showFlex = show => (show ? 'flex' : 'none')
	const hideButton = hide => (hide ? 'none' : 'inline-flex')

	return (
		totalPages > 1 && (
			<Stack isInline spacing={0}>
				<PaginationButton as={Link} to={createLocationObject(prev())} isDisabled={!hasPrev()}>
					<Icon name='selectLeft' color='primary.500' />
				</PaginationButton>
				<PaginationButton {...activeStyleProps(activePage === 1)} as={Link} to={createLocationObject(1)}>
					{1}
				</PaginationButton>
				{totalPages > 2 && (
					<PaginationButton
						display={{
							base: hideButton(activePage - 1 > 2),
							md: hideButton(activePage - 2 > 2 && totalPages > 7)
						}}
						{...activeStyleProps(activePage === 2)}
						as={Link}
						to={createLocationObject(2)}
					>
						{2}
					</PaginationButton>
				)}
				<Flex
					display={{
						base: showFlex(activePage - 1 > 2),
						md: showFlex(activePage - 2 > 2 && totalPages > 7)
					}}
					{...paginationButtonProps}
					borderBottom={`1px solid ${theme.colors.brand.gray}`}
					align='center'
					justify='center'
				>
					...
				</Flex>
				{items().map(page => (
					<PaginationButton
						{...activeStyleProps(page === activePage)}
						key={page}
						display={
							page !== Math.max(Math.min(totalPages - 2, activePage), 3)
								? { base: 'none', md: 'inline-flex' }
								: 'inline-flex'
						}
						as={Link}
						to={createLocationObject(page)}
					>
						{page}
					</PaginationButton>
				))}
				{totalPages > 3 && (
					<PaginationButton
						{...activeStyleProps(totalPages - 1 === activePage)}
						display={{
							base: hideButton(totalPages - activePage > 2),
							md: hideButton(totalPages - activePage - 1 > 2 && totalPages > 7)
						}}
						as={Link}
						to={createLocationObject(totalPages - 1)}
					>
						{totalPages - 1}
					</PaginationButton>
				)}
				<Flex
					display={{
						base: showFlex(totalPages - activePage > 2),
						md: showFlex(totalPages - activePage - 1 > 2 && totalPages > 7)
					}}
					{...paginationButtonProps}
					borderBottom={`1px solid ${theme.colors.brand.gray}`}
					align='center'
					justify='center'
				>
					...
				</Flex>
				{totalPages > 1 && (
					<PaginationButton
						{...activeStyleProps(totalPages === activePage)}
						as={Link}
						to={createLocationObject(totalPages)}
					>
						{totalPages}
					</PaginationButton>
				)}
				<PaginationButton as={Link} to={createLocationObject(next())} isDisabled={!hasNext()}>
					<Icon name='selectRight' color='primary.500' />
				</PaginationButton>
			</Stack>
		)
	)
}

Pagination.propTypes = {
	activePage: PropTypes.number.isRequired,
	totalPages: PropTypes.number.isRequired
}

export default Pagination
export { Pagination }
