import { PseudoBox, Text } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

const SubMenuEntry = ({ title, onClick, isActive, as, to }) => {
	const colors = isActive
		? { color: 'sidenav.subnav.highlightText', bg: 'sidenav.subnav.highlightBg' }
		: { color: 'sidenav.subnav.text', bg: 'sidenav.subnav.bg' }

	return (
		<PseudoBox
			rounded={4}
			onClick={onClick}
			d='flex'
			alignItems='center'
			px={1}
			py={3}
			cursor='pointer'
			_hover={!isActive && { bg: 'sidenav.subnav.hoverBg', color: 'sidenav.subnav.hoverText' }}
			as={as}
			to={to}
			{...colors}
		>
			<Text fontSize='sm' mx={4}>
				{title}
			</Text>
		</PseudoBox>
	)
}

SubMenuEntry.defaultProps = {
	onClick: undefined,
	title: '',
	isActive: false,
	as: undefined,
	to: undefined
}

SubMenuEntry.propTypes = {
	title: PropTypes.string,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
	as: PropTypes.elementType,
	to: PropTypes.string
}

export default SubMenuEntry
