import React from 'react'
import { useIntl } from 'react-intl'
import { Route, Routes } from 'react-router-dom'

import LocationApp from 'locations/LocationApp'
import LocationProvider from 'locations/LocationProvider'
import LocationCreateContainer from 'locations/create/LocationCreateContainer'
import LocationsListContainer from 'locations/list/LocationsListContainer'
import locationMessages from 'locations/list/messages'
import { DrawerProvider } from 'sidenav/DrawerProvider'
import ToppedNavFrame from 'topnav/ToppedNavFrame'
import { ConfirmProvider } from 'utils/confirm/ConfirmProvider'
import { PageHeader, SectionStack } from 'utils/layout'

import AdminContentFrame from './AdminContentFrame'

const AdminApp = () => {
	const { formatMessage } = useIntl()

	return (
		<DrawerProvider>
			<LocationProvider>
				<ConfirmProvider>
					<ToppedNavFrame>
						<Routes>
							<Route
								exact
								path=''
								element={
									<AdminContentFrame>
										<SectionStack>
											<SectionStack.Element>
												<PageHeader
													title={formatMessage(locationMessages.title)}
													action={<LocationCreateContainer />}
												/>
											</SectionStack.Element>
											<SectionStack.Element>
												<LocationsListContainer />
											</SectionStack.Element>
										</SectionStack>
									</AdminContentFrame>
								}
							/>
							<Route path=':locationId/*' element={<LocationApp />} />
						</Routes>
					</ToppedNavFrame>
				</ConfirmProvider>
			</LocationProvider>
		</DrawerProvider>
	)
}

export default AdminApp
