import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@chakra-ui/core'

const InlineStack = ({ children, ...props }) => {
	return (
		<Stack align='center' {...props} isInline spacing={2}>
			{children}
		</Stack>
	)
}

InlineStack.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

export default InlineStack
