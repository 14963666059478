import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@chakra-ui/core'
import { injectIntl } from 'react-intl'
import { queryCache } from 'react-query'

import { useLocation } from 'locations/LocationProvider'
import messages from '../messages'
import { api } from '../../api'
import { interceptError } from '../../utils/errors/errorInterceptors'
import { useConfirm } from '../../utils/confirm/ConfirmProvider'
import { CALLED_TICKETS_KEY } from '../../locations/entityKeys'

const ResetQMSystemButtonContainer = ({ onReset, intl: { formatMessage } }) => {
	const {
		location: { id: locationId },
		ticketsWaiting
	} = useLocation()
	const confirm = useConfirm()
	const [isLoading, setLoading] = useState(false)

	const resetQMSystem = () => {
		setLoading(true)
		return api.qm
			.resetQMSystem(locationId)
			.then(() => {
				onReset()
				queryCache.invalidateQueries([CALLED_TICKETS_KEY, { locationId }])
			})
			.catch(interceptError)
			.finally(() => setLoading(false))
	}

	return (
		<Button
			isDisabled={isLoading}
			size='sm'
			onClick={() =>
				confirm({
					title: formatMessage(messages.confirmResetTitle),
					description: `${formatMessage(messages.confirmResetDescriptionTicketsWaiting, {
						ticketsWaiting
					})} ${formatMessage(messages.confirmResetDescriptionPeopleInside)}`
				}).then(resetQMSystem)
			}
		>
			{formatMessage(messages.reset)}
		</Button>
	)
}

ResetQMSystemButtonContainer.propTypes = {
	onReset: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(ResetQMSystemButtonContainer)
