import React from 'react'
import PropTypes from 'prop-types'
import { ModalBody, ModalHeader, ModalFooter, Icon } from '@chakra-ui/core'
import { InlineStack } from '../../../ui/stacks'
import { Title, MediumText } from '../../../ui/text'
import { Button } from '../../../buttons'
import Modal from './Modal'

const ErrorModal = ({ onClose, title, errors, onConfirm, confirmMessage }) => {
	return (
		<Modal isOpen={true} onClose={onClose} closeOnOverlayClick={false}>
			<ModalHeader>
				<InlineStack>
					<Icon name='warning' color='brand.red' />
					<Title>{title}</Title>
				</InlineStack>
			</ModalHeader>
			<ModalBody>
				{errors.map((error, index) => (
					<MediumText key={index}>{error}</MediumText>
				))}
			</ModalBody>
			<ModalFooter>
				<Button onClick={onConfirm}>{confirmMessage}</Button>
			</ModalFooter>
		</Modal>
	)
}

ErrorModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	title: PropTypes.node.isRequired,
	errors: PropTypes.arrayOf(PropTypes.string).isRequired,
	onConfirm: PropTypes.func.isRequired,
	confirmMessage: PropTypes.node.isRequired
}

export default ErrorModal
