import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import ToastsDisplayer from 'utils/toasts/ToastsDisplayer'
import Footer from 'footer/Footer'
import { Box } from '@chakra-ui/core'

const AdminContentFrame = ({ children }) => {
	return (
		<Box px={{ base: 4, md: 10, lg: 12 }} py={{ base: 6, lg: 8 }}>
			<ToastsDisplayer />
			{children}
			<Footer />
		</Box>
	)
}

AdminContentFrame.propTypes = {
	children: PropTypes.node.isRequired
}

export default injectIntl(AdminContentFrame)
