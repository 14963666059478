import React from 'react'
import PropTypes from 'prop-types'
import { ListItem as ChakraListItem, PseudoBox, Flex } from '@chakra-ui/core'

import ListItemContent from './ListItemContent'
import ListItemActions from './ListItemActions'

const ListItem = ({ children, ...props }) => (
	<PseudoBox as={ChakraListItem} _odd={{ bg: 'gray.100' }} {...props}>
		<PseudoBox py={2} px={4} _hover={{ bg: 'twitter.50', boxShadow: 'md' }}>
			<Flex alignItems='center' w='100%'>
				{children}
			</Flex>
		</PseudoBox>
	</PseudoBox>
)

ListItem.Content = ListItemContent
ListItem.Actions = ListItemActions

ListItem.propTypes = {
	children: PropTypes.node.isRequired
}

export default ListItem
