import React, { useEffect } from 'react'
import { getTopic, MQTTService, topics } from '../../mqtt'
import PeopleCounter from '../components/PeopleCounter'
import { useLocation } from 'locations/LocationProvider'

const PeopleCounterContainer = ({ ...props }) => {
	const {
		location,
		peopleInside,
		incrementPeople: [onIncrementPeople, { status: incrementStatus }],
		decrementPeople: [onDecrementPeople, { status: decrementStatus }]
	} = useLocation()

	useEffect(() => {
		const peopleInsideTopic = getTopic(topics.qm.changedPeopleInside, { locationId: location.id })
		MQTTService.getClient().subscribe(peopleInsideTopic)

		const peopleWaitingTopic = getTopic(topics.qm.changedPeopleWaiting, { locationId: location.id })
		MQTTService.getClient().subscribe(peopleWaitingTopic)

		return () => {
			MQTTService.getClient().unsubscribe(peopleInsideTopic)
			MQTTService.getClient().unsubscribe(peopleWaitingTopic)
		}
	}, [location])

	const incrementPeopleAction = () => onIncrementPeople(location.id)

	const decrementPeopleAction = () => onDecrementPeople(location.id)

	return (
		<PeopleCounter
			isLoading={incrementStatus === 'loading' || decrementStatus === 'loading'}
			peopleInside={peopleInside}
			warning={peopleInside > location.people_inside_limit}
			onIncrement={incrementPeopleAction}
			onDecrement={decrementPeopleAction}
			{...props}
		/>
	)
}

export default PeopleCounterContainer
