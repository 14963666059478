import { Icon, PseudoBox, Text } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

export const MENU_ENTRY_HORIZONTAL_MARGIN = 4
export const MENU_ENTRY_ICON_SIZE = 5

const MenuEntry = ({ icon, title, isCollapsed, isActive, as, to, href, onClick }) => {
	const colors = isActive
		? { color: 'sidenav.highlightText', bg: 'sidenav.highlightBg' }
		: { color: 'sidenav.text', bg: 'sidenav.bg' }

	return (
		<PseudoBox
			role='group'
			d='flex'
			alignItems='center'
			py={4}
			cursor='pointer'
			_hover={!isActive && { bg: 'sidenav.hoverBg', color: 'sidenav.hoverText' }}
			as={as}
			to={to}
			href={href}
			onClick={onClick}
			{...colors}
		>
			<Icon mx={MENU_ENTRY_HORIZONTAL_MARGIN} boxSize={MENU_ENTRY_ICON_SIZE} name={icon} />
			<Text
				whiteSpace='nowrap'
				fontSize='sm'
				mx={MENU_ENTRY_HORIZONTAL_MARGIN}
				visibility={isCollapsed && 'hidden'}
			>
				{title}
			</Text>
		</PseudoBox>
	)
}

MenuEntry.defaultProps = {
	icon: undefined,
	title: '',
	onClick: undefined,
	isActive: false,
	isCollapsed: false,
	as: undefined,
	to: undefined,
	href: undefined
}

MenuEntry.propTypes = {
	icon: PropTypes.elementType,
	title: PropTypes.string,
	isActive: PropTypes.bool,
	onClick: PropTypes.func,
	isCollapsed: PropTypes.bool,
	as: PropTypes.elementType,
	to: PropTypes.string,
	href: PropTypes.string
}

export default MenuEntry
