import { defineMessages } from 'react-intl'

export const scope = 'qrq.auth.login'

export default defineMessages({
	forgotPassword: {
		id: `${scope}.link.forgotPassword`
	},
	login: {
		id: `${scope}.button.login`
	},
	noAccount: {
		id: `${scope}.button.noAccount`
	},
	title: {
		id: `${scope}.title`
	}
})
