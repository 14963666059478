import { Link } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import { useLocation } from 'locations/LocationProvider'

import Button from '../buttons/Button'
import messages from './messages'

const QRCodePDFButton = ({ intl: { formatMessage, locale } }) => {
	const { location } = useLocation()
	const eTicketLocationURL = `${process.env.REACT_APP_QR_Q_PUBLIC_PATH}${location.signed_id}`

	const pdfHref = `${process.env.REACT_APP_PDF_GENERATOR_PATH}?name=${location.name}&url_path=${eTicketLocationURL}&lang=${locale}`

	return (
		<Button as={Link} icon='download' target='_blank' href={pdfHref}>
			{formatMessage(messages.pdf)}
		</Button>
	)
}

QRCodePDFButton.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired,
		locale: PropTypes.string.isRequired
	}).isRequired
}

export default injectIntl(QRCodePDFButton)
