import { Box, Text } from '@chakra-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { MiniText } from '../../ui/text'
import { InlineStack } from '../../ui/stacks'

const TicketDisplayer = ({ formattedNumber, relativeTime, description, icon, ...props }) => (
	<Box {...props}>
		<InlineStack align='baseline'>
			<Text fontSize='45px' isTruncated lineHeight='40px'>
				{formattedNumber}
			</Text>
			{icon}
		</InlineStack>
		<MiniText isTruncated color='gray.500'>
			{description}
		</MiniText>
		<MiniText isTruncated color='gray.500' fontWeight='bold'>
			{relativeTime}
		</MiniText>
	</Box>
)

TicketDisplayer.defaultProps = {
	icon: undefined
}

TicketDisplayer.propTypes = {
	formattedNumber: PropTypes.string.isRequired,
	relativeTime: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
	icon: PropTypes.node
}

export default TicketDisplayer
