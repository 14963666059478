import PropTypes from 'prop-types'
import React from 'react'
import { Navigate, useLocation, Outlet } from 'react-router-dom'

import { useAuth } from '../../auth/AuthProvider'

const AuthenticatedRoute = ({ redirectPath, authCriteria }) => {
	const { token, user } = useAuth()
	const isAuthenticated = !!token && !!user
	const { pathname } = useLocation()

	return isAuthenticated === authCriteria ? (
		<Outlet />
	) : (
		<Navigate
			to={{
				pathname: redirectPath
			}}
			state={{ from: pathname }}
		/>
	)
}

AuthenticatedRoute.defaultProps = {
	authCriteria: false
}

AuthenticatedRoute.propTypes = {
	redirectPath: PropTypes.string.isRequired,
	authCriteria: PropTypes.bool
}

export default AuthenticatedRoute
