import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'formik'
import { FeedbackIcon } from '../../../ui/icons'

const FormFeedbackIcon = ({ formik: { status, isSubmitting, dirty }, ...props }) => {
	let feedbackStatus
	if (status) {
		feedbackStatus = status.success && !dirty ? 'success' : feedbackStatus
		feedbackStatus = status.error ? 'error' : feedbackStatus
	}
	feedbackStatus = isSubmitting ? 'loading' : feedbackStatus
	return <FeedbackIcon status={feedbackStatus} {...props} />
}

FormFeedbackIcon.displayName = 'FormFeedbackIcon'

FormFeedbackIcon.propTypes = {
	formik: PropTypes.shape({
		status: PropTypes.shape({
			success: PropTypes.bool,
			error: PropTypes.bool,
			dirty: PropTypes.bool
		}),
		isSubmitting: PropTypes.bool
	}).isRequired
}

export default connect(FormFeedbackIcon)

export { FormFeedbackIcon }
