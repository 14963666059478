import PropTypes from 'prop-types'
import React from 'react'
import { useIntl } from 'react-intl'
import { matchPath, Link, useLocation } from 'react-router-dom'

import cyclesMessages from '../cycles/messages'
import plansAndBillingMessages from '../plansAndBilling/messages'
import qrCodeMessages from '../qrcode/messages'
import qmMessages from '../queueManagement/messages'
import settingsMessages from '../settings/messages'
import Sidenav from './Sidenav'

const SidenavMenu = ({ prefix }) => {
	const { pathname } = useLocation()
	const { formatMessage } = useIntl()
	return (
		<Sidenav location={pathname} LinkComponent={Link} matchPath={matchPath}>
			<Sidenav.MenuEntry
				path={`${prefix}/qm`}
				title={formatMessage(qmMessages.queueManagementTitle)}
				icon='dashboard'
			/>
			<Sidenav.MenuEntry
				path={`${prefix}/qrcode`}
				title={formatMessage(qrCodeMessages.qrCodeTitle)}
				icon='qrCode'
			/>
			<Sidenav.MenuEntry
				path={`${prefix}/settings`}
				title={formatMessage(settingsMessages.settingsTitle)}
				icon='gear'
			/>
			<Sidenav.MenuEntry
				path={`${prefix}/plans-billings`}
				matchPath={`${prefix}/plans-billings/*`}
				title={formatMessage(plansAndBillingMessages.title)}
				icon='creditCard'
			>
				<Sidenav.SubMenuEntry
					path={`${prefix}/plans-billings/subscription-plans`}
					matchPath={`${prefix}/plans-billings/subscription-plans/*`}
					title={formatMessage(cyclesMessages.title)}
				/>
			</Sidenav.MenuEntry>
		</Sidenav>
	)
}

SidenavMenu.propTypes = {
	prefix: PropTypes.string.isRequired
}

export { SidenavMenu }

export default SidenavMenu
