import { defineMessages } from 'react-intl'

export const scope = 'qrq.forms'

export default defineMessages({
	highlightedFieldsError: {
		id: `${scope}.highlightedFieldError`,
		defaultMessage: 'Submission failed! Please check and correct the highlighted field.'
	},
	required: {
		id: `${scope}.validations.required`
	},
	stringMin: {
		id: `${scope}.validations.stringMin`
	},
	stringMax: {
		id: `${scope}.validations.stringMax`
	},
	invalidEmail: {
		id: `${scope}.validations.email`
	}
})
