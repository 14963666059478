import React, { useState, useRef, useEffect } from 'react'
import { useTransition, animated } from 'react-spring'
import SuccessToast from './SuccessToast'

const duration = 5000

export const Toast = ({ message, onRequestRemove, requestClose = false, type }) => {
	const container = useRef(null)
	const [localShow, setLocalShow] = useState(true)

	const close = () => {
		setLocalShow(false)
	}

	useEffect(() => {
		const id = setTimeout(close, duration)
		return () => clearTimeout(id)
	}, [])

	const onRest = () => {
		if (!localShow) {
			onRequestRemove()
		}
	}

	const animation = {
		config: { mass: 1, tension: 185, friction: 26 },
		from: {
			opacity: 1,
			height: 0,
			width: '100%',
			transform: `translateY(-100%) scale(1)`
		},
		enter: () => next =>
			next({
				opacity: 1,
				width: '100%',
				height: container.current ? container.current.getBoundingClientRect().height : 0,
				transform: `translateY(0) scale(1)`
			}),
		leave: {
			opacity: 0,
			height: 0,
			width: '100%',
			transform: `translateY(0) scale(0.9)`
		},
		onRest
	}

	const transition = useTransition(localShow, null, animation)
	const style = {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center'
	}

	useEffect(() => {
		if (requestClose) {
			setLocalShow(false)
		}
	}, [requestClose])

	return transition.map(
		({ key, item, props }) =>
			item && (
				<animated.div
					key={key}
					style={{
						opacity: props.opacity,
						height: props.height,
						...style
					}}
				>
					<animated.div
						style={{
							transform: props.transform,
							width: '100%',
							pointerEvents: 'auto'
						}}
						ref={container}
					>
						{type === 'success' && <SuccessToast onClose={close} message={message} />}
					</animated.div>
				</animated.div>
			)
	)
}

export default Toast
