import { defineMessages } from 'react-intl'

export const scope = 'qrq.errors'

export default defineMessages({
	cantConnectServer: {
		id: `${scope}.cantConnectServer`
	},
	serverError: {
		id: `${scope}.serverError`
	},
	mqttErrorMessage: {
		id: `${scope}.mqttErrorMessage`
	},
	errorDefaultMessage: {
		id: `${scope}.errorDefaultMessage`
	}
})
