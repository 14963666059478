export default {
	queueManagementError: 'queue_management_error',
	invalid: 'invalid',
	error: 'error',
	parseError: 'parse_error',
	authenticatedFailed: 'authenticated_failed',
	notAuthenticated: 'not_authenticated',
	permissionFailed: 'permission_failed',
	nofFound: 'not_found',
	methodNotAllowed: 'method_not_allowed',
	notAcceptable: 'not_acceptable',
	unsupportedMediaType: 'unsupported_media_type',
	throttled: 'throttled'
}
