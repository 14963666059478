import { compile, pathToRegexp } from 'path-to-regexp'

export const topics = {
	qm: {
		changedPeopleWaiting: 'locations/:locationId/tickets/changed-waiting-number',
		changedPeopleInside: 'locations/:locationId/people-inside/changed'
	}
}

export const getTopic = (topic, params) => {
	const toPath = compile(topic, { encode: encodeURIComponent })
	return decodeURIComponent(toPath(params))
}

export const getTopicParams = (topic, messageTopic) => pathToRegexp(topic).exec(messageTopic)

export const matchTopic = (topic, messageTopic) => !!getTopicParams(topic, messageTopic)
