import React, { useCallback, useContext, useState } from 'react'
import { queryCache } from 'react-query'
import PropTypes from 'prop-types'
import { api } from 'api'

const AuthContext = React.createContext({})

export const useAuth = () => useContext(AuthContext)

const AuthProvider = ({ children }) => {
	const [user, setUser] = useState(undefined)
	const [token, setToken] = useState(localStorage.getItem('token'))
	const [isLoading, setLoading] = useState(true)

	const clearToken = useCallback(() => {
		localStorage.removeItem('token')
		setToken(null)
	}, [])

	const login = useCallback(
		credentials => {
			clearToken()
			return api.auth.login(credentials).then(({ data }) => {
				localStorage.setItem('token', data.token)
				setToken(data.token)
				setUser(data.user)
			})
		},
		[clearToken]
	)

	const logout = useCallback(() => {
		setUser(null)
		clearToken()
		setLoading(false)
		queryCache.clear()
	}, [clearToken])

	const getCurrentUser = useCallback(() => {
		setLoading(true)
		return api.users
			.current()
			.then(({ data }) => {
				setUser(data)
			})
			.catch(logout)
			.finally(() => setLoading(false))
	}, [logout])

	return (
		<AuthContext.Provider value={{ getCurrentUser, login, token, isLoading, user, logout }}>
			{children}
		</AuthContext.Provider>
	)
}

AuthProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

export default AuthProvider
