import { defineMessages } from 'react-intl'

export const scope = 'qrq.subscriptions'

export default defineMessages({
	createTitle: {
		id: `${scope}.createTitle`
	},
	subscriptionPlan: {
		id: `${scope}.subscriptionPlan`
	},
	subPlanDetails: {
		id: `${scope}.subPlanDetails`
	},
	detailsTickets: {
		id: `${scope}.details.tickets`
	},
	detailsDuration: {
		id: `${scope}.details.duration`
	},
	detailsPrice: {
		id: `${scope}.details.price`
	},
	startDatetime: {
		id: `${scope}.label.startDatetime`
	},
	dateFuture: {
		id: `${scope}.validations.dateFuture`
	},
	createdSuccessMessage: {
		id: `${scope}.createdSuccessMessage`
	}
})
