import { Input } from '@chakra-ui/core'
import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'

import { SubscriptionCreateSchema } from '../schema'
import { QubeSectionForm, Field, FormLabel } from '../../utils/forms'
import messages from '../messages'
import buttonMessages from '../../buttons/messages'

const SubscriptionForm = ({ handleOnSubmit, intl: { formatMessage } }) => (
	<QubeSectionForm
		initialValues={{ start_datetime: '' }}
		validationSchema={SubscriptionCreateSchema(formatMessage)}
		onSubmit={({ start_datetime, ...rest }) =>
			handleOnSubmit({ start_datetime: new Date(start_datetime).toISOString(), ...rest })
		}
		submitMessage={buttonMessages.create}
	>
		<Field
			name='start_datetime'
			label={
				<FormLabel htmlFor='create-subscription-input-start-datetime'>
					{formatMessage(messages.startDatetime)}
				</FormLabel>
			}
		>
			{({ field }) => <Input id='create-subscription-input-start-datetime' type='date' {...field} />}
		</Field>
	</QubeSectionForm>
)

SubscriptionForm.propTypes = {
	handleOnSubmit: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(SubscriptionForm)
