import { Box, Image, Stack } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { MiniText } from '../ui/text'
import messages from './messages'

const Footer = ({ intl: { formatMessage } }) => (
	<Stack as='footer' spacing={3} align='center' p={5} my={6} textAlign='center'>
		<Box as='a' href={process.env.REACT_APP_QBETTER_SITE_PATH} target='_blank' rel='noopener'>
			<Image src='/images/logo/Q-better_logo.svg' alt='Q-Better' ignoreFallback={true} />
		</Box>
		<MiniText color='gray.500' textDecoration='underline'>
			<a
				href={`mailto:${process.env.REACT_APP_FEEDBACK_EMAIL_ADDRESS}?subject=${process.env.REACT_APP_FEEDBACK_EMAIL_SUBJECT}`}
				target='_blank'
				rel='noopener noreferrer'
			>
				{formatMessage(messages.feedback)}
			</a>
		</MiniText>
	</Stack>
)

Footer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(Footer)
