import React from 'react'
import { FormattedMessage } from 'react-intl'
import messages from './messages'
import ErrorModal from './components/ErrorModal'

export class ModalManager {
	constructor() {
		this._ref = null
	}

	setModalRef = ref => {
		this._ref = ref
	}

	showModal = (title, errors) => {
		this._ref.showModal(
			<ErrorModal
				onClose={this._ref.hideModal}
				title={title}
				errors={errors}
				onConfirm={this._ref.hideModal}
				confirmMessage={<FormattedMessage {...messages.ok} />}
			/>
		)
	}
}
