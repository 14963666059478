import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, FormErrorMessage } from '@chakra-ui/core'
import { useField, useFormikContext } from 'formik'

import { hasError } from './error_helper'

function useFormField({ name, parentName }) {
	const computedName = parentName ? `${parentName}.${name}` : name
	const field = useField({ name: computedName })

	const { errors, touched, ...formikProps } = useFormikContext()
	const computedErrors = parentName ? errors[parentName] : errors
	const computedTouched = parentName ? touched[parentName] : touched

	return [field, { errors: computedErrors, touched: computedTouched, ...formikProps }]
}

const Field = ({ name, parentName, label, children, ...props }) => {
	const [[field], { errors, touched, ...formikProps }] = useFormField({ name, parentName })

	return (
		<FormControl isInvalid={!!hasError(touched, errors, name)} {...props}>
			{label}
			{children({ field, errors, touched, ...formikProps })}
			<FormErrorMessage>{errors && errors[name]}</FormErrorMessage>
		</FormControl>
	)
}

Field.defaultProps = {
	parentName: undefined,
	label: undefined
}

Field.propTypes = {
	name: PropTypes.string.isRequired,
	parentName: PropTypes.string,
	label: PropTypes.node,
	children: PropTypes.func.isRequired
}

export default Field
