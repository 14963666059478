import * as Yup from 'yup'
import { emailValidator, passwordValidator } from '../validators'

const LoginSchema = formatMessage =>
	Yup.object().shape({
		email: emailValidator(formatMessage),
		password: passwordValidator(formatMessage)
	})

export { LoginSchema }
