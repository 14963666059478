import React from 'react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'
import { Link as ChakraLink } from '@chakra-ui/core'
import { MediumText } from '../text'

const Link = ({ to, children, ...props }) => {
	return (
		<ChakraLink {...props} as={RouterLink} to={to} color='primary.500' _hover={{ color: 'primary.900' }}>
			<MediumText>{children}</MediumText>
		</ChakraLink>
	)
}

Link.propTypes = {
	to: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired
}

export default Link
