import React from 'react'
import PropTypes from 'prop-types'
import { Box } from '@chakra-ui/core'

function Container({ children }) {
	return <Box maxWidth={{ md: '2xl' }}>{children}</Box>
}

Container.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

export default Container
