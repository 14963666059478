import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { Stack } from '@chakra-ui/core'
import QubeForm from './QubeForm'
import { interceptSectionFormError } from '../errors/errorInterceptors'
import { FormFeedbackErrorMessage } from './feedback'
import FormFeedbackIcon from './feedback/FormFeedbackIcon'
import { FormSubmitButton } from './buttons'
import buttonsMessages from '../../buttons/messages'
import InlineStack from '../../ui/stacks/InlineStack'

const QubeSectionForm = ({
	initialValues,
	onSubmit,
	validationSchema,
	children,
	intl: { formatMessage },
	submitMessage
}) => (
	<QubeForm
		initialValues={initialValues}
		onSubmit={(data, { setFieldError, ...formikBag }) =>
			onSubmit(data, { setFieldError, ...formikBag }).catch(error => {
				interceptSectionFormError(setFieldError)(error)
				return Promise.reject(error)
			})
		}
		validationSchema={validationSchema}
	>
		<Stack spacing={3}>
			<Stack>{children}</Stack>
			<FormFeedbackErrorMessage />
			<InlineStack justifyContent='flex-end'>
				<FormFeedbackIcon />
				<FormSubmitButton>{formatMessage(submitMessage)}</FormSubmitButton>
			</InlineStack>
		</Stack>
	</QubeForm>
)

QubeSectionForm.displayName = 'QubeSectionForm'

QubeSectionForm.defaultProps = {
	validationSchema: {},
	submitMessage: buttonsMessages.save
}

QubeSectionForm.propTypes = {
	initialValues: PropTypes.shape({}).isRequired,
	onSubmit: PropTypes.func.isRequired,
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired,
	validationSchema: PropTypes.shape({}),
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	submitMessage: PropTypes.shape({})
}
export default injectIntl(QubeSectionForm)
