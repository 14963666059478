import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

const ToastContext = React.createContext({})

export const useToast = () => useContext(ToastContext)

let idCounter = 0

const ToastProvider = ({ children }) => {
	const [toasts, setToasts] = useState([])

	const removeToast = id => setToasts(prevToasts => prevToasts.filter(toast => toast.id !== id))

	const showSuccessToast = message => {
		const id = idCounter
		const toastProps = {
			id,
			message,
			onRequestRemove: () => removeToast(id),
			type: 'success'
		}
		idCounter += 1
		setToasts(prevToasts => [
			toastProps,
			...prevToasts.map(toast => ({
				...toast,
				requestClose: true
			}))
		])
	}

	return <ToastContext.Provider value={{ showSuccessToast, toasts }}>{children}</ToastContext.Provider>
}

ToastProvider.propTypes = {
	children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node, PropTypes.func]).isRequired
}

export default ToastProvider
