import { Box, Flex, Stat, StatLabel, StatNumber } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'

import Card from './Card'

const MetricsCard = ({ value, label }) => (
	<Card>
		<Flex height={'100%'}>
			<Box width={2} bg='primary.500' borderRadius='md' roundedRight={0} flexShrink={0} />
			<Stat as={Card.Content}>
				<StatNumber>{value}</StatNumber>
				<StatLabel textTransform='uppercase'>{label}</StatLabel>
			</Stat>
		</Flex>
	</Card>
)

MetricsCard.propTypes = {
	value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]).isRequired,
	label: PropTypes.string.isRequired
}

export default MetricsCard
