// Adapted from: https://github.com/annexare/Countries/blob/master/data/countries.json

const countries = [
	{
		name: 'Andorra',
		code: 'AD',
		native: 'Andorra'
	},
	{
		name: 'United Arab Emirates',
		code: 'AE',
		native: 'دولة الإمارات العربية المتحدة'
	},
	{
		name: 'Afghanistan',
		code: 'AF',
		native: 'افغانستان'
	},
	{
		name: 'Antigua and Barbuda',
		code: 'AG',
		native: 'Antigua and Barbuda'
	},
	{
		name: 'Anguilla',
		code: 'AI',
		native: 'Anguilla'
	},
	{
		name: 'Albania',
		code: 'AL',
		native: 'Shqipëria'
	},
	{
		name: 'Armenia',
		code: 'AM',
		native: 'Հայաստան'
	},
	{
		name: 'Angola',
		code: 'AO',
		native: 'Angola'
	},
	{
		name: 'Antarctica',
		code: 'AQ',
		native: 'Antarctica'
	},
	{
		name: 'Argentina',
		code: 'AR',
		native: 'Argentina'
	},
	{
		name: 'American Samoa',
		code: 'AS',
		native: 'American Samoa'
	},
	{
		name: 'Austria',
		code: 'AT',
		native: 'Österreich'
	},
	{
		name: 'Australia',
		code: 'AU',
		native: 'Australia'
	},
	{
		name: 'Aruba',
		code: 'AW',
		native: 'Aruba'
	},
	{
		name: 'Åland',
		code: 'AX',
		native: 'Åland'
	},
	{
		name: 'Azerbaijan',
		code: 'AZ',
		native: 'Azərbaycan'
	},
	{
		name: 'Bosnia and Herzegovina',
		code: 'BA',
		native: 'Bosna i Hercegovina'
	},
	{
		name: 'Barbados',
		code: 'BB',
		native: 'Barbados'
	},
	{
		name: 'Bangladesh',
		code: 'BD',
		native: 'Bangladesh'
	},
	{
		name: 'Belgium',
		code: 'BE',
		native: 'België'
	},
	{
		name: 'Burkina Faso',
		code: 'BF',
		native: 'Burkina Faso'
	},
	{
		name: 'Bulgaria',
		code: 'BG',
		native: 'България'
	},
	{
		name: 'Bahrain',
		code: 'BH',
		native: '‏البحرين'
	},
	{
		name: 'Burundi',
		code: 'BI',
		native: 'Burundi'
	},
	{
		name: 'Benin',
		code: 'BJ',
		native: 'Bénin'
	},
	{
		name: 'Saint Barthélemy',
		code: 'BL',
		native: 'Saint-Barthélemy'
	},
	{
		name: 'Bermuda',
		code: 'BM',
		native: 'Bermuda'
	},
	{
		name: 'Brunei',
		code: 'BN',
		native: 'Negara Brunei Darussalam'
	},
	{
		name: 'Bolivia',
		code: 'BO',
		native: 'Bolivia'
	},
	{
		name: 'Bonaire',
		code: 'BQ',
		native: 'Bonaire'
	},
	{
		name: 'Brazil',
		code: 'BR',
		native: 'Brasil'
	},
	{
		name: 'Bahamas',
		code: 'BS',
		native: 'Bahamas'
	},
	{
		name: 'Bhutan',
		code: 'BT',
		native: 'ʼbrug-yul'
	},
	{
		name: 'Bouvet Island',
		code: 'BV',
		native: 'Bouvetøya'
	},
	{
		name: 'Botswana',
		code: 'BW',
		native: 'Botswana'
	},
	{
		name: 'Belarus',
		code: 'BY',
		native: 'Белару́сь'
	},
	{
		name: 'Belize',
		code: 'BZ',
		native: 'Belize'
	},
	{
		name: 'Canada',
		code: 'CA',
		native: 'Canada'
	},
	{
		name: 'Cocos [Keeling] Islands',
		code: 'CC',
		native: 'Cocos (Keeling) Islands'
	},
	{
		name: 'Democratic Republic of the Congo',
		code: 'CD',
		native: 'République démocratique du Congo'
	},
	{
		name: 'Central African Republic',
		code: 'CF',
		native: 'Ködörösêse tî Bêafrîka'
	},
	{
		name: 'Republic of the Congo',
		code: 'CG',
		native: 'République du Congo'
	},
	{
		name: 'Switzerland',
		code: 'CH',
		native: 'Schweiz'
	},
	{
		name: 'Ivory Coast',
		code: 'CI',
		native: "Côte d'Ivoire"
	},
	{
		name: 'Cook Islands',
		code: 'CK',
		native: 'Cook Islands'
	},
	{
		name: 'Chile',
		code: 'CL',
		native: 'Chile'
	},
	{
		name: 'Cameroon',
		code: 'CM',
		native: 'Cameroon'
	},
	{
		name: 'China',
		code: 'CN',
		native: '中国'
	},
	{
		name: 'Colombia',
		code: 'CO',
		native: 'Colombia'
	},
	{
		name: 'Costa Rica',
		code: 'CR',
		native: 'Costa Rica'
	},
	{
		name: 'Cuba',
		code: 'CU',
		native: 'Cuba'
	},
	{
		name: 'Cape Verde',
		code: 'CV',
		native: 'Cabo Verde'
	},
	{
		name: 'Curacao',
		code: 'CW',
		native: 'Curaçao'
	},
	{
		name: 'Christmas Island',
		code: 'CX',
		native: 'Christmas Island'
	},
	{
		name: 'Cyprus',
		code: 'CY',
		native: 'Κύπρος'
	},
	{
		name: 'Czech Republic',
		code: 'CZ',
		native: 'Česká republika'
	},
	{
		name: 'Germany',
		code: 'DE',
		native: 'Deutschland'
	},
	{
		name: 'Djibouti',
		code: 'DJ',
		native: 'Djibouti'
	},
	{
		name: 'Denmark',
		code: 'DK',
		native: 'Danmark'
	},
	{
		name: 'Dominica',
		code: 'DM',
		native: 'Dominica'
	},
	{
		name: 'Dominican Republic',
		code: 'DO',
		native: 'República Dominicana'
	},
	{
		name: 'Algeria',
		code: 'DZ',
		native: 'الجزائر'
	},
	{
		name: 'Ecuador',
		code: 'EC',
		native: 'Ecuador'
	},
	{
		name: 'Estonia',
		code: 'EE',
		native: 'Eesti'
	},
	{
		name: 'Egypt',
		code: 'EG',
		native: 'مصر‎'
	},
	{
		name: 'Western Sahara',
		code: 'EH',
		native: 'الصحراء الغربية'
	},
	{
		name: 'Eritrea',
		code: 'ER',
		native: 'ኤርትራ'
	},
	{
		name: 'Spain',
		code: 'ES',
		native: 'España'
	},
	{
		name: 'Ethiopia',
		code: 'ET',
		native: 'ኢትዮጵያ'
	},
	{
		name: 'Finland',
		code: 'FI',
		native: 'Suomi'
	},
	{
		name: 'Fiji',
		code: 'FJ',
		native: 'Fiji'
	},
	{
		name: 'Falkland Islands',
		code: 'FK',
		native: 'Falkland Islands'
	},
	{
		name: 'Micronesia',
		code: 'FM',
		native: 'Micronesia'
	},
	{
		name: 'Faroe Islands',
		code: 'FO',
		native: 'Føroyar'
	},
	{
		name: 'France',
		code: 'FR',
		native: 'France'
	},
	{
		name: 'Gabon',
		code: 'GA',
		native: 'Gabon'
	},
	{
		name: 'United Kingdom',
		code: 'GB',
		native: 'United Kingdom'
	},
	{
		name: 'Grenada',
		code: 'GD',
		native: 'Grenada'
	},
	{
		name: 'Georgia',
		code: 'GE',
		native: 'საქართველო'
	},
	{
		name: 'French Guiana',
		code: 'GF',
		native: 'Guyane française'
	},
	{
		name: 'Guernsey',
		code: 'GG',
		native: 'Guernsey'
	},
	{
		name: 'Ghana',
		code: 'GH',
		native: 'Ghana'
	},
	{
		name: 'Gibraltar',
		code: 'GI',
		native: 'Gibraltar'
	},
	{
		name: 'Greenland',
		code: 'GL',
		native: 'Kalaallit Nunaat'
	},
	{
		name: 'Gambia',
		code: 'GM',
		native: 'Gambia'
	},
	{
		name: 'Guinea',
		code: 'GN',
		native: 'Guinée'
	},
	{
		name: 'Guadeloupe',
		code: 'GP',
		native: 'Guadeloupe'
	},
	{
		name: 'Equatorial Guinea',
		code: 'GQ',
		native: 'Guinea Ecuatorial'
	},
	{
		name: 'Greece',
		code: 'GR',
		native: 'Ελλάδα'
	},
	{
		name: 'South Georgia and the South Sandwich Islands',
		code: 'GS',
		native: 'South Georgia'
	},
	{
		name: 'Guatemala',
		code: 'GT',
		native: 'Guatemala'
	},
	{
		name: 'Guam',
		code: 'GU',
		native: 'Guam'
	},
	{
		name: 'Guinea-Bissau',
		code: 'GW',
		native: 'Guiné-Bissau'
	},
	{
		name: 'Guyana',
		code: 'GY',
		native: 'Guyana'
	},
	{
		name: 'Hong Kong',
		code: 'HK',
		native: '香港'
	},
	{
		name: 'Heard Island and McDonald Islands',
		code: 'HM',
		native: 'Heard Island and McDonald Islands'
	},
	{
		name: 'Honduras',
		code: 'HN',
		native: 'Honduras'
	},
	{
		name: 'Croatia',
		code: 'HR',
		native: 'Hrvatska'
	},
	{
		name: 'Haiti',
		code: 'HT',
		native: 'Haïti'
	},
	{
		name: 'Hungary',
		code: 'HU',
		native: 'Magyarország'
	},
	{
		name: 'Indonesia',
		code: 'ID',
		native: 'Indonesia'
	},
	{
		name: 'Ireland',
		code: 'IE',
		native: 'Éire'
	},
	{
		name: 'Israel',
		code: 'IL',
		native: 'יִשְׂרָאֵל'
	},
	{
		name: 'Isle of Man',
		code: 'IM',
		native: 'Isle of Man'
	},
	{
		name: 'India',
		code: 'IN',
		native: 'भारत'
	},
	{
		name: 'British Indian Ocean Territory',
		code: 'IO',
		native: 'British Indian Ocean Territory'
	},
	{
		name: 'Iraq',
		code: 'IQ',
		native: 'العراق'
	},
	{
		name: 'Iran',
		code: 'IR',
		native: 'ایران'
	},
	{
		name: 'Iceland',
		code: 'IS',
		native: 'Ísland'
	},
	{
		name: 'Italy',
		code: 'IT',
		native: 'Italia'
	},
	{
		name: 'Jersey',
		code: 'JE',
		native: 'Jersey'
	},
	{
		name: 'Jamaica',
		code: 'JM',
		native: 'Jamaica'
	},
	{
		name: 'Jordan',
		code: 'JO',
		native: 'الأردن'
	},
	{
		name: 'Japan',
		code: 'JP',
		native: '日本'
	},
	{
		name: 'Kenya',
		code: 'KE',
		native: 'Kenya'
	},
	{
		name: 'Kyrgyzstan',
		code: 'KG',
		native: 'Кыргызстан'
	},
	{
		name: 'Cambodia',
		code: 'KH',
		native: 'Kâmpŭchéa'
	},
	{
		name: 'Kiribati',
		code: 'KI',
		native: 'Kiribati'
	},
	{
		name: 'Comoros',
		code: 'KM',
		native: 'Komori'
	},
	{
		name: 'Saint Kitts and Nevis',
		code: 'KN',
		native: 'Saint Kitts and Nevis'
	},
	{
		name: 'North Korea',
		code: 'KP',
		native: '북한'
	},
	{
		name: 'South Korea',
		code: 'KR',
		native: '대한민국'
	},
	{
		name: 'Kuwait',
		code: 'KW',
		native: 'الكويت'
	},
	{
		name: 'Cayman Islands',
		code: 'KY',
		native: 'Cayman Islands'
	},
	{
		name: 'Kazakhstan',
		code: 'KZ',
		native: 'Қазақстан'
	},
	{
		name: 'Laos',
		code: 'LA',
		native: 'ສປປລາວ'
	},
	{
		name: 'Lebanon',
		code: 'LB',
		native: 'لبنان'
	},
	{
		name: 'Saint Lucia',
		code: 'LC',
		native: 'Saint Lucia'
	},
	{
		name: 'Liechtenstein',
		code: 'LI',
		native: 'Liechtenstein'
	},
	{
		name: 'Sri Lanka',
		code: 'LK',
		native: 'śrī laṃkāva'
	},
	{
		name: 'Liberia',
		code: 'LR',
		native: 'Liberia'
	},
	{
		name: 'Lesotho',
		code: 'LS',
		native: 'Lesotho'
	},
	{
		name: 'Lithuania',
		code: 'LT',
		native: 'Lietuva'
	},
	{
		name: 'Luxembourg',
		code: 'LU',
		native: 'Luxembourg'
	},
	{
		name: 'Latvia',
		code: 'LV',
		native: 'Latvija'
	},
	{
		name: 'Libya',
		code: 'LY',
		native: '‏ليبيا'
	},
	{
		name: 'Morocco',
		code: 'MA',
		native: 'المغرب'
	},
	{
		name: 'Monaco',
		code: 'MC',
		native: 'Monaco'
	},
	{
		name: 'Moldova',
		code: 'MD',
		native: 'Moldova'
	},
	{
		name: 'Montenegro',
		code: 'ME',
		native: 'Црна Гора'
	},
	{
		name: 'Saint Martin',
		code: 'MF',
		native: 'Saint-Martin'
	},
	{
		name: 'Madagascar',
		code: 'MG',
		native: 'Madagasikara'
	},
	{
		name: 'Marshall Islands',
		code: 'MH',
		native: 'M̧ajeļ'
	},
	{
		name: 'North Macedonia',
		code: 'MK',
		native: 'Северна Македонија'
	},
	{
		name: 'Mali',
		code: 'ML',
		native: 'Mali'
	},
	{
		name: 'Myanmar [Burma]',
		code: 'MM',
		native: 'မြန်မာ'
	},
	{
		name: 'Mongolia',
		code: 'MN',
		native: 'Монгол улс'
	},
	{
		name: 'Macao',
		code: 'MO',
		native: '澳門'
	},
	{
		name: 'Northern Mariana Islands',
		code: 'MP',
		native: 'Northern Mariana Islands'
	},
	{
		name: 'Martinique',
		code: 'MQ',
		native: 'Martinique'
	},
	{
		name: 'Mauritania',
		code: 'MR',
		native: 'موريتانيا'
	},
	{
		name: 'Montserrat',
		code: 'MS',
		native: 'Montserrat'
	},
	{
		name: 'Malta',
		code: 'MT',
		native: 'Malta'
	},
	{
		name: 'Mauritius',
		code: 'MU',
		native: 'Maurice'
	},
	{
		name: 'Maldives',
		code: 'MV',
		native: 'Maldives'
	},
	{
		name: 'Malawi',
		code: 'MW',
		native: 'Malawi'
	},
	{
		name: 'Mexico',
		code: 'MX',
		native: 'México'
	},
	{
		name: 'Malaysia',
		code: 'MY',
		native: 'Malaysia'
	},
	{
		name: 'Mozambique',
		code: 'MZ',
		native: 'Moçambique'
	},
	{
		name: 'Namibia',
		code: 'NA',
		native: 'Namibia'
	},
	{
		name: 'New Caledonia',
		code: 'NC',
		native: 'Nouvelle-Calédonie'
	},
	{
		name: 'Niger',
		code: 'NE',
		native: 'Niger'
	},
	{
		name: 'Norfolk Island',
		code: 'NF',
		native: 'Norfolk Island'
	},
	{
		name: 'Nigeria',
		code: 'NG',
		native: 'Nigeria'
	},
	{
		name: 'Nicaragua',
		code: 'NI',
		native: 'Nicaragua'
	},
	{
		name: 'Netherlands',
		code: 'NL',
		native: 'Nederland'
	},
	{
		name: 'Norway',
		code: 'NO',
		native: 'Norge'
	},
	{
		name: 'Nepal',
		code: 'NP',
		native: 'नपल'
	},
	{
		name: 'Nauru',
		code: 'NR',
		native: 'Nauru'
	},
	{
		name: 'Niue',
		code: 'NU',
		native: 'Niuē'
	},
	{
		name: 'New Zealand',
		code: 'NZ',
		native: 'New Zealand'
	},
	{
		name: 'Oman',
		code: 'OM',
		native: 'عمان'
	},
	{
		name: 'Panama',
		code: 'PA',
		native: 'Panamá'
	},
	{
		name: 'Peru',
		code: 'PE',
		native: 'Perú'
	},
	{
		name: 'French Polynesia',
		code: 'PF',
		native: 'Polynésie française'
	},
	{
		name: 'Papua New Guinea',
		code: 'PG',
		native: 'Papua Niugini'
	},
	{
		name: 'Philippines',
		code: 'PH',
		native: 'Pilipinas'
	},
	{
		name: 'Pakistan',
		code: 'PK',
		native: 'Pakistan'
	},
	{
		name: 'Poland',
		code: 'PL',
		native: 'Polska'
	},
	{
		name: 'Saint Pierre and Miquelon',
		code: 'PM',
		native: 'Saint-Pierre-et-Miquelon'
	},
	{
		name: 'Pitcairn Islands',
		code: 'PN',
		native: 'Pitcairn Islands'
	},
	{
		name: 'Puerto Rico',
		code: 'PR',
		native: 'Puerto Rico'
	},
	{
		name: 'Palestine',
		code: 'PS',
		native: 'فلسطين'
	},
	{
		name: 'Portugal',
		code: 'PT',
		native: 'Portugal'
	},
	{
		name: 'Palau',
		code: 'PW',
		native: 'Palau'
	},
	{
		name: 'Paraguay',
		code: 'PY',
		native: 'Paraguay'
	},
	{
		name: 'Qatar',
		code: 'QA',
		native: 'قطر'
	},
	{
		name: 'Réunion',
		code: 'RE',
		native: 'La Réunion'
	},
	{
		name: 'Romania',
		code: 'RO',
		native: 'România'
	},
	{
		name: 'Serbia',
		code: 'RS',
		native: 'Србија'
	},
	{
		name: 'Russia',
		code: 'RU',
		native: 'Россия'
	},
	{
		name: 'Rwanda',
		code: 'RW',
		native: 'Rwanda'
	},
	{
		name: 'Saudi Arabia',
		code: 'SA',
		native: 'العربية السعودية'
	},
	{
		name: 'Solomon Islands',
		code: 'SB',
		native: 'Solomon Islands'
	},
	{
		name: 'Seychelles',
		code: 'SC',
		native: 'Seychelles'
	},
	{
		name: 'Sudan',
		code: 'SD',
		native: 'السودان'
	},
	{
		name: 'Sweden',
		code: 'SE',
		native: 'Sverige'
	},
	{
		name: 'Singapore',
		code: 'SG',
		native: 'Singapore'
	},
	{
		name: 'Saint Helena',
		code: 'SH',
		native: 'Saint Helena'
	},
	{
		name: 'Slovenia',
		code: 'SI',
		native: 'Slovenija'
	},
	{
		name: 'Svalbard and Jan Mayen',
		code: 'SJ',
		native: 'Svalbard og Jan Mayen'
	},
	{
		name: 'Slovakia',
		code: 'SK',
		native: 'Slovensko'
	},
	{
		name: 'Sierra Leone',
		code: 'SL',
		native: 'Sierra Leone'
	},
	{
		name: 'San Marino',
		code: 'SM',
		native: 'San Marino'
	},
	{
		name: 'Senegal',
		code: 'SN',
		native: 'Sénégal'
	},
	{
		name: 'Somalia',
		code: 'SO',
		native: 'Soomaaliya'
	},
	{
		name: 'Suriname',
		code: 'SR',
		native: 'Suriname'
	},
	{
		name: 'South Sudan',
		code: 'SS',
		native: 'South Sudan'
	},
	{
		name: 'São Tomé and Príncipe',
		code: 'ST',
		native: 'São Tomé e Príncipe'
	},
	{
		name: 'El Salvador',
		code: 'SV',
		native: 'El Salvador'
	},
	{
		name: 'Sint Maarten',
		code: 'SX',
		native: 'Sint Maarten'
	},
	{
		name: 'Syria',
		code: 'SY',
		native: 'سوريا'
	},
	{
		name: 'Swaziland',
		code: 'SZ',
		native: 'Swaziland'
	},
	{
		name: 'Turks and Caicos Islands',
		code: 'TC',
		native: 'Turks and Caicos Islands'
	},
	{
		name: 'Chad',
		code: 'TD',
		native: 'Tchad'
	},
	{
		name: 'French Southern Territories',
		code: 'TF',
		native: 'Territoire des Terres australes et antarctiques fr'
	},
	{
		name: 'Togo',
		code: 'TG',
		native: 'Togo'
	},
	{
		name: 'Thailand',
		code: 'TH',
		native: 'ประเทศไทย'
	},
	{
		name: 'Tajikistan',
		code: 'TJ',
		native: 'Тоҷикистон'
	},
	{
		name: 'Tokelau',
		code: 'TK',
		native: 'Tokelau'
	},
	{
		name: 'East Timor',
		code: 'TL',
		native: 'Timor-Leste'
	},
	{
		name: 'Turkmenistan',
		code: 'TM',
		native: 'Türkmenistan'
	},
	{
		name: 'Tunisia',
		code: 'TN',
		native: 'تونس'
	},
	{
		name: 'Tonga',
		code: 'TO',
		native: 'Tonga'
	},
	{
		name: 'Turkey',
		code: 'TR',
		native: 'Türkiye'
	},
	{
		name: 'Trinidad and Tobago',
		code: 'TT',
		native: 'Trinidad and Tobago'
	},
	{
		name: 'Tuvalu',
		code: 'TV',
		native: 'Tuvalu'
	},
	{
		name: 'Taiwan',
		code: 'TW',
		native: '臺灣'
	},
	{
		name: 'Tanzania',
		code: 'TZ',
		native: 'Tanzania'
	},
	{
		name: 'Ukraine',
		code: 'UA',
		native: 'Україна'
	},
	{
		name: 'Uganda',
		code: 'UG',
		native: 'Uganda'
	},
	{
		name: 'U.S. Minor Outlying Islands',
		code: 'UM',
		native: 'United States Minor Outlying Islands'
	},
	{
		name: 'United States',
		code: 'US',
		native: 'United States'
	},
	{
		name: 'Uruguay',
		code: 'UY',
		native: 'Uruguay'
	},
	{
		name: 'Uzbekistan',
		code: 'UZ',
		native: 'O‘zbekiston'
	},
	{
		name: 'Vatican City',
		code: 'VA',
		native: 'Vaticano'
	},
	{
		name: 'Saint Vincent and the Grenadines',
		code: 'VC',
		native: 'Saint Vincent and the Grenadines'
	},
	{
		name: 'Venezuela',
		code: 'VE',
		native: 'Venezuela'
	},
	{
		name: 'British Virgin Islands',
		code: 'VG',
		native: 'British Virgin Islands'
	},
	{
		name: 'U.S. Virgin Islands',
		code: 'VI',
		native: 'United States Virgin Islands'
	},
	{
		name: 'Vietnam',
		code: 'VN',
		native: 'Việt Nam'
	},
	{
		name: 'Vanuatu',
		code: 'VU',
		native: 'Vanuatu'
	},
	{
		name: 'Wallis and Futuna',
		code: 'WF',
		native: 'Wallis et Futuna'
	},
	{
		name: 'Samoa',
		code: 'WS',
		native: 'Samoa'
	},
	{
		name: 'Kosovo',
		code: 'XK',
		native: 'Republika e Kosovës'
	},
	{
		name: 'Yemen',
		code: 'YE',
		native: 'اليَمَن'
	},
	{
		name: 'Mayotte',
		code: 'YT',
		native: 'Mayotte'
	},
	{
		name: 'South Africa',
		code: 'ZA',
		native: 'South Africa'
	},
	{
		name: 'Zambia',
		code: 'ZM',
		native: 'Zambia'
	},
	{
		name: 'Zimbabwe',
		code: 'ZW',
		native: 'Zimbabwe'
	}
]

export default countries
