import React from 'react'
import { useIntl } from 'react-intl'
import { usePaginatedQuery } from 'react-query'
import { useSearchParams } from 'react-router-dom'

import { CardsList, ListHeader } from 'ui/lists'
import CenteredLoading from 'ui/loadings/CenteredLoading'
import { EmptyState } from 'utils/emptyStates'

import api, { defaultPageSize } from '../../api/api'
import { useAuth } from '../../auth/AuthProvider'
import { interceptError } from '../../utils/errors/errorInterceptors'
import Pagination from '../../utils/pagination/Pagination'
import { PROFILE_LOCATIONS_KEY } from '../entityKeys'
import LocationCard from './LocationCard'
import messages from './messages'

const fetchProfileLocations = (_, params) => api.profileLocations.list(params)

const LocationsListContainer = () => {
	const { formatMessage } = useIntl()
	const { user } = useAuth()
	const [searchParams] = useSearchParams()
	const activePage = Number(searchParams.get('activePage')) || 1

	const { status, resolvedData } = usePaginatedQuery(
		[PROFILE_LOCATIONS_KEY, { profileId: user.profile.id, page: activePage }],
		fetchProfileLocations,
		{ retry: false, onError: interceptError }
	)

	const { count = 0, results = [] } = resolvedData?.data || {}
	const totalPages = Math.ceil(count / defaultPageSize)

	return (
		<>
			{count !== 0 && <ListHeader pagination={<Pagination activePage={activePage} totalPages={totalPages} />} />}
			{status === 'loading' && <CenteredLoading />}
			{status === 'success' && count !== 0 && (
				<CardsList>
					{results.map((item, index) => (
						<LocationCard key={index} {...item} />
					))}
				</CardsList>
			)}
			{status === 'success' && count === 0 && (
				<EmptyState
					title={formatMessage(messages.emptyStateTitle)}
					description={formatMessage(messages.emptyStateDescription)}
					imageSrc='/images/empty-state-profile-locations.png'
				/>
			)}
		</>
	)
}

export default LocationsListContainer
