import { Divider, Icon, Stack } from '@chakra-ui/core'
import React, { useReducer, useState } from 'react'
import { useIntl } from 'react-intl'

import { useLocation } from 'locations/LocationProvider'
import Container from 'utils/layout/Container'

import { LargeText } from '../ui/text'
import { SectionStack, PageHeader } from '../utils/layout'
import { SYSTEM_RESET, TICKET_CALLED, TICKET_GENERATED } from './actions'
import { TicketListContainer } from './components'
import PeopleNumberSelector from './components/PeopleNumberSelector'
import TicketDisplayer from './components/TicketDisplayer'
import CallNextContainer from './containers/CallNextContainer'
import CleanOldTicketsButtonContainer from './containers/CleanOldTicketsButtonContainer'
import GenerateTicketContainer from './containers/GenerateTicketContainer'
import PeopleCounterContainer from './containers/PeopleCounterContainer'
import ResetQMSystemButtonContainer from './containers/ResetQMSystemButtonContainer'
import messages from './messages'
import { reducer, initialState } from './reducer'

const QueueManagementContainer = () => {
	const { formatMessage, formatRelativeTime } = useIntl()
	const { ticketsWaiting } = useLocation()
	const [numberOfPeople, setNumberOfPeople] = useState(1)
	const [{ lastCalledTicket, lastGeneratedTicket }, dispatch] = useReducer(reducer, initialState)

	const getRelativeTimeInMinutes = time => {
		const date = new Date(time)
		return Math.ceil((date.getTime() - Date.now()) / 1000 / 60)
	}

	const formatRelativeInMinutes = time =>
		formatRelativeTime(getRelativeTimeInMinutes(time), 'minute', { numeric: 'auto' })

	return (
		<SectionStack>
			<SectionStack.Element>
				<PageHeader
					title={formatMessage(messages.queueManagementTitle)}
					action={<ResetQMSystemButtonContainer onReset={() => dispatch({ type: SYSTEM_RESET })} />}
				/>
			</SectionStack.Element>
			<SectionStack.Element as={Container}>
				<Stack spacing={6}>
					<Stack spacing={3}>
						<Stack isInline spacing={5}>
							<CallNextContainer
								onCallNext={data => dispatch({ type: TICKET_CALLED, payload: data })}
								flex={1}
							/>
							{lastCalledTicket && (
								<TicketDisplayer
									flex={1}
									minWidth={0}
									description={formatMessage(messages.lastCalledTicket)}
									formattedNumber={lastCalledTicket.formatted_number}
									relativeTime={formatRelativeInMinutes(lastCalledTicket.called_at)}
									icon={
										lastCalledTicket.generated_by_profile ? (
											<Icon name='roundedTicket' color='gray.200' size={['28px', '32px']} />
										) : (
											<Icon name='qrCode' color='primary.500' size={['15px', '20px']} />
										)
									}
								/>
							)}
						</Stack>

						<TicketListContainer />
						<CleanOldTicketsButtonContainer />
					</Stack>
					<PeopleCounterContainer />
					<Divider />
					<Stack spacing={3}>
						<PeopleNumberSelector
							onChange={setNumberOfPeople}
							value={numberOfPeople}
							defaultValues={[1, 2, 3, 4]}
						/>
						<Stack isInline spacing={5}>
							<GenerateTicketContainer
								numberOfPeople={numberOfPeople}
								onGenerateTicket={data => {
									dispatch({ type: TICKET_GENERATED, payload: data })
									setNumberOfPeople(1)
								}}
								flex={1}
							/>
							{lastGeneratedTicket && (
								<TicketDisplayer
									flex={1}
									minWidth={0}
									description={formatMessage(messages.lastGeneratedTicket)}
									formattedNumber={lastGeneratedTicket.formatted_number}
									relativeTime={formatRelativeInMinutes(lastGeneratedTicket.created_at)}
								/>
							)}
						</Stack>
					</Stack>
				</Stack>
				<Divider my={[5, 6]} />
				{ticketsWaiting !== undefined && (
					<LargeText textTransform='uppercase'>
						{formatMessage(messages.ticketsWaiting, { number: ticketsWaiting })}
					</LargeText>
				)}
			</SectionStack.Element>
		</SectionStack>
	)
}

export default QueueManagementContainer
