import { defineMessages } from 'react-intl'

export const scope = 'qrq.locations'

export default defineMessages({
	namePlaceholder: {
		id: `${scope}.placeholder.locationName`
	},
	name: {
		id: `${scope}.label.locationName`
	},
	maxInside: {
		id: `${scope}.label.maxInside`
	},
	country: {
		id: `${scope}.label.country`
	},
	businessSector: {
		id: `${scope}.label.businessSector`
	},
	retail: {
		id: `${scope}.businessSectors.retail`
	},
	catering: {
		id: `${scope}.businessSectors.catering`
	},
	healthcare: {
		id: `${scope}.businessSectors.healthcare`
	},
	education: {
		id: `${scope}.businessSectors.education`
	},
	tourism: {
		id: `${scope}.businessSectors.tourism`
	},
	entertainment: {
		id: `${scope}.businessSectors.entertainment`
	},
	serviceProvider: {
		id: `${scope}.businessSectors.serviceProvider`
	},
	other: {
		id: `${scope}.businessSectors.other`
	}
})
