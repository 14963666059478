import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl } from 'react-intl'
import { ModalBody, ModalHeader } from '@chakra-ui/core'
import { queryCache, useMutation } from 'react-query'

import Modal from 'utils/modals/components/Modal'
import { useToast } from 'utils/toasts'
import messages from './messages'
import { Title } from '../../ui/text'
import LocationForm from './LocationForm'
import { useAuth } from '../../auth/AuthProvider'
import api from '../../api/api'
import { PROFILE_LOCATIONS_KEY } from '../entityKeys'
import { useNavigate } from 'react-router-dom'

const CreateLocation = ({ isOpen, onClose, intl: { formatMessage } }) => {
	const { showSuccessToast } = useToast()
	const { user } = useAuth()
	const navigate = useNavigate()

	const profileId = user.profile.id
	const [mutate] = useMutation(api.locations.create, {
		onSuccess: ({ data: { id: locationId } }) => {
			navigate(`${locationId}`)
			queryCache.invalidateQueries(PROFILE_LOCATIONS_KEY)
			showSuccessToast(formatMessage(messages.locationCreatedSuccessMessage))
			onClose()
		}
	})

	const handleOnSubmit = data => mutate({ profileId, data }, { throwOnError: true })

	return (
		<Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
			<ModalHeader>
				<Title>{formatMessage(messages.addLocation)}</Title>
			</ModalHeader>
			<ModalBody>
				<LocationForm handleOnSubmit={handleOnSubmit} />
			</ModalBody>
		</Modal>
	)
}

CreateLocation.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(CreateLocation)
