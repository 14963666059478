import React from 'react'
import PropTypes from 'prop-types'
import { Stack } from '@chakra-ui/core'
import { injectIntl } from 'react-intl'
import Link from '../../ui/links/Link'
import { Card } from '../../ui/cards'
import { SmallTextInfo } from '../../ui/text'
import locationMessages from '../messages'

const LocationCard = ({ id, name, people_inside_limit: peopleInsideLimit, intl: { formatMessage } }) => (
	<Card>
		<Card.Content>
			<Stack spacing={2}>
				<Link to={`${id}`}>{name}</Link>
				<SmallTextInfo>
					{formatMessage(locationMessages.maxInside)}: {peopleInsideLimit}
				</SmallTextInfo>
			</Stack>
		</Card.Content>
	</Card>
)

LocationCard.defaultProps = {
	people_inside_limit: undefined
}

LocationCard.propTypes = {
	id: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired,
	people_inside_limit: PropTypes.number,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(LocationCard)
