import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { injectIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'

import { api } from 'api'
import { MediumText, PageTitle } from 'ui/text'

import Link from '../../ui/links/Link'
import { SectionStack } from '../../utils/layout'
import forgotPasswordMessages from '../forgotPassword/messages'
import authMessages from '../messages'
import ResetPasswordForm from './ResetPasswordForm'
import messages from './messages'

const STATUS = {
	FORM: 0,
	SUCCESS: 1
}

const ResetPasswordContainer = ({ intl: { formatMessage } }) => {
	const { search } = useLocation()
	const [status, setStatus] = useState(STATUS.FORM)
	const urlParams = new URLSearchParams(search)
	const uid = urlParams.get('uid')
	const token = urlParams.get('token')

	const handleOnSubmit = data =>
		api.auth
			.confirmResetPassword({ ...data, new_password1: data.new_password2, uid, token })
			.then(() => setStatus(STATUS.SUCCESS))

	const title = status === STATUS.SUCCESS ? forgotPasswordMessages.allDone : messages.title

	const content =
		status === STATUS.SUCCESS ? (
			<SectionStack.Element>
				<MediumText>{formatMessage(messages.passwordUpdated)}</MediumText>
			</SectionStack.Element>
		) : (
			<SectionStack>
				<SectionStack.Element>
					<MediumText>{formatMessage(messages.resetPasswordHeader)}</MediumText>
				</SectionStack.Element>
				<SectionStack.Element>
					<ResetPasswordForm handleOnSubmit={handleOnSubmit} initialValues={{ token, uid }} />
				</SectionStack.Element>
			</SectionStack>
		)
	return (
		<SectionStack>
			<SectionStack.Element>
				<PageTitle>{formatMessage(title)}</PageTitle>
			</SectionStack.Element>
			{content}
			<SectionStack.Element mx='auto'>
				<Link id='reset-password-link-to-login' to='/login'>
					{formatMessage(authMessages.backToLogin)}
				</Link>
			</SectionStack.Element>
		</SectionStack>
	)
}

ResetPasswordContainer.propTypes = {
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired
}

export default injectIntl(ResetPasswordContainer)
export { ResetPasswordContainer }
