import React from 'react'
import { Button } from '@chakra-ui/core'

const TopnavButton = props => (
	<Button
		_focus={{ boxShadow: 'none' }}
		_active={{ color: 'topnav.button.hoverText', bg: 'topnav.button.hoverBg' }}
		_hover={{ color: 'topnav.button.hoverText', bg: 'topnav.button.hoverBg' }}
		bg='topnav.button.bg'
		fontWeight='normal'
		px={3}
		{...props}
	/>
)

export default TopnavButton
