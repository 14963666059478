import { Box } from '@chakra-ui/core'
import React from 'react'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

import { api } from 'api'
import { useLocation } from 'locations/LocationProvider'
import useCreateSubscription from 'subscriptions/useCreateSubscription'
import { MediumText, SectionCard } from 'ui'
import CenteredLoading from 'ui/loadings/CenteredLoading'
import { interceptError } from 'utils/errors/errorInterceptors'
import Container from 'utils/layout/Container'

import { PageHeader, SectionStack } from '../../utils/layout'
import SubscriptionForm from '../components/SubscriptionForm'
import { SUBSCRIPTION_PLAN_KEY } from '../entityKeys'
import messages from '../messages'

const SubscriptionCreateContainer = () => {
	const { formatMessage } = useIntl()
	const { subPlanId } = useParams()
	const { location } = useLocation()
	const { createSubscription } = useCreateSubscription()

	const { isLoading, isSuccess, data: subscriptionPlan } = useQuery(
		[SUBSCRIPTION_PLAN_KEY, parseInt(subPlanId, 10)],
		async (_, params) => {
			const { data } = await api.subscriptionPlans.detail(params)
			return data
		},
		{ onError: interceptError }
	)

	const handleOnSubmit = data => createSubscription(location.id, subPlanId, data.start_datetime)

	return (
		<>
			{isLoading && <CenteredLoading />}
			{isSuccess && (
				<SectionStack>
					<SectionStack.Element>
						<PageHeader title={formatMessage(messages.createTitle)} />
					</SectionStack.Element>
					<SectionStack.Element as={Container}>
						<SectionCard title={formatMessage(messages.subPlanDetails)}>
							<Box>
								<MediumText fontWeight='bold'>
									{subscriptionPlan.subscription_plan_group.display_name}
								</MediumText>
								<MediumText>
									{formatMessage(messages.detailsTickets, { number: subscriptionPlan.max_tickets })}
								</MediumText>
								<MediumText>
									{formatMessage(messages.detailsDuration, { number: subscriptionPlan.duration })}
								</MediumText>
								<MediumText>
									{formatMessage(messages.detailsPrice, { number: subscriptionPlan.price })}
								</MediumText>
							</Box>

							<SubscriptionForm subscriptionPlan={subscriptionPlan} handleOnSubmit={handleOnSubmit} />
						</SectionCard>
					</SectionStack.Element>
				</SectionStack>
			)}
		</>
	)
}

export default SubscriptionCreateContainer
