import {
	Input,
	NumberDecrementStepper,
	NumberIncrementStepper,
	NumberInput,
	NumberInputField,
	NumberInputStepper,
	Select
} from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'

import buttonsMessages from '../../buttons/messages'
import { QubeSectionForm, Field, FormLabel } from '../../utils/forms'
import locationMessages from '../messages'
import businessSectors from './businessSectors'
import countries from './countryCodes'
import { LocationDetailsSchema } from './schema'

const LocationForm = ({ handleOnSubmit, intl: { formatMessage }, location }) => (
	<QubeSectionForm
		initialValues={{
			name: location.name,
			people_inside_limit: location.people_inside_limit,
			country: location.country,
			business_sector: location.business_sector
		}}
		validationSchema={LocationDetailsSchema(formatMessage)}
		onSubmit={handleOnSubmit}
		submitMessage={buttonsMessages.save}
	>
		<Field
			name='name'
			label={<FormLabel htmlFor='create-location-input-name'>{formatMessage(locationMessages.name)}</FormLabel>}
		>
			{({ field }) => (
				<Input
					id='create-location-input-name'
					placeholder={formatMessage(locationMessages.namePlaceholder)}
					{...field}
				/>
			)}
		</Field>

		<Field
			name='people_inside_limit'
			label={
				<FormLabel htmlFor='create-location-input-name'>{formatMessage(locationMessages.maxInside)}</FormLabel>
			}
		>
			{({ setFieldValue, field }) => (
				<NumberInput
					id='create-location-input-people-inside'
					size='md'
					min={0}
					{...field}
					onChange={value => setFieldValue('people_inside_limit', value)}
				>
					<NumberInputField type='number' />
					<NumberInputStepper>
						<NumberIncrementStepper />
						<NumberDecrementStepper />
					</NumberInputStepper>
				</NumberInput>
			)}
		</Field>

		<Field
			name='country'
			label={
				<FormLabel htmlFor='create-location-input-country'>{formatMessage(locationMessages.country)}</FormLabel>
			}
		>
			{({ field }) => (
				<Select id='create-location-input-country' size='md' min={0} icon='chevron-down' {...field}>
					<option selected hidden disabled value='' />
					{countries
						.sort((countryA, countryB) => countryA.name.localeCompare(countryB.name))
						.map(country => (
							<option key={country.code} value={country.code}>
								{country.native}
							</option>
						))}
				</Select>
			)}
		</Field>

		<Field
			name='business_sector'
			label={
				<FormLabel htmlFor='create-location-input-business-sector'>
					{formatMessage(locationMessages.businessSector)}
				</FormLabel>
			}
		>
			{({ field }) => (
				<Select id='create-location-input-business-sector' size='md' min={0} icon='chevron-down' {...field}>
					<option selected hidden disabled value='' />
					{businessSectors.map(businessSector => (
						<option key={businessSector.code} value={businessSector.code}>
							{formatMessage(locationMessages[businessSector.label])}
						</option>
					))}
				</Select>
			)}
		</Field>
	</QubeSectionForm>
)

LocationForm.defaultProps = {
	location: { name: '', people_inside_limit: 100 }
}

LocationForm.propTypes = {
	handleOnSubmit: PropTypes.func.isRequired,
	intl: PropTypes.shape({
		formatMessage: PropTypes.func.isRequired
	}).isRequired,
	location: PropTypes.shape({
		name: PropTypes.string.isRequired,
		people_inside_limit: PropTypes.number,
		country: PropTypes.string.isRequired,
		business_sector: PropTypes.string.isRequired
	})
}

export default injectIntl(LocationForm)
