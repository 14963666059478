import * as Yup from 'yup'
import formsMessages from 'utils/forms/messages'
import messages from './messages'
import signUpMessages from '../signup/messages'
import { passwordValidator } from '../validators'

const SignUpSchema = formatMessage =>
	Yup.object().shape({
		name: Yup.string()
			.label(formatMessage(messages.name))
			.required(({ label }) => formatMessage(formsMessages.required, { label }))
			.min(2, ({ label, min }) => formatMessage(formsMessages.stringMin, { label, min }))
			.max(100, ({ label, max }) => formatMessage(formsMessages.stringMax, { label, max })),
		user: Yup.object().shape({
			email: Yup.string()
				.email(formatMessage(formsMessages.invalidEmail))
				.label(formatMessage(messages.email))
				.required(({ label }) => formatMessage(formsMessages.required, { label })),
			password1: passwordValidator(formatMessage)
		}),
		terms: Yup.boolean().oneOf([true], formatMessage(signUpMessages.checkRequired)),
		referral: Yup.string()
			.email(formatMessage(formsMessages.invalidEmail))
			.label(formatMessage(messages.referral))
	})

export { SignUpSchema }
