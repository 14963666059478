import * as Yup from 'yup'
import authMessages from './messages'
import formsMessages from '../utils/forms/messages'

export const passwordValidator = formatMessage =>
	Yup.string()
		.label(formatMessage(authMessages.password))
		.required(({ label }) => formatMessage(formsMessages.required, { label }))
		.min(8, ({ label, min }) => formatMessage(formsMessages.stringMin, { label, min }))

export const emailValidator = formatMessage =>
	Yup.string()
		.email(formatMessage(formsMessages.invalidEmail))
		.label(formatMessage(authMessages.email))
		.required(({ label }) => formatMessage(formsMessages.required, { label }))
