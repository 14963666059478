import {
	filterNoConnectionError,
	filter4XXError,
	filter5XXError,
	filter400GeneralError,
	filter402PaymentRequired,
	filter404Error,
	filter400AttributeValidationError,
	filter400ValidationNonFieldError
} from './errorFilters'
import { handleFormValidationError, handleActionValidationError, handleNonFieldError } from './errorHandlers'
import errorsMessages from './messages'
import { showErrorModal, formatMessage } from './showUIErrors'

export const interceptBaseError = error =>
	filterNoConnectionError(() => showErrorModal(formatMessage(errorsMessages.cantConnectServer)))(error)
		.catch(filter4XXError(() => showErrorModal(formatMessage(errorsMessages.errorDefaultMessage))))
		.catch(filter5XXError(() => showErrorModal(formatMessage(errorsMessages.serverError))))
		.catch(filter400GeneralError(message => showErrorModal(message)))
		.catch(filter402PaymentRequired(message => showErrorModal(message)))
		.catch(filter404Error(message => showErrorModal(message)))

export const interceptBaseErrorWithHandler = (handler, error) =>
	filterNoConnectionError(() => handler('base_error', formatMessage(errorsMessages.cantConnectServer)))(error)
		.catch(filter4XXError(() => handler('base_error', formatMessage(errorsMessages.errorDefaultMessage))))
		.catch(filter5XXError(() => handler('base_error', formatMessage(errorsMessages.serverError))))
		.catch(filter400GeneralError(message => handler('base_error', [message])))
		.catch(filter402PaymentRequired(message => handler('base_error', [message])))
		.catch(filter404Error(message => handler('base_error', [message])))

export const interceptInlineFormError = setFieldError => error =>
	interceptBaseError(error)
		.catch(filter400AttributeValidationError(handleFormValidationError(setFieldError)))
		.catch(filter400ValidationNonFieldError(handleNonFieldError(setFieldError)))

export const interceptSectionFormError = setFieldError => error =>
	interceptBaseErrorWithHandler(setFieldError, error)
		.catch(filter400AttributeValidationError(handleFormValidationError(setFieldError)))
		.catch(filter400ValidationNonFieldError(handleNonFieldError(setFieldError)))

export const interceptError = error =>
	interceptBaseError(error)
		.catch(filter400AttributeValidationError(handleActionValidationError))
		.catch(filter400ValidationNonFieldError(showErrorModal))
