import { Flex } from '@chakra-ui/core'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'

import { useLocation } from 'locations/LocationProvider'

import { useAuth } from '../auth/AuthProvider'
import LogoTopnav from './LogoTopnav'
import CycleLink from './buttons/CycleLink'
import LocationLink from './buttons/LocationLink'
import ProfileTopnavMenu from './menu/ProfileTopnavMenu'

const Topnav = ({ actions }) => {
	const { user } = useAuth()
	const { location, currentCycle } = useLocation()

	return (
		<Flex as='nav' align='center' justify='space-between' px={4} bg='topnav.bg' color='topnav.text' h='100%'>
			<LogoTopnav />
			<Flex align='center' justify='flex-end' minW='3xs'>
				{location && currentCycle && (
					<>
						<CycleLink
							subPlanName={
								currentCycle.subscription.subscription_plan.subscription_plan_group.display_name
							}
							cycleId={currentCycle.id}
							locationId={location.id}
						/>
					</>
				)}
				{location && (
					<>
						<LocationLink entity={location.name} />
						{actions.map((element, index) => (
							<Fragment key={index}>{element}</Fragment>
						))}
					</>
				)}
				<ProfileTopnavMenu profileName={user.profile.name} />
			</Flex>
		</Flex>
	)
}

Topnav.propTypes = {
	actions: PropTypes.arrayOf(PropTypes.node)
}

Topnav.defaultProps = {
	actions: []
}

export default Topnav
